<template>
  <main>
    <!-- hero-area-start -->
    <div class="tp-hero-area lightblue-bg tp-hero-2-bg">
      <div class="container custom-container">
        <div class="tp-hero-2-wrap">
          <div class="row align-items-end">
            <div class="col-xxl-5 col-xl-6 col-lg-7 col-md-12">
              <div class="tp-hero-2-content">
                <span class="tp-hero-2-subtitle wow fadeInUp" data-wow-delay=".3s">Keep Learning</span>
                <h2 class="tp-hero-2-title wow fadeInUp" data-wow-delay=".5s">
                  Best digital
                  <span>Online <img class="wow bounceIn" data-wow-duration="1.5s" data-wow-delay=".4s" src="/assets/lms_website/website-assets/img/unlerline/hero-2-svg-1.svg" alt=""/></span>
                  Courses
                </h2>
                <p class="wow fadeInUp" data-wow-delay=".7s">
                  Acquire global knowledge and build your <br/>
                  professional skills
                </p>
                <div class="tp-hero-2-btn wow fadeInUp" data-wow-delay=".9s">
                  <a class="tp-btn-border" href="course-with-filter.html">
                    Find Courses
                    <span>
                              <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M8.71533 1L13 5.28471L8.71533 9.56941" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                 <path d="M1 5.28473H12.88" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                           </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="offset-xxl-4 col-xxl-3 offset-xl-2 col-xl-4 col-lg-5 col-md-6" v-if="header_course && header_course?.name">
              <div class="tp-hero-2-course p-relative wow fadeInUp" data-wow-delay=".5s">
                <CourseWidget :course="header_course"/>
              </div>
            </div>
          </div>
          <div class="tp-hero-2-thumb">
            <img :src="(lms_website.settings_list.background_photo)?lms_website.settings_list.background_photo:'/assets/lms_website/website-assets/img/hero/hero-2-thumb-1.png'" alt=""/>
          </div>
        </div>
      </div>
      <div class="tp-hero-2-shape">
        <div class="tp-hero-2-shape-1">
            <span>
               <svg xmlns="http://www.w3.org/2000/svg" width="846" height="579" viewBox="0 0 846 579" fill="none">
                  <path
                      class="line-2"
                      d="M212.745 593.864C358.949 649.758 822.546 792.326 466.431 560.525C110.316 328.724 477.436 405.184 743.017 461.029C1008.6 516.874 193.087 -40.2421 69.0387 93.5502C-55.0097 227.343 493.91 431.765 484.946 51.9085"
                      stroke="url(#paint0_linear_311_1041)"
                      stroke-width="100"
                      stroke-linecap="square"
                  />
                  <defs>
                     <linearGradient id="paint0_linear_311_1041" x1="769.255" y1="703.159" x2="-110.567" y2="48.8101" gradientUnits="userSpaceOnUse"></linearGradient>
                  </defs>
               </svg>
            </span>
        </div>
        <div class="tp-hero-2-shape-2">
          <img src="/assets/lms_website/website-assets/img/hero/hero-2-shape-2.png" alt=""/>
        </div>
        <div class="tp-hero-2-shape-3">
          <img src="/assets/lms_website/website-assets/img/hero/hero-2-shape-3.png" alt=""/>
        </div>
        <div class="tp-hero-2-shape-4">
          <img src="/assets/lms_website/website-assets/img/hero/hero-2-shape-4.png" alt=""/>
        </div>
      </div>
    </div>
    <div class="home-header-logos">
      <div class="container">
        <div class="d-flex align-items-center justify-content-center">
          <template v-for="partner in partner_logos">
            <div :tooltip="partner.name">
              <img :src="partner.logo" :alt="partner.name">
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- hero-area-end -->
    <!-- category-area-start -->
    <section class="category-area mb-80 mt-95">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10">
            <div class="tp-section mb-40 text-center wow fadeInUp" data-wow-delay=".4s">
              <h5 class="tp-section-3-subtitle">Top Categories</h5>
              <h3 class="tp-section-3-title">
                Most demanding
                <span>
                   Categories
                   <img class="tp-underline-shape-5 wow bounceIn" data-wow-duration="1.5s" data-wow-delay=".4s" src="/assets/lms_website/website-assets/img/shape/bottom-line/line-2-category.svg" alt=""/>
                </span>
                .
              </h3>
            </div>
          </div>
        </div>
        <div class="row" v-if="home_categories.loading">
          <div v-for="number in range(1, 4)" class="col-lg-3 col-md-4 col-sm-6">
            <div class="tp-category-item mb-25 wow fadeInUp placeholder-glow" data-wow-delay=".3s">
              <div class="tp-category-icon">
                <div class="placeholder rounded" style="width:42px;height:42px;"></div>
              </div>
              <div class="tp-category-content">
                <div class="placeholder rounded" style="width:100px;height:22px;"></div>
                <span>
                  <div class="placeholder rounded" style="width:160px;height:15px;"></div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="!home_categories.loading">
          <div v-for="(category,index) in home_categories.list" :key="category.name" class="col-lg-3 col-md-4 col-sm-6">
            <router-link :to="`/courses/category/${encode_param(category.name)}`" class="tp-category-item mb-25 wow fadeInUp" :data-wow-delay="`.${((index+1))}s`">
              <div class="tp-category-icon">
                <span :class="home_categories.colors[category.color]"><img :src="(category.icon)?category.icon:''" alt=""/></span>
              </div>
              <div class="tp-category-content">
                <h4 class="tp-category-title">{{ category.category_name }}</h4>
                <span>{{ category.description }}</span>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-8">
            <div class="tp-category-banner d-flex align-items-center justify-content-between tp-category-banner-bg mt-35" data-background="/assets/lms_website/website-assets/img/bg/category-bg.jpg">
              <div class="tp-category-banner-content d-flex align-items-center">
                <div class="tp-category-banner-shape">
                  <img src="/assets/lms_website/website-assets/img/shape/category-2-shape-1.png" alt=""/>
                </div>
                <div class="tp-category-banner-text">
                  <span>Let Us Help</span>
                  <h4 class="tp-category-banner-title">Finding Your Right Courses</h4>
                </div>
              </div>
              <div class="tp-category-banner-btn">
                <a class="tp-btn-2" href="contact.html">Get Started</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- category-area-end -->
    <!-- funfact-area-start -->
    <section class="funfact-area tp-funfact-bg" data-background="/assets/lms_website/website-assets/img/bg/funfact-2-bg-1.jpg">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="tp-funfact-item d-flex align-items-center">
              <div class="tp-funfact-icon">
                <span><img src="/assets/lms_website/website-assets/img/icon/funfact/funfact-2-icon-1.svg" alt="icon"/></span>
              </div>
              <div class="tp-funfact-content">
                <h4 class="tp-funfact-title">Flexible classes</h4>
                <span>You pick schedule.</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="tp-funfact-item tp-funfact-item-2 d-flex align-items-center">
              <div class="tp-funfact-icon">
                <span><img src="/assets/lms_website/website-assets/img/icon/funfact/funfact-2-icon-2.svg" alt="icon"/></span>
              </div>
              <div class="tp-funfact-content">
                <h4 class="tp-funfact-title">Offline mode</h4>
                <span>Download classes.</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="tp-funfact-item tp-funfact-item-3 d-flex align-items-center">
              <div class="tp-funfact-icon">
                <span><img src="/assets/lms_website/website-assets/img/icon/funfact/funfact-2-icon-3.svg" alt="icon"/></span>
              </div>
              <div class="tp-funfact-content">
                <h4 class="tp-funfact-title">10k online courses</h4>
                <span>Explore a fresh topics</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="tp-funfact-item d-flex align-items-center justify-content-lg-end">
              <div class="tp-funfact-icon">
                <span><img src="/assets/lms_website/website-assets/img/icon/funfact/funfact-2-icon-4.svg" alt="icon"/></span>
              </div>
              <div class="tp-funfact-content">
                <h4 class="tp-funfact-title">Educator help</h4>
                <span>Always get answers.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- funfact-area-end -->
    <!-- course-area-start -->
    <section class="course-area tp-course-wrapper mt-100 mb-100">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-lg-6 col-md-8">
            <div class="tp-section mb-40">
              <h5 class="tp-section-3-subtitle">Our Courses</h5>
              <h3 class="tp-section-3-title">
                Most Popular
                <span>
                   Courses
                   <img class="tp-underline-shape-6 wow bounceIn" data-wow-duration="1.5s" data-wow-delay=".4s" src="/assets/lms_website/website-assets/img/unlerline/course-2-svg-1.svg" alt=""/>
                </span>
              </h3>
            </div>
          </div>
          <div class="col-lg-6 col-md-4">
            <div class="tp-team-2-arrow d-flex align-items-center justify-content-md-end mb-55">
              <div class="tp-home-courses-prev">
               <span class="pointer-event" @click="homeCoursesCarouselPrev">
                  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M6 11L1 6L6 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
               </span>
              </div>
              <div class="tp-home-courses-next">
               <span class="pointer-event" @click="homeCoursesCarouselNext">
                  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M1 11L6 6L1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
               </span>
              </div>
            </div>
          </div>
        </div>
        <template v-if="!our_courses.loading">
          <Carousel v-bind="our_courses.settings" :breakpoints="our_courses.breakpoints" ref="homeCoursesCarousel">
            <Slide class="animate__animated animate__zoomIn" v-for="course in our_courses.list" :key="course.name">
              <CourseWidget :course="course" css-class="text-left" css-style="width:100%;margin:0 12px;"/>
            </slide>
          </Carousel>
        </template>
        <div class="row">
          <div class="col-lg-4 col-md-6" v-for="number in range(1, 3)" v-if="our_courses.loading">
            <div class="tp-course-item p-relative fix mb-30 placeholder-glow">
              <div class="tp-course-teacher mb-15">
                <div class="d-flex align-items-center gap-1">
                  <span class="placeholder d-inline-block rounded-circle" style="width:30px;height:30px;"></span>
                  <span class="placeholder d-inline-block rounded" style="width:80px;height:10px;"></span>
                </div>
              </div>
              <div class="tp-course-thumb">
                <span class="placeholder d-block rounded" style="height:200px;"></span>
              </div>
              <div class="tp-course-content">
                <div class="d-flex align-items-center gap-1 mb-10">
                  <span class="placeholder d-inline-block rounded" style="width:100px;height:25px;"></span>
                </div>
                <div class="tp-course-meta">
                  <div class="d-flex align-items-center gap-1">
                    <span class="placeholder d-inline-block rounded" style="width:80px;height:10px;"></span>
                    <span class="placeholder d-inline-block rounded" style="width:80px;height:10px;"></span>
                  </div>
                </div>
                <span class="placeholder d-block rounded mt-4 mb-1" style="width:80%;height:6px;"></span>
                <span class="placeholder d-block rounded" style="width:30%;height:6px;"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- course-area-end -->
    <!-- live-area-start -->
    <section class="live-area lightblue-bg pt-95 pb-140">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10">
            <div class="tp-section text-center mb-65">
              <h5 class="tp-section-3-subtitle">Live Courses</h5>
              <h3 class="tp-section-3-title">
                Today's top free
                <span>
                  live
                  <img class="tp-underline-shape-7 wow bounceIn" data-wow-duration="1.5s" data-wow-delay=".4s" src="/assets/lms_website/website-assets/img/unlerline/live-2-svg-1.svg" alt=""/>
                </span>
                Courses
              </h3>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-xl-10">
            <div class="tp-live-bg wow fadeInUp" data-wow-delay=".2s">
              <div class="row align-items-center">
                <div class="col-lg-6 col-md-4">
                  <div class="tp-live-thumb p-relative">
                    <img :src="(live_course?.image)?live_course?.image:'/assets/lms_website/images/placeholder.jpg'" style="width:440px;max-height:310px;" :alt="live_course?.title"/>
                    <div class="tp-live-thumb-video">
                      <img src="/assets/lms_website/website-assets/img/teacher/live-2-video.png" alt=""/>
                    </div>
                    <div class="tp-live-thumb-text">
                              <span>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path
                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path d="M8 3.7998V7.9998L10.8 9.3998" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                 </svg>
                                 {{courseDuration(live_course?.custom_course_duration)}}
                              </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-8">
                  <div class="tp-live-content">
                    <span class="tp-live-tag">{{live_course?.tags}}</span>
                    <div class="tp-live-teacher">
                      <div class="tp-live-teacher-info d-flex align-items-center">
                        <div class="tp-live-teacher-thumb">
                          <img :src="(live_course?.instructor?.user_image)?live_course?.instructor?.user_image:'/assets/lms_website/images/user.png'" alt=""/>
                        </div>
                        <div class="tp-live-teacher-text">
                          <span>Teacher</span>
                          <h4 class="tp-live-teacher-title">{{live_course?.instructor?.full_name}}</h4>
                        </div>
                      </div>
                      <div class="tp-live-rating">
                        <p>{{ floatVale(live_course?.avg_rating) }} <span>/5</span></p>
                        <div class="tp-live-rating-star">
                          <div class="course-star-rating star-rating-sm m-0 mt-1">
                            <div class="back-stars">
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                            </div>
                            <div class="front-stars" :style="'width:'+avg_rating_width(live_course?.avg_rating)">
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 class="tp-live-title">
                      <router-link :to="`/courses/view/${live_course?.name}`">
                        {{ clip(live_course?.title, 65) }}
                      </router-link>
                    </h4>
                    <div class="tp-live-total">
                      <div class="tp-live-total-student">
                        <span>Live Student</span>
                        <img src="/assets/lms_website/website-assets/img/teacher/live-2-teacher-2.png" alt=""/>
                      </div>
                      <div class="tp-live-join">
                        <router-link class="tp-btn-border" :to="`/courses/view/${live_course?.name}`">
                            <span>
                               <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M3.70732 0.75H8.39024C9.65603 0.75 10.333 1.00147 10.7145 1.38301C11.0961 1.76456 11.3476 2.44153 11.3476 3.70732V9.95122C11.3476 11.217 11.0961 11.894 10.7145 12.2755C10.333 12.6571 9.65603 12.9085 8.39024 12.9085H3.70732C2.53342 12.9085 1.82979 12.5071 1.40543 11.9979C0.961765 11.4655 0.75 10.7255 0.75 9.95122V3.70732C0.75 2.44153 1.00147 1.76456 1.38301 1.38301C1.76456 1.00147 2.44153 0.75 3.70732 0.75Z"
                                      stroke="currentColor"
                                      stroke-width="1.5"
                                  />
                                  <path
                                      d="M7.21976 6.34479C8.03014 6.34479 8.68708 5.68785 8.68708 4.87747C8.68708 4.0671 8.03014 3.41016 7.21976 3.41016C6.40938 3.41016 5.75244 4.0671 5.75244 4.87747C5.75244 5.68785 6.40938 6.34479 7.21976 6.34479Z"
                                      fill="currentColor"
                                  />
                                  <path
                                      d="M13.6712 10.4795L13.6684 10.4775L12.8476 9.90073V3.75854L13.6676 3.18235C13.6677 3.18227 13.6678 3.18219 13.668 3.18211C14.021 2.93474 14.2801 2.87809 14.4413 2.87174C14.6067 2.86523 14.7268 2.9096 14.7996 2.94693C14.8665 2.98117 14.9706 3.05164 15.0602 3.19079C15.1476 3.3265 15.2501 3.57203 15.2501 4.00427V9.66281C15.2501 10.095 15.1476 10.3406 15.0602 10.4763C14.9706 10.6154 14.8665 10.6859 14.7996 10.7202L14.7996 10.7201L14.7922 10.724C14.7375 10.7528 14.6301 10.7938 14.4781 10.7938C14.3158 10.7938 14.0436 10.7437 13.6712 10.4795Z"
                                      stroke="currentColor"
                                      stroke-width="1.5"
                                  />
                               </svg>
                            </span>
                          Join Now
                          <i>
                            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.71533 1L13 5.28471L8.71533 9.56941" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M1 5.28473H12.88" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </i>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- live-area-end -->
    <!-- testimonial-area-start -->
    <section class="testimonial-area lightblue-bg pb-85">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xxl-6 col-lg-8">
            <div class="tp-testimonial-section">
              <div class="tp-section text-center mb-40">
                <h5 class="tp-section-3-subtitle">Courses Reviews</h5>
                <h3 class="tp-section-3-title">
                  Student
                  <span>
                        Say
                        <img class="tp-underline-shape-8 wow bounceIn" data-wow-duration="1.5s" data-wow-delay=".4s" src="/assets/lms_website/website-assets/img/unlerline/testimonial-2-svg-1.svg" alt=""/>
                        </span>
                  About UNIX
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8">
            <div class="tp-testimonial-2-avatar-active">
              <div v-for="review in home_reviews.list" :key="review.name" class="tp-testimonial-2-avatar-item">
                <img :src="(review?.user_data?.user_image)?review?.user_data?.user_image:'/assets/lms_website/images/user.png'" alt="avatar"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="tp-testimonial-2-active">
              <div v-for="review in home_reviews.list" :key="review.name" class="tp-testimonial-2-item text-center">
                <div class="tp-testimonial-2-avatar-info">
                  <h4 class="tp-testimonial-2-avatar-title">{{ review?.user_data?.full_name }}</h4>
                  <span>{{ clip(review?.course?.title, 50) }}</span>
                  <div class="tp-testimonial-2-avatar-rating">
                    <div class="course-star-rating d-inline-block mt-2">
                      <div class="back-stars">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="front-stars" :style="'width:'+avg_rating_width(review.rating)">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tp-testimonial-2-content p-relative">
                  <p> {{ clip(review?.review, 100) }} </p>
                  <div class="tp-testimonial-2-shape">
                    <div class="shape-1"><img src="/assets/lms_website/website-assets/img/testimonial/testimonial-shape-1.png" alt=""/></div>
                    <div class="shape-2"><img src="/assets/lms_website/website-assets/img/testimonial/testimonial-shape-3.png" alt=""/></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- testimonial-area-end -->
    <!-- team-area-start -->
    <section class="team-area pt-100 mb-100">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-lg-6 col-md-8">
            <div class="tp-section mb-45">
              <h5 class="tp-section-3-subtitle">Our Team</h5>
              <h3 class="tp-section-3-title">
                Meet Our
                <span>
                  Teachers
                  <img class="tp-underline-shape-9 wow bounceIn" data-wow-duration="1.5s" data-wow-delay=".4s" src="/assets/lms_website/website-assets/img/unlerline/team-2-svg-1.svg" alt=""/>
                </span>
              </h3>
            </div>
          </div>
          <div class="col-lg-6 col-md-4">
            <div class="tp-team-2-arrow d-flex align-items-center justify-content-md-end mb-55">
              <div class="tp-team-2-prev">
                <span>
                  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M6 11L1 6L6 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </div>
              <div class="tp-team-2-next">
                <span>
                  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 11L6 6L1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper tp-team-2-active wow fadeInUp" data-wow-delay=".5s">
          <div class="swiper-wrapper align-items-end">
            <div class="swiper-slide" v-for="instructor in home_instructors.list" :key="instructor.name">
              <div class="tp-team-2-item">
                <div class="tp-team-2-bg geyser"></div>
                <div class="tp-team-2-thumb">
                  <img :src="(instructor.photo)?instructor.photo:'/assets/lms_website/images/avatar-character.png'" :alt="instructor.instructor_name"/>
                </div>
                <div class="tp-team-2-content">
                  <h4 class="tp-team-2-title"><a>{{ instructor.instructor_name }}</a></h4>
                  <span>{{ instructor.instructor_title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- team-area-end -->
    <!-- bannner-area-start -->
    <section class="banner-area pb-60">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="tp-banner-sm-2-wrap mb-60 wow fadeInUp" data-wow-delay=".3s">
              <h3 class="tp-banner-sm-2-title">
                Earn a <br/>
                <span>
                     Certificate
                     <img class="tp-underline-shape-11 wow bounceIn" data-wow-duration="1.5s" data-wow-delay=".4s" src="/assets/lms_website/website-assets/img/unlerline/banner-2-svg-1.svg" alt=""/>
                     </span>
              </h3>
              <div class="tp-banner-sm-2-btn">
                <a href="contact.html">View Programs</a>
              </div>
              <div class="tp-banner-sm-2-shape-1">
                <img src="/assets/lms_website/website-assets/img/banner/banner-2-thumb-1.png" alt=""/>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="tp-banner-sm-2-wrap tp-banner-sm-2-bg-2 mb-60 wow fadeInUp" data-wow-delay=".3s">
              <h3 class="tp-banner-sm-2-title">
                Best <br/>
                Rated
                <span>
                     Courses
                     <img class="tp-underline-shape-11 wow bounceIn" data-wow-duration="1.5s" data-wow-delay=".4s" src="/assets/lms_website/website-assets/img/unlerline/banner-2-svg-2.svg" alt=""/>
                     </span>
              </h3>
              <div class="tp-banner-sm-2-btn">
                <a href="course-with-filter.html">View Programs</a>
              </div>
              <div class="tp-banner-sm-2-shape-1">
                <img src="/assets/lms_website/website-assets/img/banner/banner-2-thumb-2.png" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- bannner-area-end -->
    <!-- cta-area-start -->
    <section class="cta-area tp-cta-2-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xxl-8 col-lg-10">
            <div class="tp-cta-2-wrapper text-center">
              <h2 class="tp-cta-2-title">
                Get Started With
                <span>
                     UNIX
                     <img class="tp-underline-shape-12 wow bounceIn" data-wow-duration="1.5s" data-wow-delay=".4s" src="/assets/lms_website/website-assets/img/unlerline/cta-2-svg-1.svg" alt=""/>
                     </span>
                Now
              </h2>
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
              <div class="tp-cta-2-form">
                <form action="#">
                        <span>
                           <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M2.2 1.01465H11.8C12.46 1.01465 13 1.55465 13 2.21465V9.41465C13 10.0746 12.46 10.6146 11.8 10.6146H2.2C1.54 10.6146 1 10.0746 1 9.41465V2.21465C1 1.55465 1.54 1.01465 2.2 1.01465Z"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                              />
                              <path d="M13 2.21436L7 6.41436L1 2.21436" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>
                        </span>
                  <input type="text" placeholder="Enter your email address"/>
                  <div class="tp-cta-2-btn">
                    <button class="tp-btn-round">Subscribe!</button>
                  </div>
                </form>
              </div>
              <div class="tp-cta-2-info-list">
                     <span>
                        <span>
                           <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.6" d="M13 1.01465L4.75 9.26465L1 5.51465" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>
                        </span>
                        Easy to Access
                     </span>
                <span>
                        <span>
                           <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.6" d="M13 1.01465L4.75 9.26465L1 5.51465" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>
                        </span>
                        No Credit card
                     </span>
                <span>
                        <span>
                           <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.6" d="M13 1.01465L4.75 9.26465L1 5.51465" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>
                        </span>
                        85,000 student onboard with us
                     </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- cta-area-end -->
  </main>
</template>

<script>
import 'node_modules/vue3-carousel/dist/carousel.css'
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'
import {isLoggedIn} from '../../utils/auth';
import CourseWidget from "../../components/CourseWidget.vue";

export default {
  name: "HomePage",
  components: {
    CourseWidget,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {
    return {
      isLoggedIn,
      lms_website,
      frappe
    }
  },
  data() {
    return {
      header_course: null,
      live_course: null,
      partner_logos: [],
      home_categories: {
        loading: true,
        list: [],
        colors: {
          Blue: 'cat-blue',
          Green: 'cat-green',
          Pink: 'cat-pink',
          Purple: 'cat-purple',
          Yellow: 'cat-yellow',
          Violet: 'cat-l-purple',
          Turquoise: 'cat-lgreen'
        }
      },
      our_courses: {
        loading: true,
        list: [],
        settings: {
          itemsToShow: 1,
          wrapAround: true,
          snapAlign: 'center',
        },
        breakpoints: {
          700: {
            itemsToShow: 2,
            snapAlign: 'center',
          },
          1024: {
            itemsToShow: 3,
            snapAlign: 'center',
          },
        }
      },
      home_instructors: {
        loading: true,
        list: [],
      },
      home_reviews: {
        loading: true,
        list: []
      }
    }
  },
  methods: {
    get_courses(callback) {
      this.our_courses.loading = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_home_courses",
        args: {},
        callback: (response) => {
          callback(response);
        },
      });
    },
    get_home_categories(callback) {
      this.home_categories.loading = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_home_categories",
        args: {},
        callback: (response) => {
          callback(response);
        },
      });
    },
    get_partner_logos(callback) {
      frappe.call({
        type: "GET",
        method: "lms_website.api.utils.get_home_partner_logos",
        args: {},
        callback: (response) => {
          callback(response);
        },
      });
    },
    get_home_instructors(callback) {
      frappe.call({
        type: "GET",
        method: "lms_website.api.utils.get_home_instructors",
        args: {},
        callback: (response) => {
          callback(response);
        },
      });
    },
    get_home_reviews(callback) {
      frappe.call({
        type: "GET",
        method: "lms_website.api.utils.get_home_reviews",
        args: {},
        callback: (response) => {
          callback(response);
        },
      });
    },
    homeCoursesCarouselNext() {
      this.$refs.homeCoursesCarousel.next()
      this.$refs.homeCoursesCarousel.updateSlideWidth();
    },
    homeCoursesCarouselPrev() {
      this.$refs.homeCoursesCarousel.prev()
      this.$refs.homeCoursesCarousel.updateSlideWidth();
    },
    get_course_by_name(course_name, callback) {
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_course_by_name",
        args: {
          course_name: course_name
        },
        callback: (response) => {
          callback(response);
        },
      });
    },
    avg_rating_width(value) {
      if (value) {
        return `${((value / 5) * 100) + 3}%`;
      }
      return '0%';
    },
    clip(str, limit) {
      if (!str) return '';
      str = str.toString();
      if (str.length <= limit) {
        return str;
      }
      return str.substr(0, limit) + '...';
    },
    format_currency(price = 0) {
      return format_currency(price);
    },
    range(start, end) {
      return Array.from({length: end - start + 1}, (_, i) => start + i);
    },
    floatVale(value, digits = 1) {
      return parseFloat(value).toFixed(digits);
    },
    courseDuration(course_duration) {
      let duration = '';
      if (course_duration) {
        let ms = course_duration;
        let totalSeconds = ms;
        // Calculate days
        let days = Math.floor(totalSeconds / (3600 * 24));
        // Calculate remaining hours after days
        let hours = Math.floor((totalSeconds % (3600 * 24)) / 3600) + (days * 24);
        // Calculate remaining minutes after hours
        let minutes = Math.floor((totalSeconds % 3600) / 60);
        let seconds = totalSeconds % 60;
        duration = `${(hours) ? hours + 'h' : ''} ${(minutes) ? minutes + 'm' : ''} ${(seconds) ? seconds + 's' : ''}`;
      }
      return duration;
    },
    encode_param(param) {
      return encodeURIComponent(param);
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.get_partner_logos(({message}) => {
      this.partner_logos = message;
    });
    this.get_home_categories(({message}) => {
      this.home_categories.loading = false;
      if (message && message.length) {
        this.home_categories.list = message;
      }
    });
    this.get_courses(({message}) => {
      this.our_courses.loading = false;
      if (message && message.length) {
        message.map(row => {
          if (row.instructors && row.instructors[0]) {
            row['instructor'] = row.instructors[0];
          } else {
            row['instructor'] = {};
          }
          this.our_courses.list.push(row);
        });
      }
      setTimeout(() => {
        NProgress.done();
      }, 1000);
    });
    if (lms_website.settings_list && lms_website.settings_list.default_course) {
      this.get_course_by_name(lms_website.settings_list.default_course, ({message}) => {
        if (message && message[0]) {
          if (message[0].instructors && message[0].instructors[0]) {
            message[0]['instructor'] = message[0].instructors[0];
          } else {
            message[0]['instructor'] = {};
          }
          this.header_course = message[0];
        }
      });
    }
    if (lms_website.settings_list && lms_website.settings_list.live_course) {
      this.get_course_by_name(lms_website.settings_list.live_course, ({message}) => {
        if (message && message[0]) {
          if (message[0].instructors && message[0].instructors[0]) {
            message[0]['instructor'] = message[0].instructors[0];
          } else {
            message[0]['instructor'] = {};
          }
          this.live_course = message[0];
          console.log('this.live_course', this.live_course)
        }
      });
    }
    this.get_home_instructors(({message}) => {
      this.home_instructors.loading = false;
      this.home_instructors.list = message;
      setTimeout(() => {
        const slider = new Swiper('.tp-team-2-active', {
          slidesPerView: 4,
          spaceBetween: 30,
          loop: true,
          autoplay: {
            delay: 5000,
          },
          // Navigation arrows
          navigation: {
            nextEl: ".tp-team-2-next",
            prevEl: ".tp-team-2-prev",
          },
          breakpoints: {
            '1200': {
              slidesPerView: 4,
            },
            '992': {
              slidesPerView: 3,
            },
            '768': {
              slidesPerView: 2,
            },
            '576': {
              slidesPerView: 2,
            },
            '0': {
              slidesPerView: 1,
            },
          },
        });
      }, 500);
    });
    this.get_home_reviews(({message}) => {
      this.home_reviews.loading = false;
      this.home_reviews.list = message;
      setTimeout(() => {
        if (!$('.tp-testimonial-2-active').hasClass('slick-initialized')) {

          $('.tp-testimonial-2-active').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: false,
            asNavFor: '.tp-testimonial-2-avatar-active'
          });

          $('.tp-testimonial-2-avatar-active').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.tp-testimonial-2-active',
            dots: false,
            centerMode: true,
            centerPadding: '0px',
            prevArrow: '<button type="button" class="slick-prev"><i class="fa-solid fa-angle-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-angle-right"></i></button>',
            focusOnSelect: true,
            responsive: [
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                },
              },
            ],
          });
        }
      }, 500);
    });
  },
  created() {
    setTimeout(() => {
      $('.tp-hero-2-thumb > img').addClass('animate__animated animate__fadeInLeft');
      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });
    }, 500);
  }
}
</script>

<style lang="scss" scoped>

</style>
