import {createApp, getCurrentInstance} from 'vue';
import mitt from 'mitt';
import Vue3Storage, {StorageType, useStorage} from 'vue3-storage';
import {MaskInput} from 'vue-3-mask';
import ToastPlugin from 'vue-toast-notification';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';


import i18n from './i18n';
import router from './router';
import App from './App.vue';

lms_website.__ = i18n.__;

lms_website.filterData = (data, predicate) => {
    return !!!data ? null : data.reduce((list, entry) => {
        let clone = null;
        if (predicate(entry)) {
            clone = Object.assign({}, entry)
        } else if (entry.children != null) {
            let children = filterData(entry.children, predicate)
            if (children.length > 0) {
                clone = Object.assign({}, entry, {children: children})
            }
        }
        clone && list.push(clone)
        return list
    }, [])
}

lms_website.get_base_url = function () {
    // var url= (frappe.base_url || window.location.href).split('#')[0].split('?')[0].split('desk')[0];
    let url = frappe.base_url || window.location.origin;
    if (url.substr(url.length - 1, 1) == "/") url = url.substr(0, url.length - 1);
    return url;
}

String.prototype.like = function (search) {
    if (typeof search !== 'string' || this === null) {
        return false;
    }
    search = search.replace(new RegExp("([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])", "g"), "\\$1");
    search = search.replace(/%/g, '.*').replace(/_/g, '.');
    return RegExp('^' + search + '$', 'gi').test(this);
}

const emitter = mitt();
const storage = useStorage();

let app = createApp(App);

app.config.globalProperties.$emitter = emitter;

app.component('MaskInput', MaskInput);

app.use(router)
    .use(ToastPlugin)
    .use(VueSweetalert2)
    .use(Vue3Storage, {namespace: 'datavalue_unix_', storage: StorageType.Session});

// Mount the app to #app
if (lms_website.user_session === 'Guest') {
    storage.setStorage({
        key: 'UnixAuth',
        data: {}
    }).then((successCallback) => {
        sessionStorage.clear();
        app.mount('#app');
    });
} else {
    let UnixAuth = storage.getStorageSync("UnixAuth");
    if (UnixAuth && UnixAuth.is_logged_in === true && UnixAuth.user && UnixAuth.user?.name && (UnixAuth.user?.name === lms_website.user_session)) {
        console.log('webiste-UnixAuth', UnixAuth)
        console.log('auth from webiste')
        app.mount('#app');
    } else {
        console.log('UnixAuth', UnixAuth)
        console.log('auth from frappe')
        frappe.call({
            type: "POST",
            method: "lms_website.api.user.get_user_info",
            args: {
                user_session: frappe.utils.xss_sanitise((lms_website.user_session).trim()),
            },
            callback: (response) => {
                let data = response.message || {};
                data['is_logged_in'] = true;
                storage.setStorage({
                    key: 'UnixAuth',
                    data: data
                }).then((successCallback) => {
                    setTimeout(() => {
                        app.mount('#app');
                    }, 1000);
                });
            },
        });
    }
}
