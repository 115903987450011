(function ($) {

    "use strict";

    $(window).on('load', function () {
        $("#loading").fadeOut(500);
    });

    $(document).on('click', '[data-dismiss="modal"]', function () {
        $(this).parents('.modal').modal('hide');
    });

    $(document).on('focus', '[data-toggle="datepicker"]', function () {
        if ($.isFunction($.fn.datepicker) && !$(this).prop('readonly')) {
            let $input = $(this);
            let value = $input.val();
            let full_date_time = $input.data('full-date-time');
            let lang = 'en'
            if (!$.fn.datepicker.language[lang]) {
                lang = 'en';
            }
            let date_format = 'yyyy-mm-dd';
            let now_date = new Date();
            let datepicker = $input.data('datepicker');
            if (!datepicker) {
                $input.datepicker({
                    language: lang,
                    dateFormat: 'yyyy-mm-dd',
                    startDate: now_date,
                    autoClose: true,
                    todayButton: true,
                    keyboardNav: false,
                    onSelect: (date) => {
                        // $input.trigger('input');
                        const event = new Event('input', { bubbles: true });
                        $input.get(0).dispatchEvent(event);
                        $(document).trigger('datepicker-updated');
                    },
                    onShow: () => {
                        $input.data('datepicker').$datepicker.find('.datepicker--button:visible').text('Today');
                        $input.data('datepicker').$datepicker.find('[data-action="today"]').click(() => {
                            $input.data('datepicker').selectDate(frappe.datetime.now_date(true));
                        });
                    }
                });
            }
        }
    });

})(jQuery);
