const translations = {
    ar: {
        "Test": "تجربة",
    },
    __: function (text, replace = undefined, lang) {
        let me = this;
        if (lang && me[lang]) {
            let messages = (me[lang]) ? me[lang] : {};
            let string = (messages[text] && $.trim(messages[text])) ? messages[text] : text;
            if (replace && typeof replace === "object") {
                string = this.format(string, replace);
            }
            return string;
        } else {
            let string = text;
            const format = (str, args) => {
                if (str == undefined) return str;
                this.unkeyed_index = 0;
                return str.replace(/\{(\w*)\}/g, function (match, key) {
                    if (key === '') {
                        key = this.unkeyed_index;
                        this.unkeyed_index++
                    }
                    if (key == +key) {
                        return args[key] !== undefined
                            ? args[key]
                            : match;
                    }
                }.bind(this));
            };
            if (replace && typeof replace === "object") {
                string = format(string, replace);
            }
            return string;
        }
    },
    format: function (str, args) {
        if (str == undefined) return str;
        this.unkeyed_index = 0;
        return str.replace(/\{(\w*)\}/g, function (match, key) {
            if (key === '') {
                key = this.unkeyed_index;
                this.unkeyed_index++
            }
            if (key == +key) {
                return args[key] !== undefined
                    ? args[key]
                    : match;
            }
        }.bind(this));
    }
};

export default translations;
