<template>
  <!-- dashboard-content-area-start -->
  <div class="tpd-content-layout">

    <!-- dashboard-tab-area -->
    <div class="tp-dashboard-tab mb-30">
      <h2 class="tp-dashboard-tab-title">My Quiz </h2>
      <div class="tp-dashboard-tab-list">
        <ul>
          <li>
            <a class="pointer-event" @click="quiz.active_tab='completed'" :class="(quiz.active_tab==='completed')?'active':''">Completed Quiz <span>({{ quiz.completed.length }})</span></a>
          </li>
          <li>
            <a class="pointer-event" @click="quiz.active_tab='upcoming_quiz'" :class="(quiz.active_tab==='upcoming_quiz')?'active':''">Upcoming Quiz <span>({{ quiz.upcoming_quiz.length }})</span></a>
          </li>
        </ul>
      </div>
    </div>
    <template v-if="quiz.active_tab==='upcoming_quiz'">
      <div v-if="!quiz.active_quiz" class="tpd-course-area">
        <div class="tpd-table mb-25">
          <ul>
            <li class="tpd-table-head">
              <div class="tpd-table-row">
                <div class="tpd-quiz-info-sub">
                  <h4 class="tpd-table-title">Quiz Info</h4>
                </div>
                <div class="tpd-quiz-tm" style="width:150px;">
                  <h4 class="tpd-table-title">Start At</h4>
                </div>
                <div class="tpd-quiz-tm" style="width:150px;">
                  <h4 class="tpd-table-title">End At</h4>
                </div>
                <div class="tpd-quiz-details">
                  <h4 class="tpd-table-title">Details</h4>
                </div>
              </div>
            </li>
            <template v-if="quiz.loading">
              <li class="placeholder-glow">
                <div class="tpd-table-row gap-2 mr-4">
                  <div class="placeholder rounded w-100" style="height:30px;"></div>
                  <div class="placeholder rounded w-50" style="height:30px;"></div>
                  <div class="placeholder rounded w-50" style="height:30px;"></div>
                  <div class="placeholder rounded w-25" style="height:30px;"></div>
                </div>
              </li>
            </template>
            <template v-if="!quiz.loading || !quiz.active_quiz?.name">
              <li v-for="row in paginatedUpcoming">
                <div class="tpd-table-row">
                  <div class="tpd-quiz-info-sub" style="width:360px;">
                    <span class="tpd-common-date">{{ moment(row.creation).format('MMMM DD, YYYY') }}</span>
                    <h4 class="tpd-quiz-title">{{ row.quiz.title }}</h4>
                    <div class="tpd-student-info">
                      <p><span>Course: </span> {{ row.course.title }}</p>
                    </div>
                  </div>
                  <div class="tpd-quiz-tm" style="width:150px;">
                    <span class="tpd-common-text">{{ moment(`${row.from_date} ${row.start_time}`).format('DD, MMM - hh:mm A') }}</span>
                  </div>
                  <div class="tpd-quiz-tm" style="width:150px;">
                    <span class="tpd-common-text" :class="(checkQuizDateEnd(`${row.to_date} ${row.end_time}`))?'text-danger text-decoration-line-through':'text-success'">{{ moment(`${row.to_date} ${row.end_time}`).format('DD, MMM - hh:mm A') }}</span>
                  </div>
                  <div class="tpd-quiz-details" style="min-width:75px;">
                    <div class="tpd-quiz-details-btn">
                      <template v-if="!checkQuizDateEnd(`${row.to_date} ${row.end_time}`)">
                        <button type="button" @click="openQuiz(row)" style="width:80px;" class="btn btn-sm btn-primary rounded pointer-event">
                          Start <i class="far fa-arrow-right ml-1"></i>
                        </button>
                      </template>
                      <template v-if="checkQuizDateEnd(`${row.to_date} ${row.end_time}`)">
                        <div class="text-danger text-right">Ended</div>
                      </template>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <Pagination
            v-if="!quiz.loading"
            :current-page="quiz.pagination.upcoming_quiz.currentPage"
            :total-items="quiz.upcoming_quiz.length"
            :items-per-page="quiz.pagination.upcoming_quiz.itemsPerPage"
            @page-changed="handleUpcomingPageChange"
        />
      </div>
      <div class="profile-card-body" v-if="quiz.active_quiz && quiz.active_quiz?.name">
        <QuizDetails :quiz-data="quiz.active_quiz"></QuizDetails>
      </div>
    </template>
    <template v-if="quiz.active_tab==='completed'">
      <div class="tpd-course-area">
        <div class="tpd-table mb-25">
          <ul>
            <li class="tpd-table-head">
              <div class="tpd-table-row">
                <div class="tpd-quiz-info-sub">
                  <h4 class="tpd-table-title">Quiz Info</h4>
                </div>
                <div class="tpd-quiz-ques">
                  <h4 class="tpd-table-title">Percentage</h4>
                </div>
                <div class="tpd-quiz-tm">
                  <h4 class="tpd-table-title">Score</h4>
                </div>
                <div class="tpd-quiz-result-sub">
                  <h4 class="tpd-table-title">Status</h4>
                </div>
                <div class="tpd-quiz-details">
                  <h4 class="tpd-table-title">Details</h4>
                </div>
              </div>
            </li>
            <template v-if="quiz.loading">
              <li class="placeholder-glow">
                <div class="tpd-table-row gap-2 mr-4">
                  <div class="placeholder rounded w-100" style="height:30px;"></div>
                  <div class="placeholder rounded w-50" style="height:30px;"></div>
                  <div class="placeholder rounded w-50" style="height:30px;"></div>
                  <div class="placeholder rounded w-25" style="height:30px;"></div>
                </div>
              </li>
            </template>
            <template v-if="!quiz.loading">
              <li v-for="row in paginatedCompleted">
                <div class="tpd-table-row">
                  <div class="tpd-quiz-info-sub">
                    <span class="tpd-common-date">{{ moment(row.creation).format('MMMM DD, YYYY') }}</span>
                    <h4 class="tpd-quiz-title">{{ row.quiz.title }}</h4>
                    <div class="tpd-student-info">
                      <p><span>Course: </span> {{ row.course.title }}</p>
                    </div>
                  </div>
                  <div class="tpd-quiz-ques">
                    <span class="tpd-common-text">{{ row.percentage }}%</span>
                  </div>
                  <div class="tpd-quiz-tm">
                    <h4 class="tpd-table-title">{{ row.score }}/{{ row.score_out_of }}</h4>
                  </div>
                  <div class="tpd-quiz-result-sub">
                    <div class="tpd-badge-item">
                      <span class="tpd-badge sucess" v-if="row.custom_status==='Success'">Passed</span>
                      <span class="tpd-badge danger" v-if="row.custom_status==='Failed'">Failed</span>
                    </div>
                  </div>
                  <div class="tpd-quiz-details">
                    <div class="tpd-quiz-details-btn">
                      <a @click="showQuizAnswers(row)" class="tpd-border-btn pointer-event">Details</a>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <Pagination
            v-if="!quiz.loading"
            :current-page="quiz.pagination.completed.currentPage"
            :total-items="quiz.completed.length"
            :items-per-page="quiz.pagination.completed.itemsPerPage"
            @page-changed="handleCompletedPageChange"
        />
      </div>
    </template>
  </div>
  <teleport to="body">
    <div class="modal fade" id="quizAnswersModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">{{ modal.data?.quiz?.quiz?.title }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body min-height-100" :class="{'form-loading':(modal.loading)}">
            <div class="text-center mt-3">
              <h3 class="mt-3">
                <span class="text-secondary">{{ lms_website.__('You got {0}%', [modal.data?.quiz?.percentage]) }} - </span>
                <span class="text-success" v-if="modal.data?.quiz?.custom_status==='Success'">{{ lms_website.__('Passed') }}</span>
                <span class="text-danger" v-if="modal.data?.quiz?.custom_status==='Failed'">{{ lms_website.__('Failed') }}</span>
              </h3>
              <p class="text-dark">{{ lms_website.__('Your score is {0}/{1}.', [modal.data?.quiz?.score, modal.data?.quiz?.score_out_of]) }}</p>
            </div>
            <div class="alert alert-secondary mt-3" style="padding:5px 10px;">
              <p class="mb-0 font-s-13 text-dark">{{ lms_website.__('You must get {0}% of correct answers to pass the quiz.', [modal.data?.quiz?.passing_percentage]) }}</p>
            </div>
            <ol class="list-group list-group-numbered">
              <li v-for="answer in modal.data.answers" :key="answer.name" class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold margin-p-0" v-html="answer.question"></div>
                  <div class="d-flex gap-2">
                    <div class="font-s-13 text-secondary"><span class="fw-bold">Answer: </span> {{ answer.answer }}</div>
                    <div class="font-s-13 text-secondary"><span class="fw-bold">Marks: </span> ({{ answer.marks }})</div>
                  </div>
                </div>
                <span v-if="answer.is_correct" class="badge text-bg-success rounded-pill text-center font-s-14" style="min-width:30px;"><i class="far fa-check"></i></span>
                <span v-if="!answer.is_correct" class="badge text-bg-danger rounded-pill text-center font-s-14" style="min-width:30px;"><i class="far fa-times"></i></span>
              </li>
            </ol>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import EventBus from "../../../utils/bus";
import {authData, isLoggedIn} from "../../../utils/auth";
import Pagination from "../../../components/Pagination.vue";
import QuizDetails from "./partials/QuizDetails.vue";

export default {
  name: "OrdersHistory",
  components: {QuizDetails, Pagination},
  props: {},
  setup() {
    return {
      isLoggedIn,
      lms_website,
      moment
    }
  },
  data() {
    return {
      authData: authData(),
      intervalQuiz: null,
      quiz: {
        loading: true,
        active_tab: null,
        active_quiz: null,
        upcoming_quiz: [],
        completed: [],
        pagination: {
          upcoming_quiz: {
            currentPage: 1,
            itemsPerPage: 5,
          },
          completed: {
            currentPage: 1,
            itemsPerPage: 5,
          }
        },
      },
      modal: {
        loading: true,
        data: {}
      }
    }
  },
  computed: {
    auth() {
      if (this.authData && this.authData.is_logged_in && this.authData.user) {
        return this.authData;
      }
    },
    paginatedCompleted() {
      const start = (this.quiz.pagination.completed.currentPage - 1) * this.quiz.pagination.completed.itemsPerPage;
      const end = start + this.quiz.pagination.completed.itemsPerPage;
      return this.quiz.completed.slice(start, end);
    },
    paginatedUpcoming() {
      const start = (this.quiz.pagination.upcoming_quiz.currentPage - 1) * this.quiz.pagination.upcoming_quiz.itemsPerPage;
      const end = start + this.quiz.pagination.upcoming_quiz.itemsPerPage;
      return this.quiz.upcoming_quiz.slice(start, end);
    },
  },
  watch: {
    "quiz.active_tab": {
      handler(active_tab) {
        console.log('active_tab', active_tab)
        if (active_tab === 'completed') {
        }
      },
      deep: true
    }
  },
  methods: {
    get_upcoming_quizzes(callback = Function(), show_loading = false) {
      if (show_loading)
        this.quiz.loading = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.quiz.get_upcoming_quizzes",
        args: {
          user: frappe.session.user
        },
        callback: ({message}) => {
          if (show_loading)
            this.quiz.loading = false;
          this.quiz.upcoming_quiz = message;
          callback(message);
        },
      });
    },
    get_quiz_submissions(callback = Function(), show_loading = false) {
      if (show_loading)
        this.quiz.loading = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.quiz.get_student_quiz_submissions",
        args: {
          user: frappe.session.user
        },
        callback: ({message}) => {
          if (show_loading)
            this.quiz.loading = false;
          this.quiz.completed = message;
          callback(message);
        },
      });
    },
    get_quiz_answers(quiz_name, callback) {
      frappe.call({
        type: "GET",
        method: "lms_website.api.quiz.get_quiz_answers",
        args: {
          quiz_name: quiz_name
        },
        callback: ({message}) => {
          callback(message);
        },
      });
    },
    showQuizAnswers(quiz) {
      $('#quizAnswersModal').modal('show');
      this.modal.loading = true;
      this.modal.data['quiz'] = {};
      this.modal.data['answers'] = [];
      this.get_quiz_answers(quiz.name, (message) => {
        this.modal.loading = false;
        this.modal.data['quiz'] = quiz;
        this.modal.data['answers'] = message;
        console.log('this.modal.data', this.modal.data);
      });
    },
    handleCompletedPageChange(page) {
      this.quiz.pagination.completed.currentPage = page;
    },
    handleUpcomingPageChange(page) {
      this.quiz.pagination.upcoming_quiz.currentPage = page;
    },
    checkQuizDateEnd(dateString) {
      const dateToCheck = moment(dateString);
      const now = moment();
      return now.isAfter(dateToCheck);
    },
    openQuiz(quiz_data) {
      let quiz = {};
      quiz['name'] = quiz_data.quiz.name;
      quiz['start_date'] = quiz_data.from_date;
      quiz['start_time'] = quiz_data.start_time;
      quiz['end_date'] = quiz_data.to_date;
      quiz['end_time'] = quiz_data.end_time;
      quiz['lms_course'] = quiz_data.lms_course;
      quiz['lms_batch'] = quiz_data.lms_batch;
      quiz['branch'] = quiz_data.branch;
      this.quiz.active_quiz = quiz;
    }
  },
  beforeDestroy() {
    if (this.intervalQuiz) {
      clearInterval(this.intervalQuiz);
    }
  },
  mounted() {
    EventBus.on('close-active-quiz', () => {
      this.quiz.active_quiz = null;
    });
    EventBus.on('refresh-quiz-completed', () => {
      this.get_quiz_submissions();
    });
    setTimeout(() => {
      this.get_upcoming_quizzes(() => {
        this.get_quiz_submissions(() => {
          NProgress.done();
        }, true);
      }, true);
      this.quiz.active_tab = 'completed';
      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });
    }, 500);
    this.intervalQuiz = setInterval(() => {
      this.get_upcoming_quizzes();
    }, 60000);
  }
}
</script>

<style scoped>

</style>
