<template>
  <main>
    <div class="container py-5">
      <div class="tp-login-register-wrapper d-flex justify-content-center align-items-center">
        <div class="tp-login-from-box">
          <div class="tp-login-from-heading text-center">
            <h4 class="tp-login-from-title">{{ lms_website.__('Forgot password') }}</h4>
            <p class="text-secondary">{{ lms_website.__("Enter the email address you use on Coursera. We'll send you a link to reset your password.") }}</p>
          </div>
          <div class="alert alert-danger py-2 mt-3" v-if="login_error"><i class="far fa-info-circle"></i> {{ login_error }}</div>
          <form method="post" @submit="onSubmit($event)">
            <div class="tp-login-input-form">
              <div class="row">
                <div class="col-12">
                  <div class="tp-login-input p-relative">
                    <label for="login-email">{{ lms_website.__('Email') }}</label>
                    <input type="email" id="login-email" v-model="email" :placeholder="lms_website.__('Type your email')" required>
                  </div>
                </div>
                <div class="col-12">
                  <div class="tp-login-from-btn">
                    <button type="submit" class="tp-btn-inner w-100 text-center" :disabled="form_loading">
                      <span v-if="form_loading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span v-if="!form_loading"> {{ lms_website.__('Reset Password') }}</span>
                    </button>
                  </div>
                  <div class="mt-3 text-center">
                    <p>{{ lms_website.__('Back to') }}
                      <router-link :to="{ name: 'auth.login' }" class="text-primary">{{ lms_website.__('Login') }}</router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {useStorage} from "vue3-storage";

const storage = useStorage();

export default {
  data() {
    return {
      lms_website: lms_website,
      show_password: false,
      login_error: '',
      form_loading: false,
      email: null,
      rules: [
        value => !!value || lms_website.__('Field Required.')
      ],
    }
  },
  computed: {},
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.form_loading = true;
      frappe.call({
        type: "POST",
        freeze: true,
        args: {
          cmd: 'frappe.core.doctype.user.user.reset_password',
          user: frappe.utils.xss_sanitise((this.email).trim()),
          device: 'website'
        },
        callback: ({message}) => {
          this.form_loading = false;
          this.login_error = '';
          this.email = '';
        },
        always: (response) => {
          this.form_loading = false;
          if (response.exc_type) {
            this.login_error = response.message;
          }
        },
      });
    },
    required(v) {
      return !!v || 'Field is required'
    }
  },
  mounted() {
    setTimeout(() => {
      NProgress.done();
    }, 500);
  }
}
</script>

<style scoped lang="scss">
</style>
