<template>
  <div class="course-quiz-content mt-0" :class="{'form-loading':(quiz.loading || quiz.submit_loading)}">
    <h5 class="mb-0">
      <div class="d-flex">
        {{ quiz.data?.title }}
        <button type="button" @click="cancelQuiz()" class="btn btn-sm btn-danger rounded-pill ms-auto"><i class="far fa-times mr-1"></i> Close</button>
      </div>
    </h5>
    <div><b class="text-secondary">End At : </b> <b class="text-dark">{{ moment(`${quizData.end_date} ${quizData.end_time}`).format('DD, MMM ( hh:mm a )') }}</b></div>
    <template v-if="checkQuizMaxAttempts">
      <template v-if="checkQuizStartTime(quizData?.start_time)">
        <div class="alert alert-info mt-3" style="padding:5px 10px;">
          <p class="mb-0 font-s-13 text-dark">{{ lms_website.__('You will have to get {0}% correct answers in order to pass the quiz.', [quiz.data?.passing_percentage]) }}</p>
        </div>
        <div class="alert alert-warning mt-3" style="padding:5px 10px;" v-if="endCountdown && (!quiz.submit_loading || !quiz.is_submitted)">
          <p class="mb-0 font-s-13 text-danger">{{ lms_website.__('Quiz time will end after') }} : <b>[ {{ endCountdown }}]</b></p>
        </div>
      </template>
    </template>
    <template v-if="!checkQuizMaxAttempts && !quiz.loading">
      <div class="alert alert-warning mt-3" style="padding:5px 10px;">
        <p class="mb-1">{{ lms_website.__('You have reached the maximum number of attempts allowed for this quiz') }}</p>
        <router-link :to="{name:'profile.my_quiz'}"><b>Open Your Submissions</b></router-link>
      </div>
    </template>
    <template v-if="!checkQuizStartTime(quizData?.start_time)">
      <div class="alert alert-info mt-3" style="padding:5px 10px;">
        <p class="mb-0 font-s-13 text-dark">{{ lms_website.__('Quiz will start after') }} : <b>[ {{ startCountdown }}]</b></p>
      </div>
    </template>
    <template v-if="checkQuizStartTime(quizData?.start_time)">
      <template v-if="checkQuizEndTime(quizData.end_time)">
        <template v-if="checkQuizMaxAttempts">
          <div class="text-center mt-3" v-if="quiz.is_submitted">
            <CheckMark v-if="quiz.result?.pass"/>
            <FailMark v-if="!quiz.result?.pass"/>
            <h3 class="mt-3">
              <span class="text-secondary">{{ lms_website.__('You got {0}%', [quiz.result?.percentage]) }} - </span>
              <span class="text-success" v-if="quiz.result?.pass">{{ lms_website.__('Passed') }}</span>
              <span class="text-danger" v-if="!quiz.result?.pass">{{ lms_website.__('Failed') }}</span>
            </h3>
            <p class="text-dark">{{ lms_website.__('Your score is {0}/{1}.', [quiz.result?.score, quiz.result?.score_out_of]) }}</p>
          </div>
          <div v-if="!quiz.is_submitted">
            <template v-for="question in quizQuestions">
              <fieldset>
                <div class="question-title" v-html="question.question"></div>
                <div class="question-content">
                  <template v-if="question.type==='Choices'">
                    <ul class="list-group">
                      <template v-for="(answer) in (question.answers)" :key="answer.uid">
                        <li class="list-group-item d-flex align-items-center" v-if="answer.answer" :data-uid="answer.uid">
                          <template v-if="!question.is_checked">
                            <template v-if="!question.multiple">
                              <input class="form-check-input form-check-input-lg mr-2" type="radio" v-model="quiz.answers[question.name]" :id="`${question.name}-option_${answer.uid}`" :value="answer.answer">
                            </template>
                            <template v-if="question.multiple">
                              <input class="form-check-input form-check-input-lg mr-2" type="checkbox" v-model="quiz.answers[question.name]" :id="`${question.name}-option_${answer.uid}`" :value="answer.answer">
                            </template>
                            <label class="form-check-label stretched-link" :for="`${question.name}-option_${answer.uid}`">{{ answer.answer }}</label>
                          </template>
                          <template v-if="question.is_checked">
                            <span class="badge text-bg-secondary mr-1" v-if="!answer.is_answered && !answer.is_correct"><i class="far fa-minus-circle"></i></span>
                            <span class="badge text-bg-success mr-1" v-if="(answer.is_answered && answer.is_correct) || answer.is_correct"><i class="far fa-check"></i></span>
                            <span class="badge text-bg-danger mr-1" v-if="answer.is_answered && !answer.is_correct"><i class="far fa-times"></i></span>
                            <label class="form-check-label stretched-link">{{ answer.answer }}</label>
                          </template>
                        </li>
                      </template>
                    </ul>
                    <div class="invalid-feedback d-block" v-if="question.validate_error"><i class="far fa-info-circle mr-1"></i> {{ question.validate_error }}</div>
                  </template>
                  <template v-if="question.type==='User Input'">
                    <div class="position-relative">
                      <textarea class="form-control" v-model="quiz.answers[question.name]" :class="{'is-invalid':(question.correct_error),'is-valid':(question.correct_success)}" style="height:100px;resize:none;" placeholder="Your Answer"></textarea>
                      <div class="valid-feedback d-block" v-if="question.correct_success"><i class="far fa-check-circle mr-1"></i> {{ question.correct_success }}</div>
                      <div class="invalid-feedback d-block" v-if="question.correct_error"><i class="far fa-times-circle mr-1"></i> {{ question.correct_error }}</div>
                      <div class="invalid-feedback d-block" v-if="question.validate_error"><i class="far fa-info-circle mr-1"></i> {{ question.validate_error }}</div>
                    </div>
                  </template>
                </div>
              </fieldset>
              <div class="d-flex align-items-center mt-4">
                <b class="text-secondary">Question {{ quiz.pagination.currentPage }} / {{ quiz.data?.custom_number_of_questions }}</b>
                <div class="ms-auto">
                  <template v-if="quiz.data?.show_answers">
                    <button type="button" class="btn btn-sm btn-primary" @click="quiz_check_answer(question)" v-if="!question.is_checked" :disabled="!quiz.answers[question.name] || !quiz.answers[question.name]?.length"><i class="far fa-check-circle"></i> Check</button>
                    <button type="button" class="btn btn-sm btn-secondary" @click="next_question(question)" v-if="question.is_checked && (quiz.pagination.currentPage < quiz.data?.custom_number_of_questions)" :disabled="!quiz.answers[question.name]">Next Question <i class="far fa-arrow-right ml-1"></i></button>
                    <button type="button" class="btn btn-sm btn-primary" @click="submit_answers()" v-if="question.is_checked && (quiz.pagination.currentPage === quiz.data?.custom_number_of_questions)" :disabled="!quiz.answers[question.name]">Submit Answers <i class="far fa-arrow-right ml-1"></i></button>
                  </template>
                  <template v-if="!quiz.data?.show_answers">
                    <button type="button" class="btn btn-sm btn-secondary" @click="next_question(question)" v-if="(quiz.pagination.currentPage < quiz.data?.custom_number_of_questions)" :disabled="!quiz.answers[question.name] || !quiz.answers[question.name]?.length">Next Question <i class="far fa-arrow-right ml-1"></i></button>
                    <button type="button" class="btn btn-sm btn-primary" @click="submit_answers()" v-if="(quiz.pagination.currentPage === quiz.data?.custom_number_of_questions)" :disabled="!quiz.answers[question.name]">Submit Answers <i class="far fa-arrow-right ml-1"></i></button>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>
      </template>
      <template v-if="!checkQuizEndTime(quizData.end_time)">
        <div class="alert alert-warning mt-3" style="padding:5px 10px;">
          <p class="mb-0 font-s-13 text-dark">{{ lms_website.__('See you tomorrow at') }} : <b>[ {{ moment(`${quizData.start_date} ${quizData.start_time}`).format('hh:mm A') }}]</b></p>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import CheckMark from "../../../../components/shared/CheckMark.vue";
import FailMark from "../../../../components/shared/FailMark.vue";
import EventBus from "../../../../utils/bus";

export default {
  name: "QuizDetails",
  components: {FailMark, CheckMark},
  props: {
    quizData: {
      type: Object,
      default: {}
    }
  },
  setup() {
    return {
      lms_website,
      moment
    }
  },
  data() {
    return {
      startCountdown: null,
      endCountdown: null,
      startCountdownInterval: undefined,
      endCountdownInterval: undefined,
      quiz: {
        loading: false,
        is_start: false,
        submit_loading: false,
        is_submitted: false,
        data: {},
        answers: {},
        originalArray: [1, 2, 3, 4],
        shuffledArray: [],
        result: undefined,
        pagination: {
          perPage: 1,
          currentPage: 1
        }
      }
    }
  },
  computed: {
    quizQuestions() {
      if (this.quiz.data && this.quiz.data.questions) {
        const offset = (this.quiz.pagination.currentPage - 1) * this.quiz.pagination.perPage;
        return this.quiz.data.questions.slice(offset, offset + this.quiz.pagination.perPage);
      } else
        return [];
    },
    checkQuizMaxAttempts() {
      if (this.quiz.data?.max_attempts === 0) {
        return true;
      } else {
        return this.quiz.data?.max_attempts > this.quiz.active_quiz_max_attempts;
      }
    }
  },
  watch: {},
  methods: {
    check_quiz_submissions(quiz_name, callback) {
      frappe.call({
        type: "GET",
        method: "lms_website.api.quiz.check_student_quiz_submissions",
        args: {
          quiz: quiz_name
        },
        callback: (response) => {
          callback(response)
        }
      });
    },
    startQuiz(quiz_id) {
      this.quiz.is_start = true;
      this.quiz.loading = true;
      this.quiz.active_quiz = quiz_id;
      this.check_quiz_submissions(quiz_id, ({message}) => {
        this.quiz.active_quiz_max_attempts = message;
        frappe.call({
          type: "GET",
          method: "lms_website.api.quiz.get_quiz_data",
          args: {
            quiz_id: quiz_id
          },
          callback: ({message}) => {
            setTimeout(() => {
              let questions_list = [];
              this.quiz.loading = false;
              this.quiz.data = message;
              if (message.questions && message.questions.length) {
                message.questions.map(question => {
                  question.answers = [];
                  if (question.type === 'Choices') {
                    let valid_answers = [];
                    if (question.multiple) {
                      this.quiz.answers[question.name] = [];
                    }
                    for (let i = 1; i <= 4; i++) {
                      if (question[`is_correct_${i}`] === 1) {
                        valid_answers.push(question[`option_${i}`]);
                      }
                    }
                    question.valid_answers = valid_answers;
                    question.is_correct_answer = (value) => {
                      valid_answers.includes(value)
                    };
                    for (let i = 1; i <= 4; i++) {
                      let uid = this.generateUniqueId();
                      question.answers.push({
                        uid: uid,
                        answer: question[`option_${i}`],
                        is_answered: false,
                        is_correct: valid_answers.includes(question[`option_${i}`])
                      })
                    }
                  } else if (question.type === 'User Input') {
                    question.possibility_answers = [];
                    let possibility_answers = [];
                    for (let i = 1; i <= 4; i++) {
                      if (question[`possibility_${i}`]) {
                        possibility_answers.push(question[`possibility_${i}`]);
                      }
                      question.possibility_answers = possibility_answers;
                    }
                  }
                  questions_list.push(question);
                });
              }
              this.quiz.data['questions'] = questions_list;
              // this.generateRandomAnswers();
            }, 500);
          },
          always: (response) => {
            // this.quiz.loading = false;
            if (response.exception && !response._server_messages) {
              frappe.msgprint({
                title: lms_website.__("Error"),
                message: lms_website.__(response.exception),
                indicator: "red",
              });
            }
          }
        });
      });
    },
    cancelQuiz() {
      this.$swal.fire({
        title: lms_website.__('Will close and clear your answers in quiz?'),
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: lms_website.__('Yes, close it!')
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          this.quizData = null;
          this.quiz = {
            loading: false,
            is_start: false,
            start_date: null,
            end_date: null,
            submit_loading: false,
            is_submitted: false,
            data: {},
            answers: {},
            originalArray: [1, 2, 3, 4],
            shuffledArray: [],
            result: undefined,
          }
          clearInterval(this.startCountdownInterval);
          EventBus.emit('close-active-quiz');
        }
      });
    },
    quiz_check_answer(question) {
      setTimeout(() => {
        if (question.type === 'Choices') {
          if (question.answers && question.answers.length) {
            question.answers.map(answer => {
              if (question.multiple) {
                if (this.quiz.answers[question.name] && this.quiz.answers[question.name].length) {
                  question.is_checked = true;
                  question.validate_error = null;
                  answer.is_answered = (this.quiz.answers[question.name].includes(answer.answer));
                } else {
                  question.is_checked = false;
                  question.validate_error = lms_website.__('Please enter your answer');
                }
              } else {
                answer.is_answered = (answer.answer === this.quiz.answers[question.name]);
                question.is_checked = true;
              }
            });
          }
        } else if (question.type === 'User Input') {
          if ($.trim(this.quiz.answers[question.name])) {
            question.validate_error = null;
            question.correct_success = null;
            question.correct_error = null;
            question.is_checked = true;
            if (question.possibility_answers.includes(this.quiz.answers[question.name])) {
              question.correct_success = lms_website.__('Correct Answer');
              question.correct_error = null;
            } else {
              question.correct_success = null;
              question.correct_error = lms_website.__('Incorrect Answer');
            }
          } else {
            question.validate_error = lms_website.__('Please enter your answer');
          }
        }
      }, 10);
    },
    next_question(question) {
      setTimeout(() => {
        question.is_checked = true;
        this.quiz.pagination.currentPage++;
      }, 100);
    },
    submit_answers() {
      this.$swal.fire({
        title: lms_website.__('Do you want to submit your answers?'),
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: lms_website.__('Yes, submit it!')
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          this.sendAnswers();
          // this.quiz.is_start = false;
          // this.quiz.loading = false;
          // this.quiz.active_quiz = null;
        }
      });
    },
    sendAnswers() {
      let results = [];
      if (this.quiz.data.questions && this.quiz.data.questions.length) {
        this.quiz.data.questions.map((question, index) => {
          let answer_obj = {};
          let question_answer = this.quiz.answers[question.name];
          answer_obj['question_index'] = index + 1;
          answer_obj['question_name'] = question.name;
          answer_obj['question_name'] = question.name;
          if (question.type === 'Choices') {
            if (question.multiple) {
              answer_obj['answer'] = (question_answer && question_answer.length) ? question_answer.join(',') : ['null'].join(',');
              let is_correct = [];
              if (this.quiz.answers[question.name] && this.quiz.answers[question.name].length) {
                this.quiz.answers[question.name].map(value => {
                  is_correct.push(Number(question.valid_answers.includes(value)));
                });
              } else {
                is_correct = [0];
              }
              answer_obj['is_correct'] = is_correct
            } else {
              let choice_answer = ($.trim(question_answer)) ? question_answer : 'null';
              answer_obj['answer'] = String(choice_answer);
              answer_obj['is_correct'] = [Number(question.valid_answers.includes(choice_answer))];
            }
          } else {
            let text_answer = ($.trim(question_answer)) ? question_answer : 'null';
            answer_obj['answer'] = String(text_answer);
            answer_obj['is_correct'] = [Number(question.possibility_answers.includes(text_answer))];
          }
          results.push(answer_obj)
        });

        this.quiz.submit_loading = true;
        this.quiz.is_submitted = false;
        this.endCountdown = null;
        let answer_list = [];
        results.map(r => {
          if (!r.answer) {
            r.answer = 'null';
          }
          if (!r.is_correct || !r.is_correct.length) {
            r.is_correct = [0];
          }
          answer_list.push(r);
        });
      }
      frappe.call({
        type: "POST",
        method: "lms_website.api.quiz.submit_quiz_answers",
        args: {
          quiz: this.quiz.active_quiz,
          results: results,
        },
        callback: ({message}) => {
          this.quiz.submit_loading = false;
          this.quiz.is_submitted = true;
          this.quiz.result = message;
          EventBus.emit('refresh-quiz-completed');
        },
        always: (response) => {
          if (response.exception || response._server_messages) {
            this.quiz.submit_loading = false;
          }
          if (response.exception && !response._server_messages) {
            frappe.msgprint({
              title: lms_website.__("Error"),
              message: lms_website.__(response.exception),
              indicator: "red",
            });
          }
        }
      });
    },
    generateUniqueId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    },
    checkQuizStartTime(timeString) {
      const targetTime = moment(timeString, "HH:mm:ss").set({
        year: moment().year(),
        month: moment().month(),
        date: moment().date(),
      });
      // Get the current time of day
      const currentTime = moment().set({
        year: moment().year(),
        month: moment().month(),
        date: moment().date(),
      });
      return targetTime.isBefore(currentTime);
    },
    checkQuizEndTime(timeString) {
      const targetTime = moment(timeString, "HH:mm:ss").set({
        year: moment().year(),
        month: moment().month(),
        date: moment().date(),
      });
      // Get the current time of day
      const currentTime = moment().set({
        year: moment().year(),
        month: moment().month(),
        date: moment().date(),
      });
      return targetTime.isAfter(currentTime);
    },
    calculateTimeToTarget(targetTimeString, countdownInterval, countdown) {
      const $this = this;
      // Current time
      const now = moment();
      // Parse target time from string (e.g., "4:00:00")
      let targetTime = moment(targetTimeString, "HH:mm:ss");
      // If the current time is already past today's target time, set it to the next day
      if (now.isAfter(targetTime)) {
        targetTime.add(1, 'day');
      }
      // Calculate the difference
      const duration = moment.duration(targetTime.diff(now));
      // Check if the time difference is zero or negative, and clear the interval if true
      if (parseInt(duration.asSeconds()) < 1) {
        // clearInterval($this[countdownInterval]);
        if (countdownInterval === 'startCountdownInterval') {
          setTimeout(() => {
            this.endCountdownInterval = setInterval(() => this.calculateTimeToTarget(this.quizData.end_time, 'endCountdownInterval', 'endCountdown'), 1000);
            clearInterval($this['startCountdownInterval']);
            return false
          }, 1000);
        }
        if (countdownInterval === 'endCountdownInterval') {
          this.sendAnswers();
          clearInterval(this.endCountdownInterval);
          return false
        }
        // return $this[countdown] = null;
      }
      // Extract hours, minutes, and seconds
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      $this[countdown] = `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    }
  },
  beforeDestroy() {
    if (this.startCountdownInterval) {
      clearInterval(this.startCountdownInterval);
    }
    if (this.endCountdownInterval) {
      clearInterval(this.endCountdownInterval);
    }
  },
  mounted() {
    if (this.quizData && this.quizData?.name) {
      this.startQuiz(this.quizData.name);
      if (this.checkQuizStartTime(this.quizData.start_time)) {
        if (this.checkQuizEndTime(this.quizData.end_time))
          this.endCountdownInterval = setInterval(() => this.calculateTimeToTarget(this.quizData.end_time, 'endCountdownInterval', 'endCountdown'), 1000);
      } else {
        this.startCountdownInterval = setInterval(() => this.calculateTimeToTarget(this.quizData.start_time, 'startCountdownInterval', 'startCountdown'), 1000);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
