import {createRouter, createWebHistory} from 'vue-router'
import {useStorage} from "vue3-storage";

// Middleware
import {authMiddleware} from "./middleware/AuthMiddleware"
import {guestMiddleware} from "./middleware/GuestMiddleware"

// Events
import EventBus from "./utils/bus";

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('./pages/home/HomePage.vue'),
        beforeEnter: []
    },
    {
        path: '/index',
        name: 'index',
        beforeEnter: (to, from, next) => {
            next({name: 'home'});
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('./components/404.vue'),
    },
    {
        path: '/auth',
        name: 'auth',
        beforeEnter: (to, from, next) => {
            next({name: 'auth.login'});
        }
    },
    {
        path: '/auth/forgot-password',
        name: 'auth.forgot_password',
        component: () => import('./pages/auth/ForgotPassword.vue'),
        meta: {
            isGuestView: true,
        },
        beforeEnter: [
            guestMiddleware
        ]
    },
    {
        path: '/auth/login',
        name: 'auth.login',
        component: () => import('./pages/auth/Login.vue'),
        meta: {
            isGuestView: true,
        },
        beforeEnter: [
            guestMiddleware
        ]
    },
    {
        path: '/auth/logout',
        name: 'auth.logout',
        beforeEnter: (to, from, next) => {
            frappe.call({
                type: "POST",
                method: "lms_website.api.login.web_logout",
                callback: (response) => {
                    sessionStorage.clear();
                    window.location.reload();
                },
            });
        }
    },
    {
        path: '/student-applicant',
        name: 'student_applicant',
        component: () => import('./pages/student-applicant/StudentApplicant.vue'),
        beforeEnter: [
            guestMiddleware
        ]
    },
    {
        path: '/courses',
        name: 'courses',
        component: () => import('./pages/courses/CoursesPage.vue'),
        beforeEnter: (to, from, next) => {
            if (to.name === 'courses')
                next({name: 'courses.list'});
            next();
        },
        children: [
            {
                path: '',
                name: 'courses.list',
                component: () => import('./pages/courses/CoursesListPage.vue'),
                props: true
            },
            {
                path: 'category/:id',
                name: 'courses.category',
                component: () => import('./pages/courses/CategoryCoursesListPage.vue'),
                props: true
            },
            {
                path: 'view/:id',
                name: 'courses.view',
                component: () => import('./pages/courses/CourseViewPage.vue'),
                props: true
            },
        ]
    },
    {
        path: '/batches',
        name: 'batches',
        component: () => import('./pages/batches/BatchesPage.vue'),
        beforeEnter: (to, from, next) => {
            if (to.name === 'batches')
                next({name: 'home'});
            next();
        },
        children: [
            {
                path: ':id',
                name: 'batches.view',
                component: () => import('./pages/batches/BatcheViewPage.vue'),
                props: true
            },
        ]
    },
    {
        path: '/about-us',
        name: 'about_us',
        component: () => import('./pages/about-us/AboutUs.vue')
    },
    {
        path: '/contact-us',
        name: 'contact_us',
        component: () => import('./pages/contact-us/ContactUs.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('./pages/profile/ProfilePage.vue'),
        children: [
            {
                path: '',
                name: 'profile.view',
                component: () => import('./pages/profile/components/ViewProfile.vue'),
                props: true
            },
            {
                path: '/profile/enrolled-courses',
                name: 'profile.enrolled_courses',
                component: () => import('./pages/profile/components/EnrolledCourses.vue'),
                props: true
            },
            {
                path: '/profile/reviews',
                name: 'profile.reviews',
                component: () => import('./pages/profile/components/Reviews.vue'),
                props: true
            },
            {
                path: '/profile/orders-history',
                name: 'profile.orders_history',
                component: () => import('./pages/profile/components/OrdersHistory.vue'),
                props: true
            },
            {
                path: '/profile/my-quiz',
                name: 'profile.my_quiz',
                component: () => import('./pages/profile/components/MyQuiz.vue'),
                props: true
            },
            {
                path: '/profile/discuss',
                name: 'profile.discuss',
                component: () => import('./pages/profile/components/Discuss.vue'),
                props: true
            },
            {
                path: '/profile/settings',
                name: 'profile.settings',
                component: () => import('./pages/profile/components/Settings.vue'),
                props: true
            }
        ],
        beforeEnter: [
            authMiddleware
        ]
    },
]

let router = createRouter({
    history: createWebHistory('/home'),
    routes,
    scrollBehavior() {
        return {top: 0, left: 0}
    }
});

// Global before guard
router.beforeEach(async (to, from, next) => {
    NProgress.done();
    setTimeout(() => NProgress.start(), 500);
    $('.offcanvas-close-btn, .tp-search-close-btn').trigger('click');
    setTimeout(() => {
        $('.tp-header-inner-login').removeClass('active');
    }, 500);
    EventBus.emit('page-changed');
    if (lms_website.user_session !== 'Guest') {
        const storage = useStorage();
        let UnixAuth = storage.getStorageSync("UnixAuth");
        if (UnixAuth && UnixAuth.is_logged_in == true && UnixAuth.user && UnixAuth.user?.name && (UnixAuth.user?.name === lms_website.user_session)) {
            next();
        } else {
            await frappe.call({
                type: "POST",
                method: "lms_website.api.user.get_user_info",
                args: {
                    user_session: frappe.utils.xss_sanitise((lms_website.user_session).trim()),
                },
                callback: async (response) => {
                    let data = response.message || {};
                    data['is_logged_in'] = true;
                    await storage.setStorage({
                        key: 'UnixAuth',
                        data: data
                    }).then(async (successCallback) => {
                        await next();
                    }).catch((failCallback) => {
                        console.log(failCallback.errMsg);
                    });
                },
            });
        }
    } else {
        next();
    }
});


export default router
