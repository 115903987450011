<template>
  <main>
    <div class="container py-5">
      About Us
    </div>
  </main>
</template>

<script>
import {authData, isLoggedIn} from "../../utils/auth";

export default {
  name: "AboutUs",
  setup() {
    return {
      isLoggedIn
    }
  },
  data() {
    return {
      lms_website: lms_website,
      authData: authData(),
      moment: moment,
    }
  },
  computed: {
    auth() {
      if (this.authData && this.authData.is_logged_in && this.authData.user) {
        return this.authData;
      }
    }
  },
  watch: {},
  methods: {},
  mounted() {
    setTimeout(() => {
      NProgress.done();
      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });
    }, 500);
  }
}
</script>

<style scoped>

</style>
