<template>
  <div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmModalLabel">{{title}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{lms_website.__('Cancel')}}</button>
          <button type="button" class="btn btn-primary" @click="confirm">{{lms_website.__('Confirm')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: lms_website.__('Confirm Action')
    },
    message: {
      type: String,
      default: lms_website.__('Are you sure you want to proceed?')
    }
  },
  setup(){
    return {
      lms_website
    }
  },
  methods: {
    confirm() {
      this.$emit('confirmed');
      $('#confirmModal').modal('hide');
    }
  }
}
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>
