<template>
  <!-- header-area-start -->
  <header class="header-area p-relative">
    <div id="header-sticky" class="tp-header-2">
      <div class="container custom-container">
        <div class="row align-items-center">
          <div class="col-xl-3 col-lg-4 col-md-6 col-6">
            <div class="tp-header-2-right d-flex align-items-center">
              <div class="tp-header-inner-logo tp-header-logo">
                <router-link :to="{ name: 'home' }">
                  <img src="/assets/lms_website/images/unix_logo-sm.jpg" alt="UNIX">
                </router-link>
              </div>
              <div class="tp-header-2-category tp-header-inner-category home-2 d-none d-md-block" v-if="batches.list?.length">
                <a class="tp-header-2-category-icon" href="course-with-sidebar.html">
                  <p><span><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path class="color" d="M13.9998 4.564V1.386C13.9998 0.399 13.5518 0 12.4388 0H9.6108C8.4978 0 8.0498 0.399 8.0498 1.386V4.557C8.0498 5.551 8.4978 5.943 9.6108 5.943H12.4388C13.5518 5.95 13.9998 5.551 13.9998 4.564Z" fill="currentColor"/>
                                 <path opacity="0.4" d="M13.9998 12.439V9.611C13.9998 8.498 13.5518 8.05 12.4388 8.05H9.6108C8.4978 8.05 8.0498 8.498 8.0498 9.611V12.439C8.0498 13.552 8.4978 14 9.6108 14H12.4388C13.5518 14 13.9998 13.552 13.9998 12.439Z" fill="#1A73E8"/>
                                 <path opacity="0.4" d="M5.95 4.564V1.386C5.95 0.399 5.502 0 4.389 0H1.561C0.448 0 0 0.399 0 1.386V4.557C0 5.551 0.448 5.943 1.561 5.943H4.389C5.502 5.95 5.95 5.551 5.95 4.564Z" fill="#1A73E8"/>
                                 <path class="color" d="M5.95 12.439V9.611C5.95 8.498 5.502 8.05 4.389 8.05H1.561C0.448 8.05 0 8.498 0 9.611V12.439C0 13.552 0.448 14 1.561 14H4.389C5.502 14 5.95 13.552 5.95 12.439Z" fill="currentColor"/>
                                 </svg></span>
                    {{ lms_website.__('Batches') }}
                  </p>
                </a>
                <div class="tp-header-2-category-list">
                  <ul>
                    <li v-for="batch in batches.list">
                      <router-link :to="`/batches/${batch?.name}`">{{batch?.title}}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-8 d-none d-xl-block">
            <div class="main-menu text-lg-center d-none d-xl-block">
              <nav class="tp-main-menu-content">
                <ul>
                  <li class="tp-static">
                    <router-link :to="{ name: 'home' }">{{ lms_website.__('Home') }}</router-link>
                  </li>
                  <li class="p-static">
                    <router-link :to="{ name: 'courses.list' }">{{ lms_website.__('Courses') }}</router-link>
                  </li>
                  <li class="p-static">
                    <router-link :to="{ name: 'about_us' }">{{ lms_website.__('About UNIX') }}</router-link>
                  </li>
                  <li class="p-static">
                    <router-link :to="{ name: 'contact_us' }">{{ lms_website.__('Contact Us') }}</router-link>
                  </li>
                  <!--                      <li class="has-dropdown"><a href="student-dashboard.html">Student Dashboard</a>-->
                  <!--                        <ul class="tp-submenu">-->
                  <!--                          <li><a href="student-dashboard.html">Dashboard</a></li>-->
                  <!--                          <li><a href="student-profile.html">Profile</a></li>-->
                  <!--                          <li><a href="student-enroll-course.html">Enrolled Courses</a></li>-->
                  <!--                          <li><a href="student-wishlist.html">Wishlist</a></li>-->
                  <!--                          <li><a href="student-reviews.html">Reviews</a></li>-->
                  <!--                          <li><a href="student-my-quiz.html">My Quiz Attempts</a></li>-->
                  <!--                          <li><a href="student-setting-profile.html">Settings</a></li>-->
                  <!--                        </ul>-->
                  <!--                      </li>-->
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-xl-5 col-lg-8 col-6">
            <div class="tp-header-2-contact d-flex align-items-center justify-content-end">
              <div class="tp-header-inner-search">
                <button class="tp-search-open-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M13.4 13.4L17 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.4 8.19999C15.4 4.22354 12.1765 1 8.20001 1C4.22357 1 1.00002 4.22354 1.00002 8.19999C1.00002 12.1764 4.22357 15.4 8.20001 15.4C12.1765 15.4 15.4 12.1764 15.4 8.19999Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
              <template v-if="!isLoggedIn()">
                <div class="home-2 d-none d-xl-flex gap-2">
                  <a href="/student-applicant" class="btn btn-dark"><i class="far fa-user-plus mr-1"></i> {{ lms_website.__('Student Applicant') }}</a>
                  <router-link class="tp-btn-inner" :to="{ name: 'auth.login' , query: { redirect: $route.fullPath }}"><i class="far fa-sign-in mr-1"></i> {{ lms_website.__('Log In') }}</router-link>
                </div>
              </template>
              <template v-if="isLoggedIn()">
                <div class="tp-header-inner-login tp-header-user-hover">
                  <button><img :src="(auth?.user?.user_image)?auth?.user?.user_image:'/assets/lms_website/images/user.png'" :alt="auth?.user?.fullname"></button>
                  <div class="tp-header-user-box">
                    <div class="tp-header-user-content">
                      <div class="tp-header-user-profile d-flex align-items-center">
                        <div class="tp-header-user-profile-thumb">
                          <img :src="(auth?.user?.user_image)?auth?.user?.user_image:'/assets/lms_website/images/user.png'" :alt="auth?.user?.fullname"/>
                        </div>
                        <div class="tp-header-user-profile-content">
                          <h4>{{ auth?.user?.full_name || auth?.user?.first_name }}</h4>
                          <span class="text-truncate d-block" style="width:170px;">{{ auth?.user?.user_category || auth?.user?.email }}</span>
                        </div>
                      </div>
                      <div class="tp-header-user-list">
                        <ul>
                          <li>
                            <router-link :to="{ name: 'profile.view' }">
                            <span>
                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        opacity="0.4"
                                        d="M7.98015 8.78062C10.4049 8.78062 12.3705 6.81501 12.3705 4.39031C12.3705 1.96561 10.4049 0 7.98015 0C5.55545 0 3.58984 1.96561 3.58984 4.39031C3.58984 6.81501 5.55545 8.78062 7.98015 8.78062Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        d="M7.98158 10.9755C3.58249 10.9755 0 13.9258 0 17.5609C0 17.8068 0.193174 18 0.439031 18H15.5241C15.77 18 15.9632 17.8068 15.9632 17.5609C15.9632 13.9258 12.3807 10.9755 7.98158 10.9755Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                              My Profile
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'profile.enrolled_courses' }">
                              <span>
                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.4"
                                        d="M13.4349 9.71387V13.9033C13.4349 14.9826 12.593 16.1383 11.581 16.4782L8.86831 17.379C8.3921 17.5404 7.61825 17.5404 7.15054 17.379L4.43782 16.4782C3.41736 16.1383 2.58398 14.9826 2.58398 13.9033L2.59249 9.71387L6.35118 12.1613C7.26959 12.7646 8.78328 12.7646 9.70169 12.1613L13.4349 9.71387Z"
                                        fill="currentColor"></path>
                                  <path
                                      d="M14.7945 4.29218L9.70074 0.952512C8.78233 0.349163 7.26865 0.349163 6.35023 0.952512L1.23093 4.29218C-0.41031 5.35441 -0.41031 7.75931 1.23093 8.83004L2.59154 9.71382L6.35023 12.1612C7.26865 12.7646 8.78233 12.7646 9.70074 12.1612L13.4339 9.71382L14.5989 8.94901V11.5494C14.5989 11.8978 14.8881 12.1867 15.2367 12.1867C15.5854 12.1867 15.8745 11.8978 15.8745 11.5494V7.36841C16.2147 6.27218 15.866 4.9975 14.7945 4.29218Z"
                                      fill="currentColor"></path>
                                </svg>
                              </span>
                              {{ lms_website.__('Enrolled Courses') }}
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'profile.orders_history' }">
                              <span>
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                      d="M14.2903 2.86564H13.9703L11.2667 0.16198C11.0507 -0.0539932 10.6987 -0.0539932 10.4748 0.16198C10.2588 0.377953 10.2588 0.729909 10.4748 0.953881L12.3865 2.86564H4.61149L6.52325 0.953881C6.73922 0.737908 6.73922 0.385952 6.52325 0.16198C6.30727 -0.0539932 5.95532 -0.0539932 5.73135 0.16198L3.03568 2.86564H2.71572C1.99581 2.86564 0.5 2.86564 0.5 4.91339C0.5 5.68929 0.65998 6.20122 0.995938 6.53718C1.18791 6.73716 1.41989 6.84114 1.66785 6.89714C1.89982 6.95313 2.14779 6.96113 2.38776 6.96113H14.6102C14.8582 6.96113 15.0902 6.94513 15.3141 6.89714C15.9861 6.73716 16.498 6.25722 16.498 4.91339C16.498 2.86564 15.0022 2.86564 14.2903 2.86564Z"
                                      fill="currentColor"/>
                                <path
                                    d="M14.6193 6.96103H2.38886C2.15688 6.96103 1.90092 6.95303 1.66895 6.88904L2.67682 13.0403C2.90079 14.4161 3.50072 15.9999 6.16438 15.9999H10.6518C13.3475 15.9999 13.8274 14.6481 14.1154 13.1363L15.3232 6.88904C15.0993 6.94503 14.8593 6.96103 14.6193 6.96103ZM8.50009 13.2002C6.62833 13.2002 5.10052 11.6724 5.10052 9.80067C5.10052 9.47272 5.37248 9.20075 5.70044 9.20075C6.0284 9.20075 6.30037 9.47272 6.30037 9.80067C6.30037 11.0165 7.28424 12.0004 8.50009 12.0004C9.71594 12.0004 10.6998 11.0165 10.6998 9.80067C10.6998 9.47272 10.9718 9.20075 11.2997 9.20075C11.6277 9.20075 11.8997 9.47272 11.8997 9.80067C11.8997 11.6724 10.3719 13.2002 8.50009 13.2002Z"
                                    fill="currentColor"/>
                             </svg>
                              </span>
                              {{ lms_website.__('Orders History') }}
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'profile.settings' }">
                              <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.60509 10.2644C6.32151 10.2644 5.2832 9.26439 5.2832 8.00839C5.2832 6.75239 6.32151 5.74438 7.60509 5.74438C8.88867 5.74438 9.90245 6.75239 9.90245 8.00839C9.90245 9.26439 8.88867 10.2644 7.60509 10.2644Z" fill="currentColor"/>
                                <path opacity="0.4"
                                      d="M14.9841 9.896C14.8288 9.656 14.608 9.416 14.3219 9.264C14.093 9.152 13.9458 8.968 13.815 8.752C13.398 8.064 13.6433 7.16 14.3382 6.752C15.1558 6.296 15.4174 5.28 14.9432 4.488L14.3955 3.544C13.9294 2.752 12.9075 2.472 12.0981 2.936C11.3786 3.32 10.4548 3.064 10.0378 2.384C9.90702 2.16 9.83344 1.92 9.84979 1.68C9.87432 1.368 9.77621 1.072 9.62905 0.832C9.32655 0.336 8.77878 0 8.17378 0H7.02101C6.42419 0.016 5.87642 0.336 5.57392 0.832C5.41858 1.072 5.32865 1.368 5.345 1.68C5.36135 1.92 5.28777 2.16 5.15696 2.384C4.74 3.064 3.81615 3.32 3.10487 2.936C2.28731 2.472 1.27352 2.752 0.799336 3.544L0.251567 4.488C-0.214445 5.28 0.0471757 6.296 0.856566 6.752C1.5515 7.16 1.79677 8.064 1.38798 8.752C1.249 8.968 1.10184 9.152 0.872917 9.264C0.594945 9.416 0.349675 9.656 0.218865 9.896C-0.0836348 10.392 -0.0672835 11.016 0.235216 11.536L0.799336 12.496C1.10184 13.008 1.66596 13.328 2.2546 13.328C2.53258 13.328 2.8596 13.248 3.12122 13.088C3.32561 12.952 3.57088 12.904 3.84068 12.904C4.65007 12.904 5.32865 13.568 5.345 14.36C5.345 15.28 6.09716 16 7.04554 16H8.15743C9.09763 16 9.84979 15.28 9.84979 14.36C9.87432 13.568 10.5529 12.904 11.3623 12.904C11.6239 12.904 11.8692 12.952 12.0817 13.088C12.3434 13.248 12.6622 13.328 12.9484 13.328C13.5288 13.328 14.093 13.008 14.3955 12.496L14.9678 11.536C15.2621 11 15.2866 10.392 14.9841 9.896Z"
                                      fill="currentColor"/>
                                </svg>
                              </span>
                              {{ lms_website.__('Settings') }}
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'auth.logout' }">
                              <span>
                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        opacity="0.4"
                                        d="M9.94863 0C10.3714 0 10.7222 0.341829 10.7222 0.773613V17.2264C10.7222 17.6492 10.3804 18 9.94863 18C4.65028 18 0.953125 14.3028 0.953125 9.0045C0.953125 3.70615 4.65927 0 9.94863 0Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        d="M16.5143 8.58188L13.9596 6.01816C13.6987 5.75729 13.2669 5.75729 13.006 6.01816C12.7452 6.27903 12.7452 6.71082 13.006 6.97169L14.4093 8.37498H5.80064C5.43182 8.37498 5.12598 8.68083 5.12598 9.04965C5.12598 9.41846 5.43182 9.72431 5.80064 9.72431H14.4093L13.006 11.1276C12.7452 11.3885 12.7452 11.8203 13.006 12.0811C13.141 12.2161 13.3119 12.279 13.4828 12.279C13.6537 12.279 13.8246 12.2161 13.9596 12.0811L16.5143 9.51741C16.7752 9.26554 16.7752 8.84275 16.5143 8.58188Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                              {{ lms_website.__('Logout') }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </template>
              <div class="offcanvas-btn d-xl-none ml-30">
                <button class="offcanvas-open-btn"><i class="fa-solid fa-bars"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- header-area-end -->
</template>

<script>

import {isLoggedIn, authData} from '../utils/auth';

export default {
  name: "Header",
  setup() {
    return {
      isLoggedIn
    }
  },
  data() {
    return {
      lms_website: lms_website,
      authData: authData() || {
        user: {}
      },
      batches: {
        list: []
      }
    }
  },
  methods: {
    get_batches() {
      frappe.call({
        type: "GET",
        method: "lms_website.api.utils.get_header_batches",
        args: {},
        callback: ({message}) => {
          this.batches.list = message;
        }
      });
    }
  },
  computed: {
    auth() {
      if (this.authData && this.authData.is_logged_in && this.authData.user) {
        return this.authData;
      }
    }
  },
  watch: {},
  mounted() {
    this.get_batches();
    setTimeout(() => {

      // 02. Mobile menu js
      if ($('.tp-main-menu-content').length && $('.tp-main-menu-mobile').length) {
        let navContent = document.querySelector(".tp-main-menu-content").outerHTML;
        let mobileNavContainer = document.querySelector(".tp-main-menu-mobile");
        mobileNavContainer.innerHTML = navContent;

        let arrow = $(".tp-main-menu-mobile .has-dropdown > a");

        arrow.each(function () {
          let self = $(this);
          let arrowBtn = document.createElement("BUTTON");
          arrowBtn.classList.add("dropdown-toggle-btn");

          // Append the arrow button after the link
          self[0].appendChild(arrowBtn);

          function toggleDropdown(e) {
            e.preventDefault();
            let self = $(this);
            let parent = self.parent();
            let submenu = parent.children(".tp-submenu, .tp-megamenu-main");

            self.toggleClass("dropdown-opened");
            parent.toggleClass("expanded");
            parent.parent().addClass("dropdown-opened").siblings().removeClass("dropdown-opened").find(".tp-submenu, .tp-megamenu-main").slideUp();

            submenu.slideToggle();
          }

          // Add event listeners to both the link and the button
          self.on("click", toggleDropdown);
          $(arrowBtn).on("click", toggleDropdown);
        });
      }

      if ($('.tp-header-user-hover').length > 0) {
        window.addEventListener('click', function (e) {
          let clickedElement = e.target;

          $('.tp-header-user-hover').each(function () {
            if (this.contains(clickedElement)) {
              $(this).toggleClass('active');
            } else {
              $(this).removeClass('active');
            }
          });
        });
      }

      // 07. Search Js
      $(".tp-search-open-btn").on("click", function () {
        $(".tp-search-area").addClass("opened");
        $(".body-overlay").addClass("opened");
      });

      $(".tp-search-close-btn").on("click", function () {
        $(".tp-search-area").removeClass("opened");
        $(".body-overlay").removeClass("opened");
      });

      // . language
      if ($("#header-bottom__lang-toggle").length > 0) {
        window.addEventListener('click', function (e) {
          if (document.getElementById('header-bottom__lang-toggle').contains(e.target)) {
            $(".header-bottom__lang-submenu, .header-bottom__lang-submenu-2").toggleClass("open");
          } else {
            $(".header-bottom__lang-submenu, .header-bottom__lang-submenu-2").removeClass("open");
          }
        });
      }

    }, 500);
  }
}
</script>

<style lang="scss" scoped>

</style>
