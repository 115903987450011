<template>
  <div class="tpd-course-area">
    <div class="tp-dashboard-tab mb-25">
      <h2 class="tp-dashboard-tab-title">Settings</h2>
      <div class="tp-dashboard-tab-list">
        <ul>
          <li>
            <a @click="(!form_lading)?active_tab='profile':undefined" :class="{'active':(active_tab==='profile'),'pointer-event':(!form_lading)}">Profile</a>
          </li>
          <li>
            <a @click="(!form_lading)?active_tab='details':undefined" :class="{'active':(active_tab==='details'),'pointer-event':(!form_lading)}">Details</a>
          </li>
          <li>
            <a @click="(!form_lading)?active_tab='password':undefined" :class="{'active':(active_tab==='password'),'pointer-event':(!form_lading)}">Password</a>
          </li>
        </ul>
      </div>
    </div>
    <form method="post" @submit="update_profile($event)" v-if="active_tab==='profile' || active_tab==='details'">
      <template v-if="active_tab==='profile'">
        <div class="tpd-setting-box profile" :class="{'form-loading':form_lading}">
          <div class="tp-dashboard-banner-bg profile mb-100" :data-background="(auth.user?.cover_image)?auth.user?.cover_image:'/assets/lms_website/website-assets/img/dashboard/bg/dashboard-bg-1.jpg'">
            <div class="tp-instructor-wrap d-flex justify-content-between">
              <div class="tp-instructor-info d-flex">
                <div class="tp-instructor-avatar p-relative profile">
                  <img :src="(auth.user?.user_image)?auth.user?.user_image:'/assets/lms_website/images/user.png'" alt="">
                  <span class="pointer-event" @click="upload_photo('user_image')">
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="19" cy="19" r="18" fill="white" stroke="#E6E8F0"></circle>
                      <path
                          d="M23.352 13.5172L23.272 13.3463C23.056 12.9034 22.808 12.3907 22.656 12.0954C22.288 11.3962 21.656 11.0078 20.88 11H17.112C16.336 11.0078 15.712 11.3962 15.344 12.0954C15.184 12.4062 14.912 12.9656 14.688 13.424L14.64 13.5172C14.616 13.5794 14.552 13.6104 14.488 13.6104C12.56 13.6104 11 15.1332 11 16.9978V21.6127C11 23.4772 12.56 25 14.488 25H23.512C25.432 25 27 23.4772 27 21.6127V16.9978C27 15.1332 25.432 13.6104 23.512 13.6104C23.44 13.6104 23.384 13.5716 23.352 13.5172Z"
                          fill="#303651"></path>
                      <path
                          d="M19.0035 15.9806C19.8435 15.9806 20.6355 16.2992 21.2275 16.8741C21.8195 17.4568 22.1475 18.2259 22.1475 19.0339C22.1395 19.8808 21.7875 20.6421 21.2195 21.1937C20.6515 21.7454 19.8675 22.0872 19.0035 22.0872C18.1635 22.0872 17.3795 21.7687 16.7795 21.1937C16.1875 20.6111 15.8595 19.8497 15.8595 19.0339C15.8515 18.2259 16.1795 17.4646 16.7715 16.8819C17.3715 16.2992 18.1635 15.9806 19.0035 15.9806ZM19.0035 17.146C18.4835 17.146 17.9955 17.3402 17.6195 17.7054C17.2515 18.0628 17.0515 18.5367 17.0595 19.0262V19.0339C17.0595 19.5389 17.2595 20.0128 17.6275 20.3702C17.9955 20.7276 18.4835 20.9218 19.0035 20.9218C20.0755 20.9218 20.9395 20.075 20.9475 19.0339C20.9475 18.5289 20.7475 18.055 20.3795 17.6976C20.0115 17.3402 19.5235 17.146 19.0035 17.146ZM23.4915 15.7942C23.8915 15.7942 24.2195 16.1127 24.2195 16.5012C24.2195 16.8896 23.8915 17.2004 23.4915 17.2004C23.0915 17.2004 22.7715 16.8896 22.7715 16.5012C22.7715 16.1127 23.0915 15.7942 23.4915 15.7942Z"
                          fill="white"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div class="tp-instructor-course-btn profile">
                <a class="tp-btn-add-course profile pointer-event" @click="upload_photo('cover_image')">
                  <span>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M13.624 2.8768L13.539 2.68146C13.3095 2.17536 13.046 1.58935 12.8845 1.25194C12.4935 0.45283 11.822 0.00887902 10.9975 0H6.994C6.1695 0.00887902 5.5065 0.45283 5.1155 1.25194C4.9455 1.6071 4.6565 2.24639 4.4185 2.77026L4.3675 2.8768C4.342 2.94784 4.274 2.98335 4.206 2.98335C2.1575 2.98335 0.5 4.72364 0.5 6.85461V12.1287C0.5 14.2597 2.1575 16 4.206 16H13.794C15.834 16 17.5 14.2597 17.5 12.1287V6.85461C17.5 4.72364 15.834 2.98335 13.794 2.98335C13.7175 2.98335 13.658 2.93896 13.624 2.8768Z"
                          fill="white"></path>
                      <path
                          d="M9.00471 5.69173C9.89721 5.69173 10.7387 6.05577 11.3677 6.71282C11.9967 7.37875 12.3452 8.25777 12.3452 9.18119C12.3367 10.149 11.9627 11.0191 11.3592 11.6496C10.7557 12.28 9.92271 12.6706 9.00471 12.6706C8.11221 12.6706 7.27921 12.3066 6.64171 11.6496C6.01271 10.9836 5.66421 10.1135 5.66421 9.18119C5.65571 8.25777 6.00421 7.38763 6.63321 6.7217C7.27071 6.05577 8.11221 5.69173 9.00471 5.69173ZM9.00471 7.02359C8.45221 7.02359 7.93371 7.24556 7.53421 7.66288C7.14321 8.07131 6.93071 8.61293 6.93921 9.17231V9.18119C6.93921 9.75833 7.15171 10.2999 7.54271 10.7084C7.93371 11.1168 8.45221 11.3388 9.00471 11.3388C10.1437 11.3388 11.0617 10.371 11.0702 9.18119C11.0702 8.60405 10.8577 8.06243 10.4667 7.654C10.0757 7.24556 9.55721 7.02359 9.00471 7.02359ZM13.7732 5.47864C14.1982 5.47864 14.5467 5.84268 14.5467 6.28663C14.5467 6.73058 14.1982 7.08574 13.7732 7.08574C13.3482 7.08574 13.0082 6.73058 13.0082 6.28663C13.0082 5.84268 13.3482 5.47864 13.7732 5.47864Z"
                          fill="#333333"></path>
                    </svg>
                </span>
                  Edit Cover Photo</a>
              </div>
            </div>
          </div>
          <div class="tpd-setting-from">
            <div class="row">
              <div class="col-lg-6">
                <div class="tpd-input">
                  <label for="first-name">First Name</label>
                  <input type="text" id="first-name" v-model="user_data.first_name" name="first_name" required>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="tpd-input">
                  <label for="last-name">Last Name</label>
                  <input type="text" id="last-name" v-model="user_data.last_name" name="last_name">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="tpd-input">
                  <label for="username">Username</label>
                  <input type="text" id="username" v-model="user_data.username" disabled readonly>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="tpd-input">
                  <label for="email">Email</label>
                  <input type="email" id="email" v-model="user_data.email" name="email" disabled readonly>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="tpd-input">
                  <label for="phone">Phone Number</label>
                  <input type="text" id="phone" v-model="user_data.phone" name="phone">
                </div>
              </div>
              <div class="col-lg-6">
                <label for="gender">Gender</label>
                <div class="mt-3">
                  <div class="form-check form-check-lg form-check-inline">
                    <input class="form-check-input" type="radio" name="gender" id="gender-male" v-model="user_data.gender" value="Male">
                    <label class="form-check-label" for="gender-male">Male</label>
                  </div>
                  <div class="form-check form-check-lg form-check-inline">
                    <input class="form-check-input" type="radio" name="gender" id="gender-female" v-model="user_data.gender" value="Female">
                    <label class="form-check-label" for="gender-female">Female</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="tpd-input">
                  <label for="bio">About me</label>
                  <textarea id="bio" v-model="user_data.bio" name="bio"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="tpd-setting-cartificate">
            <div class="tpd-setting-cartificate-btn text-right">
              <button type="submit" :disabled="form_lading"><span class="spinner-border spinner-border-sm mr-5" aria-hidden="true" v-if="form_lading"></span> Update Profile</button>
            </div>
          </div>
        </div>
      </template>
      <template v-if="active_tab==='details'">
        <div class="tpd-setting-box profile" :class="{'form-loading':form_lading}">
          <div class="tpd-setting-from mt-4">
            <div class="row">
              <div class="col-lg-6 mb-2">
                <label class="form-label" for="linkedin">LinkedIn URL</label>
                <input type="text" class="form-control form-control-sm" id="linkedin" v-model="user_data.linkedin" name="linkedin" placeholder="">
              </div>
              <div class="col-lg-6 mb-2">
                <label for="multiSelect" class="form-label">Skills</label>
                <div class="dropdown">
                  <button
                      class="btn btn-outline-secondary text-left dropdown-toggle w-100 text-truncate"
                      type="button"
                      id="skillsDropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                    {{ skills.selected.length ? skills.selected.join(', ') : 'Select Skills' }}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="skillsDropdownMenuButton">
                    <li v-for="skill in skills.list" :key="skill" class="dropdown-item">
                      <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            :value="skill"
                            v-model="skills.selected"
                            :id="`option-${skill}`"
                        />
                        <label class="form-check-label" :for="`option-${skill}`">
                          {{ skill }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-12 mb-2">
                <div class="card">
                  <div class="card-header">
                    <ul class="nav nav-pills card-header-pills" id="pills-tab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button type="button" class="nav-link active" id="pills-education-tab" data-bs-toggle="pill" data-bs-target="#pills-education"><i class="far fa-graduation-cap mr-1"></i> Education</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button type="button" class="nav-link" id="pills-work_experience-tab" data-bs-toggle="pill" data-bs-target="#pills-work_experience"><i class="far fa-briefcase mr-1"></i> Work Experience</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button type="button" class="nav-link" id="pills-certification-tab" data-bs-toggle="pill" data-bs-target="#pills-certification"><i class="far fa-file-certificate mr-1"></i> Certification</button>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <div class="tab-content" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="pills-education" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                        <DetailsTable table-name="education" :data="auth?.education" :fields="dialog_fields.education" title="Education"/>
                      </div>
                      <div class="tab-pane fade" id="pills-work_experience" role="tabpanel" aria-labelledby="pills-work_experience-tab" tabindex="0">
                        <DetailsTable table-name="work_experience" :data="auth?.work_experience" :fields="dialog_fields.work_experience" title="Work Experience"/>
                      </div>
                      <div class="tab-pane fade" id="pills-certification" role="tabpanel" aria-labelledby="pills-certification-tab" tabindex="0">
                        <DetailsTable table-name="certification" :data="auth?.certification" :fields="dialog_fields.certifications" title="Certification"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tpd-setting-cartificate">
            <div class="tpd-setting-cartificate-btn text-right">
              <button type="submit" :disabled="form_lading"><span class="spinner-border spinner-border-sm mr-5" aria-hidden="true" v-if="form_lading"></span> Update Details</button>
            </div>
          </div>
        </div>
      </template>
    </form>
    <template v-if="active_tab==='password'">
      <div class="tpd-setting-box">
        <div class="tpd-setting-password-content">
          <div class="col-lg-8">
            <div class="tpd-input">
              <label for="">Current Password</label>
              <input type="text" placeholder="Current Password">
            </div>
          </div>
          <div class="col-lg-8">
            <div class="tpd-input">
              <label for="">New Password</label>
              <input type="text" placeholder="New Password">
            </div>
          </div>
          <div class="col-lg-8">
            <div class="tpd-input">
              <label for="">Re-type New Password</label>
              <input type="text" placeholder="Re-type New Password">
            </div>
          </div>
          <div class="col-lg-8">
            <div class="tpd-setting-btn">
              <button>Set New Password</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {useStorage} from "vue3-storage";
import {authData, isLoggedIn} from "../../../utils/auth";
import {frappeAPI} from "../../../services/frappe";
import EventBus from "../../../utils/bus";
import DetailsTable from "./partials/DetailsTable.vue";

const storage = useStorage();

export default {
  name: "Settings",
  components: {DetailsTable},
  props: {},
  setup() {
    return {
      isLoggedIn
    }
  },
  data() {
    return {
      lms_website: lms_website,
      skills: {
        list: [],
        selected: []
      },
      authData: authData(),
      moment: moment,
      active_tab: 'profile',
      form_lading: false,
      user_data: {},
      dialog: {},
      dialog_selected_row: undefined,
      dialog_fields: {
        education: [
          {
            fieldname: "institution_name",
            fieldtype: "Data",
            label: lms_website.__("Institution Name"),
            reqd: 1
          },
          {
            fieldname: "location",
            fieldtype: "Data",
            label: lms_website.__("Location"),
            reqd: 1
          },
          {
            fieldname: "major",
            fieldtype: "Data",
            label: lms_website.__("Field of Major/Study"),
            reqd: 1
          },
          {
            fieldname: "grade",
            fieldtype: "Data",
            label: lms_website.__("Grade"),
            reqd: 1
          },
          {
            fieldname: "degree_type",
            fieldtype: "Data",
            label: lms_website.__("Degree Type"),
            reqd: 1
          },
          {
            fieldname: "start_date",
            fieldtype: "Date",
            label: lms_website.__("Start Date"),
            reqd: 1
          },
          {
            fieldname: "end_date",
            fieldtype: "Date",
            label: lms_website.__("End Date (or expected)"),
            reqd: 1
          },
          // {
          //   fieldname: "dsfsdf",
          //   fieldtype: "Small Text",
          //   label: lms_website.__("dsfsdf")
          // }  ,
        ],
        work_experience: [
          {
            fieldname: "title",
            fieldtype: "Data",
            label: lms_website.__("Title"),
            reqd: 1
          },
          {
            fieldname: "company",
            fieldtype: "Data",
            label: lms_website.__("Company"),
            reqd: 1
          },
          {
            fieldname: "location",
            fieldtype: "Data",
            label: lms_website.__("Location"),
            reqd: 1
          },
          {
            fieldname: "from_date",
            fieldtype: "Date",
            label: lms_website.__("From Date"),
            reqd: 1
          },
          {
            fieldname: "current",
            fieldtype: "Check",
            label: lms_website.__("I am currently working here"),
            reqd: 0
          },
          {
            fieldname: "to_date",
            fieldtype: "Date",
            label: lms_website.__("To Date"),
            reqd: 0
          },
          {
            fieldname: "description",
            fieldtype: "Small Text",
            label: lms_website.__("Description")
          }
        ],
        certifications: [
          {
            fieldname: "certification_name",
            fieldtype: "Data",
            label: lms_website.__("Certification Name"),
            reqd: 1
          },
          {
            fieldname: "organization",
            fieldtype: "Data",
            label: lms_website.__("Organization"),
            reqd: 1
          },
          {
            fieldname: "issue_date",
            fieldtype: "Date",
            label: lms_website.__("Issue Date"),
            reqd: 1
          },
          {
            fieldname: "expire",
            fieldtype: "Check",
            label: lms_website.__("This certificate does no expire"),
            reqd: 0
          },
          {
            fieldname: "expiration_date",
            fieldtype: "Date",
            label: lms_website.__("Expiration Date"),
            reqd: 0
          },
          {
            fieldname: "description",
            fieldtype: "Small Text",
            label: lms_website.__("Description")
          }
        ]
      }
    }
  },
  computed: {
    auth() {
      if (this.authData && this.authData.is_logged_in && this.authData.user) {
        return this.authData;
      }
    }
  },
  watch: {
    active_tab(active_tab) {
      console.log('active_tab', active_tab)
      setTimeout(() => {
        $("[data-background]").each(function () {
          $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
        });
      }, 10);
    }
  },
  methods: {
    upload_photo(field_name) {
      new frappe.ui.FileUploader({
        doctype: 'User',
        folder: "Home/Attachments",
        dialog_title: lms_website.__('Upload Photo'),
        allow_multiple: false,
        restrictions: {
          allowed_file_types: ["image/*"]
        },
        make_attachments_public: true,
        on_success: ({file_url}) => {
          setTimeout(() => {
            this.user_data[field_name] = file_url;
            this.authData.user[field_name] = file_url;
            setTimeout(() => {
              $("[data-background]").each(function () {
                $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
              });
            }, 300);
          }, 100);
        },
      });
    },
    update_profile($event) {
      $event.preventDefault();
      this.form_lading = true;
      NProgress.start();
      this.user_data.skills = [];
      this.skills.selected.map(skill => this.user_data.skills.push({skill_name: skill}));
      this.user_data.educations = this.auth.education;
      this.user_data.work_experience = this.auth.work_experience;
      this.user_data.certifications = this.auth.certification;
      // console.log('this.user_data', this.user_data)
      // return
      frappe.call({
        type: "POST",
        method: "lms_website.api.user.update_profile_data",
        args: {
          data: this.user_data
        },
        callback: ({message}) => {
          frappe.call({
            type: "POST",
            method: "lms_website.api.user.get_user_info",
            args: {
              user_session: frappe.utils.xss_sanitise((lms_website.user_session).trim()),
            },
            callback: (response) => {
              let data = response.message || {};
              data['is_logged_in'] = true;
              storage.setStorage({
                key: 'UnixAuth',
                data: data
              }).then((successCallback) => {
                // NProgress.done();
                // this.form_lading = false;
                this.$toast.success(this.lms_website.__("You profile has been updated"));
                EventBus.emit('update-auth-data', data);
                window.location.reload();
              });
            },
          });
        },
        always: (response) => {
          if (response.exception || response._server_messages) {
            NProgress.done();
            this.form_lading = false;
          }
          if (response.exception && !response._server_messages) {
            frappe.msgprint({
              title: this.lms_website.__("Error"),
              message: this.lms_website.__(response.exception),
              indicator: "red",
            });
          }
        }
      });
    },
  },
  mounted() {
    this.user_data = this.auth.user;
    frappeAPI.get_all('User Skill', ['skill']).then(({message}) => {
      if (message && message.length) {
        message.map(row => {
          this.skills.list.push(row.skill);
        });
        setTimeout(() => {
          if (this.authData.skills && this.authData.skills.length) {
            this.authData.skills.map(skill => {
              this.skills.selected.push(skill.skill_name);
            })
          }
        });
      }
    });
    setTimeout(() => {
      NProgress.done();
      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });
    }, 500);
  }
}
</script>

<style scoped>

</style>
