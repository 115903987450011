<template>
  <div style="position: relative;width:90px;height:90px;display: inline-block">
    <div class="circle-border"></div>
    <div class="circle">
      <div class="error"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FailMark"
}
</script>

<style lang="scss" scoped>

.circle, .circle-border {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.circle {
  z-index: 1;
  position: relative;
  background: white;
  transform: scale(1);
  animation: success-anim 700ms ease;
}

.circle-border {
  z-index: 0;
  position: absolute;
  transform: scale(1.1);
  animation: circle-anim 400ms ease;
  background: var(--bs-danger);
}

@keyframes success-anim {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes circle-anim {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1.1);
  }
}

.error {
  &::before, &::after {
    content: "";
    display: block;
    height: 4px;
    background: var(--bs-danger);
    position: absolute;
  }

  &::before {
    width: 50px;
    top: 48%;
    left: 20px;
    transform: rotateZ(50deg);
  }

  &::after {
    width: 50px;
    top: 48%;
    left: 20px;
    transform: rotateZ(-50deg);
  }
}


</style>
