<template>
  <div class="tpd-content-layout">
    <div class="tp-dashboard-tab mb-30">
      <h2 class="tp-dashboard-tab-title">{{ lms_website.__('Discuss') }}</h2>
    </div>
    <div class="profile-card-body">
      <div class="discuss-card">
        <div class="discuss-card-list" v-if="!active_course">
          <div class="list-group placeholder-glow" v-if="loading_courses_list">
            <div class="d-flex gap-2">
              <div class="placeholder rounded" style="height:55px;width:60px"></div>
              <div>
                <div class="placeholder rounded" style="height:8px;width:70%"></div>
                <div class="d-flex gap-2 mt-1">
                  <div class="placeholder rounded" style="height:20px;width:100px"></div>
                  <div class="placeholder rounded" style="height:20px;width:100px"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="list-group" v-if="!loading_courses_list">
            <template v-for="course in courses_list" :key="course.name">
              <a @click="join_group(course)" class="list-group-item list-group-item-action pointer-event d-flex justify-content-between align-items-start">
                <div class="me-auto d-flex align-items-center gap-2">
                  <img :src="(course.image)?course.image:'/assets/lms_website/images/placeholder.jpg'" alt=""/>
                  <div>
                    <div class="fw-bold">{{ clip(course.title, 75) }}</div>
                    <div class="text-secondary d-flex gap-3">
                      <div><i class="fal fa-user-graduate mr-1"></i> {{ course.enrollment_count }} {{ lms_website.__('Students') }}</div>
                      <div><i class="fal fa-comment-lines mr-1"></i> {{ course.discussion_count }} {{ lms_website.__('Messages') }}</div>
                    </div>
                  </div>
                </div>
                <span class="discuss-join-icon" :tooltip="lms_website.__('Join Group')"><i class="fal fa-sign-in"></i></span>
              </a>
            </template>
          </div>
        </div>
        <div class="discuss-card-body" v-if="active_course">
          <div class="list-group">
            <div class="list-group-item d-flex justify-content-between align-items-start">
              <div class="me-auto d-flex align-items-center gap-3">
                <img :src="(active_course?.image)?active_course?.image:'/assets/lms_website/images/placeholder.jpg'" alt=""/>
                <div>
                  <div class="fw-bold">{{ clip(active_course?.title, 75) }}</div>
                  <div class="text-secondary d-flex gap-3">
                    <div><i class="fal fa-user-graduate mr-1"></i> {{ active_course?.enrollment_count }} {{ lms_website.__('Students') }}</div>
                    <div><i class="fal fa-comment-lines mr-1"></i> {{ active_course?.discussion_count }} {{ lms_website.__('Messages') }}</div>
                  </div>
                </div>
              </div>
              <!--              <span class="discuss-users-icon" @click="show_members(active_course?.name)" :tooltip="lms_website.__('Group Members')"><i class="far fa-users"></i></span>-->
              <span class="discuss-close-icon" @click="exit_group()" :tooltip="lms_website.__('Close Chat')"><i class="far fa-times"></i></span>
            </div>
          </div>
          <div class="discuss-card-body-chat-list" :class="{'form-loading':loading_chat_list}">
            <template v-for="msg in chat_list" :key="msg.name">
              <div class="message-container animate__animated animate__faster animate__zoomIn" :class="{'sent':(msg.is_my_message),'is-instructor':(courseInstructor===msg.user.name)}" :id="`chat-msg-${msg.name}`">
                <img :src="(msg.user.user_image)?msg.user.user_image:'/assets/lms_website/images/user.png'" v-if="!msg.is_my_message" class="avatar" alt="">
                <div class="message-content">
                  <div class="message-info">
                    <span>
                      <template v-if="msg.is_my_message">{{ lms_website.__('Me') }}</template>
                      <template v-if="!msg.is_my_message">{{ msg.user.full_name }}</template>
                      <small class="text-dark font-w-400 ml-1" v-if="courseInstructor===msg.user.name">(Instructor)</small>
                    </span>
                    <span :tooltip="moment(msg.creation).format('DD MMM, hh:mm a')">{{ timeAgo(msg.creation) }}</span>
                  </div>
                  <div class="break-word" v-html="replaceURLsWithLinks(nl_to_br(sanitizedHtml(msg.message)))"></div>
                  <template v-if="msg.attachment">
                    <div class="message-attachment" v-html="attachment_view(msg.attachment)"></div>
                  </template>
                  <span class="msg-status is-sent" v-if="msg.is_sent"><i class="fal fa-check-double"></i></span>
                  <span class="msg-status not-sent" v-if="!msg.is_sent"><i class="fal fa-clock"></i></span>
                </div>
              </div>
            </template>
          </div>
          <div class="discuss-card-body-form">
            <form method="post" @submit="send_message($event)">
              <div class="chat-attachments" v-if="form.attachment && form.attachment?.name">
                <div><i class="far fa-file mr-1"></i> <span>{{ form.attachment?.name }}</span></div>
              </div>
              <div class="d-flex align-items-center gap-3">
                <textarea rows="2" v-model="form.message" placeholder="Type your message..." required></textarea>
                <div class="form-actions">
                  <button type="button" class="btn btn-attachment" @click="attach_file()" :tooltip="lms_website.__('Attach File')"><i class="fal fa-paperclip-vertical"></i></button>
                  <button type="submit" :disabled="!form.message && !form.attachment" class="btn btn-primary"><i class="far fa-paper-plane"></i></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <teleport to="body">
    <div class="modal fade" id="chatMembersModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">{{ lms_website.__('Group Members') }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body min-height-100" :class="{'form-loading':(members.loading)}">


          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>

import DOMPurify from 'dompurify';
import {authData} from "../../../utils/auth";
import {timeAgo} from "../../../utils/datetime";

export default {
  name: "Discuss",
  setup() {
    return {
      lms_website,
      authData,
      timeAgo,
      moment
    }
  },
  data() {
    return {
      loading_courses_list: true,
      loading_chat_list: true,
      courses_list: [],
      active_course: null,
      chat_list: [],
      members: {
        loading: true,
        list: []
      },
      form: {
        message: '',
        attachment: null
      }
    }
  },
  computed: {
    courseInstructor() {
      if (this.active_course && this.active_course.instructor && this.active_course.instructor.name) {
        return this.active_course.instructor.name
      }
      return '';
    }
  },
  watch: {},
  methods: {
    get_discuss_courses(callback = Function()) {
      this.loading_courses_list = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.user.get_discuss_courses",
        args: {
          user: frappe.session.user
        },
        callback: ({message}) => {
          this.loading_courses_list = false;
          callback(message);
        },
      });
    },
    get_discuss_list(course, callback = Function()) {
      this.loading_chat_list = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.user.get_discuss_list",
        args: {
          course: course
        },
        callback: ({message}) => {
          this.loading_chat_list = false;
          callback(message);
        },
      });
    },
    join_group(course) {
      this.active_course = course;
      console.log('this.active_course', this.active_course)
      this.get_discuss_list(course.name, (list) => {
        this.chat_list = list;
        setTimeout(() => {
          this.chat_scroll();
        }, 1000)
      });
    },
    send_message($event) {
      $event.preventDefault();
      if ($.trim(this.form.message)) {
        let msgId = new Date().getTime();
        let msg_data = {
          course: this.active_course.name,
          message: this.form.message,
          attachment: this.form.attachment.url
        };
        this.chat_list.push({
          name: msgId,
          course: msg_data.name,
          message: msg_data.message,
          attachment: msg_data.attachment,
          user: {
            full_name: authData().user.full_name,
            name: authData().user.name,
            user_image: authData().user.user_image
          },
          owner: frappe.session.user,
          is_my_message: true,
          is_sent: false,
          creation: String(moment()),
        });
        this.form.message = '';
        this.form.attachment = null;
        this.chat_scroll();
        frappe.call({
          type: "POST",
          method: "lms_website.api.utils.create_doc",
          args: {
            doctype: 'LMS Website Discussion',
            frm_data: msg_data,
          },
          callback: ({message}) => {
            let msg = this.chat_list.filter(m => m.name === msgId)[0];
            if (msg) {
              msg.is_sent = true;
            }
            this.active_course.discussion_count += 1;
            this.chat_scroll();
          },
        });
      }
    },
    attachment_view(attachment) {
      let file_name = attachment.replace('/files/', '');
      let file_ext = file_name.split('.').pop();
      if (['jpg', 'jpeg', 'png', 'gif'].includes(file_ext.toLowerCase())) {
        return `<a href="${attachment}" target="_blank" download><img src="${attachment}" alt=""><span class="d-block">${file_name}</span></a>`;
      } else if (['pdf'].includes(file_ext.toLowerCase())) {
        return `<a href="${attachment}" target="_blank" download><i class="fal fa-file-pdf d-flex"></i> <span>${file_name}</span></a>`;
      } else if (['zip'].includes(file_ext.toLowerCase())) {
        return `<a href="${attachment}" target="_blank" download><i class="fal fa-file-archive d-flex"></i> <span>${file_name}</span></a>`;
      } else if (['csv'].includes(file_ext.toLowerCase())) {
        return `<a href="${attachment}" target="_blank" download><i class="fal fa-file-csv d-flex"></i> <span>${file_name}</span></a>`;
      }
      else {
        return `<a href="${attachment}" target="_blank" download><i class="fal fa-file-alt d-flex"></i> <span>${file_name}</span></a>`;
      }
    },
    attach_file() {
      new frappe.ui.FileUploader({
        doctype: 'LMS Website Discussion',
        folder: "Home/Attachments",
        dialog_title: lms_website.__('Attach File'),
        allow_multiple: false,
        restrictions: {
          allowed_file_types: [
            "image/*",
            "text/plain",
            "text/csv",
            "application/pdf",
            "application/zip",
            "application/msword",
            "application/vnd.ms-excel",
          ]
        },
        make_attachments_public: true,
        on_success: (file) => {
          setTimeout(() => {
            this.form.attachment = {
              name: file.file_name,
              size: file.file_size,
              type: file.file_type,
              url: file.file_url,
            }
          }, 100);
        },
      });
    },
    show_members(course_name) {
      $('#chatMembersModal').modal('show');
      this.members.loading = true;
      this.members.list = [];
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_course_members",
        args: {
          course_name: course_name
        },
        callback: ({message}) => {
          this.members.loading = false;
          this.members.list = message;
          console.log('get_course_members', message)
        },
      });
    },
    exit_group() {
      this.active_course = null;
      this.chat_list = [];
    },
    chat_scroll() {
      let chatBox = $('.discuss-card-body-chat-list');
      setTimeout(() => {
        chatBox.scrollTop(chatBox[0].scrollHeight);
        // document.querySelector('.discuss-card-body-chat-list').scrollTop = document.querySelector('.discuss-card-body-chat-list').scrollHeight;
      }, 10);
    },
    clip(str, limit) {
      if (!str) return '';
      str = str.toString();
      if (str.length <= limit) {
        return str;
      }
      return str.substr(0, limit) + '...';
    },
    sanitizedHtml(content) {
      return DOMPurify.sanitize(content, {ALLOWED_TAGS: ['a', 'strong']});
    },
    nl_to_br(text) {
      return text.replace(/\n/g, '<br>');
    },
    replaceURLsWithLinks(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, (url) => {
        // Extract the video ID from the URL if it's a YouTube link
        const youtubeMatch = url.match(/(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        if (youtubeMatch) {
          const videoId = youtubeMatch[1];
          return `
          <div class="video-placeholder" data-video-id="${videoId}">
            <img src="https://img.youtube.com/vi/${videoId}/hqdefault.jpg" alt="Video Thumbnail">
            <a href="${url}" target="_blank" class="chat-play-video"><i class="fab fa-youtube"></i></a>
          </div>
            `;
        } else {
          return `<a href="${url}" target="_blank">${url}</a>`;
        }
      });
    },
  },
  mounted() {
    this.get_discuss_courses((courses) => {
      this.courses_list = courses;
      setTimeout(() => {
        NProgress.done();
      }, 500);
    });
  }
}
</script>

<style lang="scss" scoped>
.profile-card-body {
  padding: 24px;
}
</style>
