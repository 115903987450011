import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52f78e6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-link-container dropdown" }
const _hoisted_2 = {
  key: 0,
  class: "field-link-loading"
}
const _hoisted_3 = ["name", "id", "required"]
const _hoisted_4 = {
  key: 0,
  class: "alert alert-warning text-center text-orange",
  style: {"padding":"4px 8px","margin":"0 8px","font-size":"13px"}
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "d-block" }
const _hoisted_7 = {
  key: 0,
  class: "text-secondary"
}
const _hoisted_8 = {
  key: 1,
  class: "dropdown-item bg-white dropdown-item-filters",
  style: {"white-space":"revert","font-size":"13px","border-top":"1px solid #eee","padding-top":"10px","margin-top":"10px"}
}
const _hoisted_9 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($data.loading_list)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: _normalizeClass(["form-control", $props.cssClass]),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($props.modelValue[$props.field.name]) = $event)),
      onInput: _cache[1] || (_cache[1] = (...args) => ($options.searchList && $options.searchList(...args))),
      onKeydown: _cache[2] || (_cache[2] = (...args) => ($options.searchList && $options.searchList(...args))),
      onFocus: _cache[3] || (_cache[3] = (...args) => ($options.openList && $options.openList(...args))),
      onBlur: _cache[4] || (_cache[4] = (...args) => ($options.closeList && $options.closeList(...args))),
      name: $props.field.name,
      id: `${$props.field.name}`,
      required: $props.field.required,
      autocomplete: "off"
    }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_3), [
      [_vModelText, $props.modelValue[$props.field.name]]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-menu animate__animated animate__zoomIn animate__faster", {'show':($data.show_list && !$data.loading_list)}]),
      style: {"width":"100%"}
    }, [
      (!$data.loading_list && !$data.list.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.lms_website.__('No data found')), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item) => {
        return (_openBlock(), _createElementBlock("button", {
          type: "button",
          class: "dropdown-item",
          onClick: $event => ($options.select_value(item.value))
        }, [
          _createElementVNode("b", _hoisted_6, _toDisplayString(item.value), 1 /* TEXT */),
          (item.description)
            ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(item.description), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_5))
      }), 256 /* UNKEYED_FRAGMENT */)),
      ($props.field.link_filters)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("span", {
              class: "text-secondary",
              innerHTML: $options.get_filter_description($props.field.link_filters)
            }, null, 8 /* PROPS */, _hoisted_9)
          ]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)
  ]))
}