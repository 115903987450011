<template>
  <table class="table table-sm table-borderless table-list table-form">
    <thead>
    <tr>
      <th class="font-s-14" colspan="2">
        <div class="d-flex align-items-center">
          {{ title }}
          <button type="button" @click="add_details_row" class="btn btn-sm btn-outline-dark ms-auto" style="padding: 0 6px;"><i class="far fa-plus mr-1"></i> New</button>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <template v-for="row in data" :key="row.name">
      <tr>
        <!--        <slot :row="row"/>-->
        <td>
          <div class="row">
            <div v-for="field in fields" class="col-lg-6 mb-1">
              <template v-if="field.fieldtype==='Check'">
                <div class="form-check mt-2">
                  <input type="checkbox" class="form-check-input form-check-input-lg" v-model="row[field.fieldname]" :data-fieldname="field.fieldname" :name="`${tableName}_${field.fieldname}`" :id="`${tableName}-${field.fieldname}-${row.name}`" :required="field.reqd" :true-value="1" :false-value="0">
                  <label class="form-check-label form-check-label-lg" :for="`${tableName}-${field.fieldname}-${row.name}`">{{ field.label }} <span v-if="field.reqd" class="text-danger">*</span></label>
                </div>
              </template>
              <label class="control-label" v-if="field.fieldtype!=='Check'" :for="`${tableName}-${field.fieldname}-${row.name}`">{{ field.label }} <span v-if="field.reqd" class="text-danger">*</span></label>
              <template v-if="field.fieldtype==='Data'">
                <input type="text" class="form-control form-control-xs" v-model="row[field.fieldname]" :name="`${tableName}_${field.fieldname}`" :id="`${tableName}-${field.fieldname}-${row.name}`" :required="field.reqd" autocomplete="off">
              </template>
              <template v-if="field.fieldtype==='Date'">
                <input type="text" class="form-control form-control-xs" v-model="row[field.fieldname]" :data-fieldname="field.fieldname" data-toggle="datepicker" :name="`${tableName}_${field.fieldname}`" :id="`${tableName}-${field.fieldname}-${row.name}`" :required="field.reqd" autocomplete="off">
              </template>
              <template v-if="field.fieldtype==='Small Text'">
                <textarea class="form-control form-control-xs" v-model="row[field.fieldname]" rows="4" :name="`${tableName}_${field.fieldname}`" :id="`${tableName}-${field.fieldname}-${row.name}`" :required="field.reqd"></textarea>
              </template>
            </div>
          </div>
        </td>
        <td style="width:100px;">
          <div class="text-right">
            <button type="button" class="btn btn-sm btn-outline-danger" @click="remove_details_row(row.name,data)" :data-row-name="row.name" style="width:40px;padding:2px 10px;" tooltip="Delete"><i class="far fa-times"></i></button>
          </div>
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>

export default {
  name: "DetailsTable",
  props: {
    data: {
      type: [Array],
      default: []
    },
    fields: {
      type: [Array],
      default: {}
    },
    title: {
      type: [String],
      default: ''
    },
    tableName: {
      type: [String],
      default: ''
    }
  },
  setup() {
    return {
      lms_website
    }
  },
  data() {
    return {
      dialog_selected_row: undefined,
      form: {
        type: 'create',
        show: false
      }
    }
  },
  methods: {
    add_details_row() {
      this.form.type = 'create';
      this.form.show = true;
      let field_obj = {};
      if (this.fields && this.fields.length) {
        this.fields.map(field => {
          field_obj['name'] = this.generateUniqueId();
          field_obj[field.fieldname] = '';
        });
        this.data.push(field_obj)
      }
    },
    remove_details_row(name, list) {
      let index = list.findIndex(item => item.name === name);
      this.$swal.fire({
        title: "Do you want to remove row?",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Yes, remove it!"
      }).then(({isConfirmed}) => {
        if (isConfirmed)
          if (index !== -1) {
            list.splice(index, 1);
          }
      });
    },
    generateUniqueId(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let uniqueId = '';
      for (let i = 0; i < length; i++) {
        uniqueId += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return uniqueId;
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
