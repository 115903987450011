<template>
  <main>
    <!-- course filter start -->
    <section class="tp-course-filter-area tp-course-filter-bg p-relative pt-180 pb-220">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="tp-breadcrumb__content-filter mb-50">
              <div class="tp-breadcrumb__list">
                <span>
                   <router-link :to="{ name: 'home' }">
                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path fill-rule="evenodd" clip-rule="evenodd"
                             d="M8.07207 0C8.19331 0 8.31107 0.0404348 8.40664 0.114882L16.1539 6.14233L15.4847 6.98713L14.5385 6.25079V12.8994C14.538 13.1843 14.4243 13.4574 14.2225 13.6589C14.0206 13.8604 13.747 13.9738 13.4616 13.9743H2.69231C2.40688 13.9737 2.13329 13.8603 1.93146 13.6588C1.72962 13.4573 1.61597 13.1843 1.61539 12.8994V6.2459L0.669148 6.98235L0 6.1376L7.7375 0.114882C7.83308 0.0404348 7.95083 0 8.07207 0ZM8.07694 1.22084L2.69231 5.40777V12.8994H13.4616V5.41341L8.07694 1.22084Z"
                             fill="currentColor"/>
                    </svg>
                  </router-link>
                </span>
                <span class="color">{{batch?.title}}</span>
              </div>
              <h3 class="tp-breadcrumb__title">{{batch?.title}}</h3>
              <p>We have the largest collection of <span>{{ count }}</span> courses</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- course filter end -->
    <!-- course filter area start -->
    <section class="tp-filter-mt-2">
      <div class="container">
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="courses-grid" role="tabpanel" aria-labelledby="courses-grid-tab">
            <div class="row">
              <template v-if="!courses.loading && !courses.list.length">
                <div class="col-lg-12 col-md-12 animate__animated animate__zoomIn">
                  <div class="tp-course-item p-relative fix mb-30 d-flex align-items-center justify-content-center" style="height:380px;">
                    <div class="tp-error-wrapper text-center">
                      <h4 class="tp-error-title">Oops!</h4>
                      <div class="tp-error-content">
                        <h4 class="tp-error-title-sm">Not Data Found...</h4>
                        <p>Sorry, we couldn't find any courses like <b>{{ filters.search }}</b>.</p>
                        <button type="button" class="tp-btn-inner" @click="search_value=''">{{ lms_website.__('Show All Courses') }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="!courses.loading">
                <div class="col-lg-4 col-md-6 animate__animated animate__zoomIn" v-for="course in courses.list" :key="course.name">
                  <CourseWidget :course="course"/>
                </div>
              </template>
              <div class="col-lg-4 col-md-6" v-for="number in range(1, 3)" :key="number" v-if="courses.loading">
                <div class="tp-course-item p-relative fix mb-30 placeholder-glow">
                  <div class="tp-course-teacher mb-15">
                    <div class="d-flex align-items-center gap-1">
                      <span class="placeholder d-inline-block rounded-circle" style="width:30px;height:30px;"></span>
                      <span class="placeholder d-inline-block rounded" style="width:80px;height:10px;"></span>
                    </div>
                  </div>
                  <div class="tp-course-thumb">
                    <span class="placeholder d-block rounded" style="height:200px;"></span>
                  </div>
                  <div class="tp-course-content">
                    <div class="d-flex align-items-center gap-1 mb-10">
                      <span class="placeholder d-inline-block rounded" style="width:100px;height:25px;"></span>
                    </div>
                    <div class="tp-course-meta">
                      <div class="d-flex align-items-center gap-1">
                        <span class="placeholder d-inline-block rounded" style="width:80px;height:10px;"></span>
                        <span class="placeholder d-inline-block rounded" style="width:80px;height:10px;"></span>
                      </div>
                    </div>
                    <span class="placeholder d-block rounded mt-4 mb-1" style="width:80%;height:6px;"></span>
                    <span class="placeholder d-block rounded" style="width:30%;height:6px;"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="courses-list" role="tabpanel" aria-labelledby="courses-grid-tab">
            <div class="tp-course-filter-item mb-25 d-flex animate__animated animate__zoomIn" v-for="course in courses.list" :key="course.name">
              <div class="tp-course-filter-thumb">
                <router-link :to="`/courses/view/${course.name}`">
                  <img class="course-sky" :src="(course.image)?course.image:'/assets/lms_website/images/placeholder.jpg'" :alt="course.title"/>
                </router-link>
              </div>
              <div class="tp-course-filter-content">
                <div class="tp-course-filter-tag d-flex align-items-center justify-content-between mb-10">
                  <span class="tag-span">{{ course.tags }}</span>
                  <div class="tp-course-rating-star">
                    <p>{{ course.avg_rating }}<span> /5</span></p>
                    <div class="tp-course-rating-icon">
                      <div class="course-star-rating">
                        <div class="back-stars">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </div>
                        <div class="front-stars" :style="'width:'+avg_rating_width(course.avg_rating)">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 class="tp-course-filter-title">
                  <router-link :to="`/courses/view/${course.name}`">
                    {{ clip(course.title, 75) }}
                  </router-link>
                </h4>
                <div class="tp-course-filter-meta">
                   <span v-if="course.instructor?.name"><img :src="(course.instructor.user_image)?course.instructor.user_image:'/assets/lms_website/images/user.png'" alt=""/>
                        {{ course.instructor?.full_name }}
                    </span>
                  <span>
                   <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M13.9228 10.0426V2.29411C13.9228 1.51825 13.2949 0.953997 12.5252 1.01445H12.4847C11.1276 1.12529 9.07163 1.82055 7.91706 2.53596L7.80567 2.6065C7.62337 2.71733 7.30935 2.71733 7.11692 2.6065L6.9549 2.50573C5.81046 1.79033 3.75452 1.1152 2.3974 1.00437C1.62768 0.943911 0.999756 1.51827 0.999756 2.28405V10.0426C0.999756 10.6573 1.50613 11.2417 2.12393 11.3122L2.30622 11.3425C3.70386 11.5238 5.87126 12.2392 7.10685 12.9143L7.1372 12.9244C7.30937 13.0252 7.59293 13.0252 7.75498 12.9244C8.99057 12.2393 11.1681 11.5339 12.5758 11.3425L12.7885 11.3122C13.4164 11.2417 13.9228 10.6674 13.9228 10.0426Z"
                          stroke="#94928E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M7.46118 2.81787V12.4506" stroke="#94928E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                   </svg>
                   {{ course.lesson_count }} Lessons
                  </span>
                  <span>
                     <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.57134 7.5C8.36239 7.5 9.81432 6.04493 9.81432 4.25C9.81432 2.45507 8.36239 1 6.57134 1C4.7803 1 3.32837 2.45507 3.32837 4.25C3.32837 6.04493 4.7803 7.5 6.57134 7.5Z" stroke="#94928E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M12.1426 14C12.1426 11.4845 9.64553 9.44995 6.57119 9.44995C3.49684 9.44995 0.999756 11.4845 0.999756 14" stroke="#94928E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                     </svg>
                     {{ course.enrollment_count }} Student
                  </span>
                </div>
                <div class="tp-course-filter-p">
                  <p>{{ clip(course.short_introduction, 140) }}</p>
                </div>
                <div class="tp-course-filter-pricing d-flex align-items-center justify-content-between">
                  <span v-if="!course.paid_course">Free</span>
                  <span v-if="course.paid_course">{{ format_currency(course.course_price) }}</span>
                  <div class="tp-course-filter-btn">
                    <router-link :to="`/courses/view/${course.name}`">{{ lms_website.__('Preview this Course') }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- course filter area end -->
  </main>
</template>

<script>
import CourseWidget from "../../components/CourseWidget.vue";

export default {
  name: "BatcheViewPage",
  components: {CourseWidget},
  props: ['id'],
  data() {
    return {
      lms_website: lms_website,
      search_value: '',
      count: 0,
      filters: {
        order_by: '',
        search: '',
        limit: 9,
      },
      batch: {},
      courses: {
        loading: true,
        list: []
      }
    }
  },
  computed: {
    batchId() {
      return this.id;
    },
  },
  watch: {
    filters: {
      handler(filters) {
        setTimeout(() => {
          console.log('filters===', filters)
          this.get_all_courses(filters);
        }, 500);
      },
      deep: true
    },
    search_value: {
      handler(value) {
        if (!$.trim(value)) {
          this.filters.search = '';
        }
      }
    }
  },
  methods: {
    get_all_courses(filters = {}) {
      this.courses.loading = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_batch_courses",
        args: {
          batch_name: this.batchId
        },
        callback: ({message}) => {
          this.courses.loading = false;
          NProgress.done();
          this.courses.list = [];
          this.batch = message.batch;
          this.count = message.count;
          if (message.list && message.list.length) {
            message.list.map(row => {
              if (row.instructors && row.instructors[0]) {
                row['instructor'] = row.instructors[0];
              } else {
                row['instructor'] = {};
              }
              this.courses.list.push(row);
            });
          }
        },
      });
    },
    avg_rating_width(value) {
      if (value) {
        return `${((value / 5) * 100) + 3}%`;
      }
      return '0%';
    },
    clip(str, limit) {
      if (!str) return '';
      str = str.toString();
      if (str.length <= limit) {
        return str;
      }
      return str.substr(0, limit) + '...';
    },
    format_currency(price = 0) {
      return format_currency(price);
    },
    range(start, end) {
      return Array.from({length: end - start + 1}, (_, i) => start + i);
    }
  },
  mounted() {
    setTimeout(() => {
      this.filters.order_by = 'creation desc';
      $('select.wide').niceSelect();
      $('[data-model="filters.sort"]').on('change', (e) => {
        this.filters.order_by = e.target.value;
      })
    }, 500);
  }
}
</script>

<style scoped>

</style>
