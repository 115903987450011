import {useStorage} from "vue3-storage";
import mitt from 'mitt';

const emitter = mitt();

export const authMiddleware = (to, from, next) => {
    const storage = useStorage();
    let UnixAuth = storage.getStorageSync("UnixAuth");
    if (lms_website.user_session !== 'Guest') {
        next();
    } else {
        next({name: 'auth.login', query: {redirect: to.fullPath}});
    }
}
