<template>
  <Body/>
  <Header/>
  <!-- offcanvas area start -->
  <div class="offcanvas__area offcanvas__2">
    <div class="offcanvas__wrapper">
      <div class="offcanvas__close">
        <button class="offcanvas__close-btn offcanvas-close-btn">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 1L1 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 1L11 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <div class="offcanvas__content">
        <div class="offcanvas__top mb-90 d-flex justify-content-between align-items-center">
          <div class="offcanvas__logo tp-header-logo">
            <img src="/assets/lms_website/images/unix_logo-sm.jpg" alt="logo">
          </div>
        </div>
        <div class="offcanvas-main">
          <div class="tp-main-menu-mobile d-xxl-none"></div>
          <div class="row mt-2 mb-4" v-if="!isLoggedIn()">
            <div class="col-12">
              <router-link class="btn btn-dark text-center w-100" :to="{name:'student_applicant'}"><i class="far fa-user-plus mr-1"></i> {{ lms_website.__('Student Applicant') }}</router-link>
            </div>
            <div class="col-12 mt-2">
              <router-link class="tp-btn-inner text-center w-100" :to="{ name: 'auth.login' , query: { redirect: $route.fullPath }}"><i class="far fa-sign-in mr-1"></i> {{ lms_website.__('Log In') }}</router-link>
            </div>
          </div>
          <div class="offcanvas-social">
            <h3 class="offcanvas-title sm">Follow Us</h3>
            <ul>
              <li><a :href="lms_website.settings_list.facebook_link" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
              <li><a :href="lms_website.settings_list.twitter_link" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
              <li><a :href="lms_website.settings_list.linkedin_link" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></li>
              <li><a :href="lms_website.settings_list.youtube_link" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="body-overlay"></div>
  <!-- offcanvas area end -->
  <!-- for example router view -->
  <router-view></router-view>
  <!-- set progressbar -->
  <Footer/>
</template>
<script>
import {getCurrentInstance} from "vue";

import Body from "./components/Body.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

import {isLoggedIn, authData} from './utils/auth';

export default {
  name: "App",
  components: {Header, Body, Footer},
  setup() {
    return {
      lms_website,
      isLoggedIn
    }
  },
  methods: {
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
  },
  mounted() {

    setTimeout(() => {

      $("#loading").fadeOut(500);

      const windowOn = $(window);

      // PreLoader Js
      windowOn.on('load', function () {
        $("#loading").fadeOut(500);
      });

      $(".offcanvas-open-btn").on("click", function () {
        $(".offcanvas__area").addClass("offcanvas-opened");
        $(".body-overlay").addClass("opened");
      });
      $(".offcanvas-close-btn").on("click", function () {
        $(".offcanvas__area").removeClass("offcanvas-opened");
        $(".body-overlay").removeClass("opened");
      });
      $(".body-overlay").on("click", function () {
        $(".offcanvas__area").removeClass("offcanvas-opened");
        $(".body-overlay").removeClass("opened");
      });

      // Sticky Header Js
      windowOn.on('scroll', function () {
        const scroll = $(window).scrollTop();
        if (scroll < 200) {
          $("#header-sticky").removeClass("tp-header-sticky");
        } else {
          $("#header-sticky").addClass("tp-header-sticky");
        }
      });


      function back_to_top() {
        var btn = $('#back_to_top');
        var btn_wrapper = $('.back-to-top-wrapper');

        windowOn.scroll(function () {
          if (windowOn.scrollTop() > 300) {
            btn_wrapper.addClass('back-to-top-btn-show');
          } else {
            btn_wrapper.removeClass('back-to-top-btn-show');
          }
        });

        btn.on('click', function (e) {
          e.preventDefault();
          $('html, body').animate({scrollTop: 0}, '300');
        });
      }

      back_to_top();

      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });

      $("[data-width]").each(function () {
        $(this).css("width", $(this).attr("data-width"));
      });

      $("[data-bg-color]").each(function () {
        $(this).css("background-color", $(this).attr("data-bg-color"));
      });

      $("[data-text-color]").each(function () {
        $(this).css("color", $(this).attr("data-text-color"));
      });

      // Body overlay Js
      $(".body-overlay").on("click", function () {
        $(".offcanvas__area").removeClass("offcanvas-opened");
        $(".tp-search-area").removeClass("opened");
        $(".cartmini__area").removeClass("cartmini-opened");
        $(".body-overlay").removeClass("opened");
      });

    }, 500);
  }
}
</script>
