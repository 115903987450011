<template>
  <main>
    <!-- error area start -->
    <div class="tp-error-area pt-120 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-10">
            <div class="tp-error-wrapper text-center">
              <h4 class="tp-error-title">{{lms_website.__('Oops!')}}</h4>
              <div class="tp-error-thumb mb-50">
                <img src="/assets/lms_website/website-assets/img/error/error.png" style="width:50%" alt="">
              </div>
              <div class="tp-error-content">
                <h4 class="tp-error-title-sm">{{lms_website.__('Something went Wrong...')}}</h4>
                <p>{{lms_website.__("Sorry, we couldn't find your page.")}}</p>
                <router-link class="tp-btn-inner" :to="{name:'home'}">{{lms_website.__('Back to Home')}}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- error area end -->
  </main>
</template>

<script>
export default {
  name: "404",
  setup() {
    return {
      lms_website
    }
  },
  mounted() {
    setTimeout(() => {
      NProgress.done();
    }, 500);
  }
}
</script>

<style scoped>

</style>
