<template>
  <!-- pre loader area start -->
  <div id="loading">
    <div id="loading-center">
      <div id="loading-center-absolute">
        <!-- loading content here -->
        <div class="tp-preloader-content">
          <div class="tp-preloader-logo">
            <div class="tp-preloader-circle">
              <svg width="190" height="190" viewBox="0 0 380 380" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle stroke="#D9D9D9" cx="190" cy="190" r="180" stroke-width="6" stroke-linecap="round">
                </circle>
                <circle stroke="red" cx="190" cy="190" r="180" stroke-width="6" stroke-linecap="round"></circle>
              </svg>
            </div>
            <img src="/assets/lms_website/images/unix_logo-icon-md.jpg" alt="UNIX">
          </div>
          <p class="tp-preloader-subtitle">Loading...</p>
        </div>
      </div>
    </div>
  </div>
  <!-- pre loader area end -->

  <!-- back to top start -->
  <div class="back-to-top-wrapper">
    <button id="back_to_top" type="button" class="back-to-top-btn">
      <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 6L6 1L1 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
  <!-- back to top end -->

  <!-- search area start -->
  <div class="tp-search-area">
    <div class="tp-search-inner p-relative">
      <div class="tp-search-close">
        <button class="tp-search-close-btn">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="path-1" d="M11 1L1 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path class="path-2" d="M1 1L11 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <div class="container">
        <div class="row">
          <div class="tp-search-wrapper">
            <div class="col-lg-9">
              <div class="tp-search-content blue">
                <div class="search p-relative">
                  <input type="search" v-model="search.value" class="search-input" :placeholder="lms_website.__('What are you looking for?')">
                  <button class="tp-search-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M13.3989 13.4001L16.9989 17.0001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M15.3999 8.2001C15.3999 4.22366 12.1764 1.00012 8.19997 1.00012C4.22354 1.00012 1 4.22366 1 8.2001C1 12.1765 4.22354 15.4001 8.19997 15.4001C12.1764 15.4001 15.3999 12.1765 15.3999 8.2001Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
                <div class="tp-search-course-wrap" :class="{'form-loading':(search.loading)}">
                  <h3 class="tp-search-course-title" v-if="!search.loading && search.value.length >= 3">Courses Found ({{ search.list?.length }})</h3>
                  <div class="row">
                    <div class="col-12" v-if="!search.loading && !search.list?.length && search.value.length >= 3">
                      <div class="alert alert-warning mb-3" style="padding:6px 10px;">{{ lms_website.__('No courses found') }}</div>
                    </div>
                    <template v-for="course in search.list" :key="course.name" v-if="!search.loading">
                      <div class="col-xl-3 col-lg-4 col-sm-6">
                        <div class="tp-search-course-item mb-30">
                          <div class="tp-search-course-thumb mb-5">
                            <router-link :to="`/courses/view/${course.name}`">
                              <img :src="(course.image)?course.image:'/assets/lms_website/images/placeholder.jpg'" alt="">
                            </router-link>
                          </div>
                          <div class="tp-search-course-content">
                            <div class="tp-search-course-star">
                              <div class="course-star-rating star-rating-sm">
                                <div class="back-stars">
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                </div>
                                <div class="front-stars" :style="'width:'+avg_rating_width(course.avg_rating)">
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                </div>
                              </div>
                            </div>
                            <h4 class="tp-search-course-item-title">
                              <router-link :to="`/courses/view/${course.name}`">
                                {{ clip(course.title, 55) }}
                              </router-link>
                            </h4>
                            <div class="tp-search-course-price">
                              <span v-if="!course.paid_course">Free</span>
                              <span v-if="course.paid_course">{{ format_currency(course.course_price) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- search area end -->
</template>

<script>

import EventBus from "../utils/bus";

export default {
  name: "Body",
  setup() {
    return {
      lms_website
    }
  },
  data() {
    return {
      search: {
        value: '',
        loading: false,
        list: []
      }
    }
  },
  watch: {
    "search.value": {
      handler(value) {
        if ($.trim(value) && value.length >= 3) {
          this.search.loading = true;
          this.get_search_courses();
        } else {
          this.search.list = [];
        }
      }
    }
  },
  methods: {
    get_search_courses() {
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.search_courses",
        args: {
          search: this.search.value
        },
        callback: ({message}) => {
          this.search.loading = false;
          this.search.list = message;
        },
      });
    },
    avg_rating_width(value) {
      if (value) {
        return `${((value / 5) * 100) + 3}%`;
      }
      return '0%';
    },
    format_currency(price = 0) {
      return format_currency(price);
    },
    clip(str, limit) {
      if (!str) return '';
      str = str.toString();
      if (str.length <= limit) {
        return str;
      }
      return str.substr(0, limit) + '...';
    }
  },
  mounted() {
    EventBus.on('page-changed', () => {
      this.search.value = '';
    });
  }
}
</script>

<style scoped>

</style>
