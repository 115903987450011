<template>
  <!-- dashboard-content-area-start -->
  <div class="tpd-content-layout">

    <!-- dashboard-tab-area -->
    <div class="tp-dashboard-tab mb-30">
      <h2 class="tp-dashboard-tab-title">Reviews</h2>
    </div>
    <!-- dashboard-tab-area-end -->

    <!-- course-area-start -->
    <div class="tpd-table mb-25">
      <ul>
        <li class="tpd-table-head">
          <div class="tpd-table-row">
            <div class="tpd-reviews-course">
              <h4 class="tpd-table-title">Course Title</h4>
            </div>
            <div class="tpd-reviews-feedback-2">
              <h4 class="tpd-table-title">Feedback</h4>
            </div>
            <div class="tpd-reviews-edit"></div>
          </div>
        </li>
        <template v-if="reviews.loading">
          <li>
            <div class="placeholder-glow">
              <div class="d-flex gap-3">
                <span class="placeholder d-block rounded" style="width:100%;height:30px;"></span>
                <span class="placeholder d-block rounded" style="width:100%;height:30px;"></span>
                <span class="placeholder d-block rounded" style="width:100%;height:30px;"></span>
              </div>
            </div>
          </li>
        </template>
        <template v-if="!reviews.list.length && !reviews.loading">
          <li>
            <div class="alert alert-warning">
              <i class="far fa-info-circle mr-1"></i> Not Reviews Found
            </div>
          </li>
        </template>
        <template v-if="reviews.list.length && !reviews.loading">
          <li v-for="review in paginatedReviews" :key="review.name">
            <div class="tpd-table-row">
              <div class="tpd-reviews-course">
                <div class="tpd-course-wrap">
                  <span class="tpd-course-name">{{ clip(review.course.title, 50) }}</span>
                  <div class="tpd-course-title mt-1 font-s-12"><i class="far fa-clock mr-1"></i> {{ moment(review.creation).format("DD, MMMM YYYY - hh:mm a") }}</div>
                </div>
              </div>
              <div class="tpd-reviews-feedback-2">
                <div class="tp-instructor-rating">
                  <div class="course-star-rating star-rating-sm d-inline-block m-0" :tooltip="`${(review.rating)}/5`">
                    <span class="tpd-action-tooltip">Edit</span>
                    <div class="back-stars">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                    <div class="front-stars" :style="'width:'+avg_rating_width(review.rating)">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                  </div>
                  <p class="tpd-reviews-comment">
                    {{ clip(review.review, 100) }}
                  </p>
                </div>
              </div>
              <div class="tpd-reviews-edit">
                <div class="tpd-reviews-edit-warp d-flex align-items-center justify-content-end">
                  <div class="tpd-action-btn">
                    <button type="button" class="edit-btn">
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.74422 2.63127C9.19134 2.14685 9.41489 1.90464 9.65245 1.76336C10.2256 1.42246 10.9315 1.41185 11.5142 1.73539C11.7557 1.86948 11.9862 2.10487 12.447 2.57566C12.9079 3.04644 13.1383 3.28183 13.2696 3.52856C13.5863 4.12387 13.5759 4.84487 13.2422 5.43042C13.1039 5.67309 12.8668 5.90146 12.3926 6.35821L6.75038 11.7926C5.85173 12.6581 5.4024 13.0909 4.84084 13.3102C4.27927 13.5296 3.66192 13.5134 2.42722 13.4811L2.25923 13.4768C1.88334 13.4669 1.6954 13.462 1.58615 13.338C1.4769 13.214 1.49182 13.0226 1.52165 12.6397L1.53785 12.4318C1.6218 11.3541 1.66378 10.8153 1.87422 10.3309C2.08466 9.84657 2.44766 9.45328 3.17366 8.6667L8.74422 2.63127Z"
                            stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
                        <path d="M8.09375 2.69922L12.2938 6.89922" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path>
                        <path d="M8.69531 13.5L13.4953 13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                      <span class="tpd-action-tooltip">Edit</span>
                    </button>
                  </div>
                  <div class="tpd-action-btn ml-10">
                    <button type="button" class="delete-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                        <path d="M12.9154 3.27502L12.4678 9.79134C12.3534 11.4562 12.2963 12.2887 11.8326 12.8871C11.6033 13.183 11.3082 13.4328 10.9659 13.6204C10.2736 14 9.34686 14 7.49346 14C5.63762 14 4.7097 14 4.0169 13.6197C3.67439 13.4317 3.37914 13.1816 3.14997 12.8852C2.68644 12.2857 2.63053 11.4521 2.51869 9.78488L2.08203 3.27502" stroke="currentColor" stroke-width="1.5"
                              stroke-linecap="round"></path>
                        <path d="M14 3.27502H1" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
                        <path d="M10.4282 3.275L9.9352 2.35962C9.60769 1.75157 9.44393 1.44754 9.16146 1.25792C9.0988 1.21586 9.03245 1.17845 8.96307 1.14606C8.65027 1 8.27486 1 7.52404 1C6.75437 1 6.36954 1 6.05154 1.15218C5.98107 1.18591 5.91382 1.22483 5.85048 1.26856C5.56473 1.46586 5.40511 1.78101 5.08588 2.41132L4.64844 3.275" stroke="currentColor" stroke-width="1.5"
                              stroke-linecap="round"></path>
                        <path d="M5.69336 10.425L5.69336 6.52505" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
                        <path d="M9.30469 10.425L9.30469 6.52502" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
                      </svg>
                      <span class="tpd-action-tooltip">Delete</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <!-- course-area-start-end -->

    <Pagination
        v-if="!reviews.loading"
        :current-page="reviews.currentPage"
        :total-items="reviews.list.length"
        :items-per-page="reviews.itemsPerPage"
        @page-changed="handlePageChange"
    />

  </div>
  <!-- dashboard-content-area-end -->
</template>

<script>
import {authData, isLoggedIn} from "../../../utils/auth";
import Pagination from "../../../components/Pagination.vue";

export default {
  name: "Reviews",
  components: {Pagination},
  props: {},
  setup() {
    return {
      isLoggedIn
    }
  },
  data() {
    return {
      lms_website: lms_website,
      authData: authData(),
      moment: moment,
      reviews: {
        loading: true,
        currentPage: 1,
        itemsPerPage: 5,
        list: [],
      }
    }
  },
  computed: {
    auth() {
      if (this.authData && this.authData.is_logged_in && this.authData.user) {
        return this.authData;
      }
    },
    paginatedReviews() {
      const start = (this.reviews.currentPage - 1) * this.reviews.itemsPerPage;
      const end = start + this.reviews.itemsPerPage;
      return this.reviews.list.slice(start, end);
    },
  },
  watch: {},
  methods: {
    get_reviews() {
      this.reviews.loading = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_reviews",
        args: {
          user: frappe.session.user
        },
        callback: ({message}) => {
          NProgress.done();
          this.reviews.loading = false;
          this.reviews.list = message;
        },
      });
    },
    handlePageChange(page) {
      this.reviews.currentPage = page;
    },
    avg_rating_width(value) {
      if (value) {
        return `${((value / 5) * 100) }%`;
      }
      return '0%';
    },
    clip(str, limit) {
      if (!str) return '';
      str = str.toString();
      if (str.length <= limit) {
        return str;
      }
      return str.substr(0, limit) + '...';
    },
  },
  mounted() {
    setTimeout(() => {
      this.get_reviews();
      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });
    }, 500);
  }
}
</script>

<style lang="scss" scoped>
.tpd-reviews-comment {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
</style>
