<template>
  <div id="reviews">
    <h4 class="tp-course-details-2-main-title">Student Feedback</h4>
    <div class="tp-course-details-2-review-rating">
      <div class="row gx-2">
        <div class="col-lg-4">
          <div class="tp-course-details-2-review-rating-info text-center placeholder-glow">
            <template v-if="reviews.is_loading">
              <span class="placeholder d-block rounded" style="width:100%;height:120px;"></span>
            </template>
            <template v-if="!reviews.is_loading">
              <h5>{{ totalRate }}</h5>
              <div class="course-star-rating d-inline-block mt-2">
                <div class="back-stars">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
                <div class="front-stars" :style="'width:'+avg_rating_width(totalRate,0)">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
              <p>Rated {{ totalRate }} out of 5</p>
            </template>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="tp-course-details-2-review-details">
            <div class="tp-course-details-2-review-content placeholder-glow">
              <template v-if="reviews.is_loading">
                <span class="placeholder d-block rounded" style="width:100%;height:10px;margin-bottom:15px;"></span>
                <span class="placeholder d-block rounded" style="width:100%;height:10px;margin-bottom:15px;"></span>
                <span class="placeholder d-block rounded" style="width:100%;height:10px;margin-bottom:15px;"></span>
                <span class="placeholder d-block rounded" style="width:100%;height:10px;margin-bottom:15px;"></span>
                <span class="placeholder d-block rounded" style="width:100%;height:10px;"></span>
              </template>
              <template v-if="!reviews.is_loading">
                <div class="tp-course-details-2-review-item d-flex align-items-center justify-content-between">
                  <div class="tp-course-details-2-review-text"><span>5 star</span></div>
                  <div class="tp-course-details-2-review-progress">
                    <div class="single-progress" :data-width="reviews.percentage['5_star']+'%'"></div>
                  </div>
                  <div class="tp-course-details-2-review-percent">
                    <h5>{{ reviews.percentage['5_star'] }}%</h5>
                  </div>
                </div>
                <div class="tp-course-details-2-review-item d-flex align-items-center justify-content-between">
                  <div class="tp-course-details-2-review-text"><span>4 star</span></div>
                  <div class="tp-course-details-2-review-progress">
                    <div class="single-progress" :data-width="reviews.percentage['4_star']+'%'"></div>
                  </div>
                  <div class="tp-course-details-2-review-percent">
                    <h5>{{ reviews.percentage['4_star'] }}%</h5>
                  </div>
                </div>
                <div class="tp-course-details-2-review-item d-flex align-items-center justify-content-between">
                  <div class="tp-course-details-2-review-text"><span>3 star</span></div>
                  <div class="tp-course-details-2-review-progress">
                    <div class="single-progress" :data-width="reviews.percentage['3_star']+'%'"></div>
                  </div>
                  <div class="tp-course-details-2-review-percent">
                    <h5>{{ reviews.percentage['3_star'] }}%</h5>
                  </div>
                </div>
                <div class="tp-course-details-2-review-item d-flex align-items-center justify-content-between">
                  <div class="tp-course-details-2-review-text"><span>2 star</span></div>
                  <div class="tp-course-details-2-review-progress">
                    <div class="single-progress" :data-width="reviews.percentage['2_star']+'%'"></div>
                  </div>
                  <div class="tp-course-details-2-review-percent">
                    <h5>{{ reviews.percentage['2_star'] }}%</h5>
                  </div>
                </div>
                <div class="tp-course-details-2-review-item d-flex align-items-center justify-content-between">
                  <div class="tp-course-details-2-review-text"><span>1 star</span></div>
                  <div class="tp-course-details-2-review-progress">
                    <div class="single-progress" :data-width="reviews.percentage['1_star']+'%'"></div>
                  </div>
                  <div class="tp-course-details-2-review-percent">
                    <h5>{{ reviews.percentage['1_star'] }}%</h5>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h4 class="tp-course-details-2-main-title">Featured Reviews <small class="text-secondary">({{ reviews.list.length }})</small></h4>
  <div class="tp-course-details-2-review-reply-wrap">
    <template v-if="reviews.is_loading">
      <div class="tp-course-details-2-review-item-reply placeholder-glow">
        <div class="tp-course-details-2-review-top d-flex">
          <div class="tp-course-details-2-review-thumb">
            <span class="placeholder d-inline-block rounded-circle" style="width:50px;height:50px;"></span>
          </div>
          <div class="tp-course-details-2-review-content">
            <span class="placeholder d-block rounded" style="width:80px;height:18px; margin: 8px 0 6px 0;"></span>
            <span class="placeholder d-block rounded" style="width:50px;height:8px;"></span>
          </div>
        </div>
        <span class="placeholder d-block rounded" style="width:100%;height:10px; margin-bottom:12px;"></span>
        <span class="placeholder d-block rounded" style="width:35%;height:10px;"></span>
      </div>
    </template>
    <template v-for="review in visibleReviews" :key="review.name">
      <div class="tp-course-details-2-review-item-reply placeholder-glow animate__animated animate__zoomIn" :id="`review-item-${review.name}`">
        <div class="tp-course-details-2-review-top d-flex">
          <div class="tp-course-details-2-review-thumb">
            <img :src="(review.user_data?.user_image)?review.user_data?.user_image:'/assets/lms_website/images/user.png'" alt="">
          </div>
          <div class="tp-course-details-2-review-content"><h4>{{ review.user_data?.full_name }}</h4>
            <div class="tp-course-details-2-review-star d-flex align-items-center gap-1">
              <div class="course-star-rating star-rating-sm d-inline-block">
                <div class="back-stars">
                  <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                </div>
                <div class="front-stars" :style="`width: ${avg_rating_width((review.rating*5))};`">
                  <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                </div>
              </div>
              <span class="span" :tooltip="moment(review.creation).format('DD MMM, YYYY - hh:mm a')"><i class="fal fa-clock mr-1"></i> {{ timeAgo(review.creation) }}</span></div>
          </div>
        </div>
        <p>{{ review.review }}</p>
      </div>
    </template>
    <template v-if="!visibleReviews.length && !reviews.is_loading">
      <div class="d-flex align-items-center gap-2">
        <img class="icon icon-xl" src="/assets/lms/icons/comment.svg" alt="">
        <div class="empty-state-text">
          <h5 class="m-0">Review the course</h5>
          <div class="text-secondary">Help us improve our course material.</div>
        </div>
      </div>
    </template>

    <div class="tp-course-details-2-review-reply-btn pointer-event" @click="loadMore()" v-if="visibleReviews.length < reviews.list.length">
      <a>Show More Reviews ({{ (reviews.list.length - visibleReviews.length) }})</a>
    </div>
  </div>
  <template v-if="courseEnrolled">
    <h4 class="tp-course-details-2-main-title">Write a Review</h4>
    <template v-if="!rating?.is_reviewed">
      <div class="tp-course-details-2-comment-box pr-25">
        <span>What is it like to Course?</span>
        <form id="contact-form" :class="{'form-loading':(rating.form_lading)}" method="post" @submit="submit_review($event)">
          <div class="tp-course-details-2-comment-star">
            <div class="star-rating d-inline-block mt-1 mb-2" style="direction:rtl;">
              <i
                  v-for="star in rating.stars"
                  :key="star"
                  :class="['fa-star', rating.value >= star || rating.hoverRating >= star ? 'fas' : 'far']"
                  @click="setRating(star)"
                  @mouseover="setHoverRating(star)"
                  @mouseleave="resetHoverRating"
                  class="fa"
              ></i>
            </div>
          </div>
          <div class="tp-contact-input-form">
            <div class="row">
              <div class="col-xl-12">
                <div class="tp-contact-input p-relative">
                  <label>Review Content</label>
                  <textarea name="rating_content" v-model="rating.content" :class="{'is-invalid':(rating.errors.content)}" required></textarea>
                  <div class="invalid-feedback" v-if="rating.errors.content">
                    <i class="far fa-info-circle mr-1"></i> {{ rating.errors.content }}
                  </div>
                </div>
              </div>
              <div class="tp-contact-btn">
                <button type="submit" class="tp-btn-inner" :disabled="!rating.value || !rating.content">
                  <span class="spinner-border spinner-border-sm mr-1" aria-hidden="true" v-if="rating.form_lading"></span> Submit Review
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
    <template v-if="rating?.is_reviewed">
      <div class="alert alert-info">
        <i class="far fa-info-circle mr-1"></i> {{ lms_website.__('You have already written a review.') }}
      </div>
    </template>
  </template>
</template>

<script>

import {timeAgo} from "../../../utils/datetime";
import EventBus from "../../../utils/bus";

export default {
  name: "CourseReview",
  props: {
    courseName: {
      type: [String],
      default: ''
    },
    courseEnrolled: {
      type: [Boolean],
      default: false
    },
    isReviewed: {
      type: [String],
      default: ''
    }
  },
  setup() {
    return {
      timeAgo,
      moment,
    }
  },
  data() {
    return {
      lms_website: lms_website,
      rating: {
        is_reviewed: null,
        courseName: undefined,
        value: 0,
        content: '',
        hoverRating: 0,
        stars: [5, 4, 3, 2, 1],
        errors: {},
        form_lading: false
      },
      reviews: {
        is_loading: true,
        itemsToShow: 5,
        list: [],
        counts: {},
        percentage: {},
      }
    }
  },
  computed: {
    totalRate() {
      let total_rate = 0;
      if (this.reviews.list && this.reviews.list.length) {
        this.reviews.list.map(review => {
          if (review.rating > 0)
            total_rate += ((review.rating * 5) / (this.reviews.list.length * 5)) * 5;
        });
      }
      return parseFloat(total_rate).toFixed(1);
    },
    visibleReviews() {
      return this.reviews.list.slice(0, this.reviews.itemsToShow);
    }
  },
  watch: {},
  methods: {
    get_course_reviews(callback = Function()) {
      this.reviews.is_loading = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_course_reviews",
        args: {
          course_name: this.courseName
        },
        callback: ({message}) => {
          this.reviews.is_loading = false;
          this.reviews.list = message;
          EventBus.emit('update-course-reviews-count',this.reviews.list.length)
          let count_of_1_star = this.reviews.list.filter(review => (review.rating > 0 && review.rating <= 0.2)).length;
          let count_of_2_star = this.reviews.list.filter(review => (review.rating > 0.2 && review.rating <= 0.5)).length;
          let count_of_3_star = this.reviews.list.filter(review => (review.rating > 0.5 && review.rating <= 0.6)).length;
          let count_of_4_star = this.reviews.list.filter(review => (review.rating > 0.6 && review.rating <= 0.8)).length;
          let count_of_5_star = this.reviews.list.filter(review => (review.rating > 0.8 && review.rating === 1)).length;
          this.reviews.counts = {
            '1_star': count_of_1_star,
            '2_star': count_of_2_star,
            '3_star': count_of_3_star,
            '4_star': count_of_4_star,
            '5_star': count_of_5_star
          }
          let total_stars = (count_of_1_star + count_of_2_star + count_of_3_star + count_of_4_star + count_of_5_star)
          this.reviews.percentage = {
            '1_star': parseFloat(((count_of_1_star) / (total_stars)) * 100).toFixed(0),
            '2_star': parseFloat(((count_of_2_star) / (total_stars)) * 100).toFixed(0),
            '3_star': parseFloat(((count_of_3_star) / (total_stars)) * 100).toFixed(0),
            '4_star': parseFloat(((count_of_4_star) / (total_stars)) * 100).toFixed(0),
            '5_star': parseFloat(((count_of_5_star) / (total_stars)) * 100).toFixed(0)
          }
          console.log('this.reviews', this.reviews)
          setTimeout(() => {
            $("[data-width]").each(function () {
              $(this).css("width", $(this).attr("data-width"));
            });
          }, 500);
          callback();
        }
      });
    },
    submit_review($event) {
      $event.preventDefault();
      if (!$.trim(this.rating.content)) {
        this.rating.errors.content = lms_website.__('Review content must not be empty.');
      } else if ($.trim(this.rating.content).length < 6) {
        this.rating.errors.content = lms_website.__('Length must greater than 6 chars.');
      } else {
        this.rating.errors.content = null;
        const data = {
          course_name: this.rating.courseName,
          value: (this.rating.value / 5),
          content: this.rating.content
        }
        this.rating.form_lading = true;
        frappe.call({
          type: "POST",
          method: "lms_website.api.courses.add_course_reviews",
          args: data,
          callback: ({message}) => {
            this.get_course_reviews(() => {
              setTimeout(() => {
                this.rating.form_lading = false;
                this.rating.value = 0;
                this.rating.content = '';
                this.rating.is_reviewed = message.name;
                this.$toast.success(lms_website.__("You review has been submitted"));
                $('html, body').animate({
                  scrollTop: $(`#review-item-${message.name}`).offset().top - 250
                }, 500);
              }, 100);
            });
          },
          always: (response) => {
            this.rating.form_lading = false;
            if (response.exception && !response._server_messages) {
              frappe.msgprint({
                title: lms_website.__("Error"),
                message: lms_website.__(response.exception),
                indicator: "red",
              });
            }
          }
        });
      }
    },
    loadMore() {
      this.reviews.itemsToShow += 5;
    },
    avg_rating_width(value, plus = 0) {
      if (value > 0) {
        return `${((value / 5) * 100) + plus}%`;
      }
      return '0%';
    },
    setRating(star) {
      this.rating.value = star;
    },
    setHoverRating(star) {
      this.rating.hoverRating = star;
    },
    resetHoverRating() {
      this.rating.hoverRating = 0;
    }
  },
  mounted() {
    this.rating.is_reviewed = this.isReviewed;
    setTimeout(() => {
      this.rating.courseName = this.courseName;
      this.get_course_reviews();
    }, 1000);
  }
}
</script>

<style lang="scss" scoped>
.star-rating {
  .fa {
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 5px;
    color: lightgray;
    transition: color 0.2s ease-in-out;

    &.fas {
      color: #FFB21D;
    }
  }
}

.star-rating .fa:hover,
.star-rating .fa:hover ~ .fa {
  color: #FFB21D;
}
</style>
<script setup>
</script>
