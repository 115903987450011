<template>
  <!-- dashboard-content-area-start -->
  <div class="tpd-content-layout">

    <!-- dashboard-tab-area -->
    <div class="dashboader-area mb-25">
      <div class="tp-dashboard-tab">
        <h2 class="tp-dashboard-tab-title">My Courses</h2>
        <div class="tp-dashboard-tab-list">
          <ul>
            <li>
              <a class="pointer-event" @click="courses.active_tab='in_progress'" :class="(courses.active_tab==='in_progress')?'active':''">Enrolled Courses <span>({{ courses.in_progress.length }})</span></a>
            </li>
            <li>
              <a class="pointer-event" @click="courses.active_tab='completed'" :class="(courses.active_tab==='completed')?'active':''">Completed <span>({{ courses.completed.length }})</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- dashboard-tab-area-end -->

    <!-- course-area-start -->
    <div class="course-area">
      <div class="row">
        <template v-if="activeCourses.length">
          <div v-for="course in activeCourses" :key="course.name" class="col-xl-4 col-md-6">
            <div class="tp-dashboard-course mb-25">
              <div class="tp-dashboard-course-thumb">
                <router-link :to="`/courses/view/${course.name}`">
                  <img :src="(course.image)?course.image:'/assets/lms_website/images/placeholder.jpg'" alt="">
                </router-link>
              </div>
              <div class="tp-dashboard-course-content">
                <div class="tp-dashboard-rating d-flex align-items-center gap-2">
                  <div class="course-star-rating star-rating-sm d-inline-block m-0">
                    <div class="back-stars">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                    <div class="front-stars" :style="'width:'+avg_rating_width(course.avg_rating)">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                  </div>
                  <span>({{ course.reviews_count }} Reviews)</span>
                </div>
                <h4 class="tp-dashboard-course-title" style="font-size:18px;">
                  <router-link :to="`/courses/view/${course.name}`"> {{ clip(course.title, 60) }}</router-link>
                </h4>
                <div class="tp-dashboard-course-meta">
                <span>
                  <span>
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.9228 10.0426V2.29411C13.9228 1.51825 13.2949 0.953997 12.5252 1.01445H12.4847C11.1276 1.12529 9.07163 1.82055 7.91706 2.53596L7.80567 2.6065C7.62337 2.71733 7.30935 2.71733 7.11692 2.6065L6.9549 2.50573C5.81046 1.79033 3.75452 1.1152 2.3974 1.00437C1.62768 0.943911 0.999756 1.51827 0.999756 2.28405V10.0426C0.999756 10.6573 1.50613 11.2417 2.12393 11.3122L2.30622 11.3425C3.70386 11.5238 5.87126 12.2392 7.10685 12.9143L7.1372 12.9244C7.30937 13.0252 7.59293 13.0252 7.75498 12.9244C8.99057 12.2393 11.1681 11.5339 12.5758 11.3425L12.7885 11.3122C13.4164 11.2417 13.9228 10.6674 13.9228 10.0426Z"
                        stroke="#94928E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M7.46118 2.81787V12.4506" stroke="#94928E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </span>
                 {{ course.lesson_count }} Lessons
                </span>
                  <span>
                  <span>
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.57134 7.5C8.36239 7.5 9.81432 6.04493 9.81432 4.25C9.81432 2.45507 8.36239 1 6.57134 1C4.7803 1 3.32837 2.45507 3.32837 4.25C3.32837 6.04493 4.7803 7.5 6.57134 7.5Z" stroke="#94928E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M12.1426 14C12.1426 11.4845 9.64553 9.44995 6.57119 9.44995C3.49684 9.44995 0.999756 11.4845 0.999756 14" stroke="#94928E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </span>
                  {{ course.enrollment_count }} Student
                </span>
                </div>
                <div class="tp-dashboard-progress hidden">
                  <div class="tp-dashboard-progress-info d-flex align-items-center justify-content-between">
                    <span>3/4</span>
                    <span>85% Complete</span>
                  </div>
                  <div class="progress" role="progressbar" aria-label="Example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar wow fadeInLeft" data-wow-duration=".9s" data-wow-delay=".3s" data-width=" 75%"></div>
                  </div>
                </div>
                <div class="tp-dashboard-course-btn">
                  <router-link class="tpd-btn-border w-100" :to="`/courses/view/${course.name}`">View Course</router-link>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="courses.loading">
          <div class="placeholder-glow col-xl-4 col-md-6">
            <span class="placeholder rounded" style="width:100%;height:420px;"></span>
          </div>
          <div class="placeholder-glow col-xl-4 col-md-6">
            <span class="placeholder rounded" style="width:100%;height:420px;"></span>
          </div>
          <div class="placeholder-glow col-xl-4 col-md-6">
            <span class="placeholder rounded" style="width:100%;height:420px;"></span>
          </div>
        </template>
        <template v-if="!activeCourses.length && !courses.loading">
          <div class="col-12">
            <div class="alert alert-warning">
              <i class="far fa-info-circle mr-1"></i> Not Courses Found
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- course-area-start-end -->

  </div>
  <!-- dashboard-content-area-end -->
</template>

<script>
import {authData, isLoggedIn} from "../../../utils/auth";

export default {
  name: "EnrolledCourses",
  props: {},
  setup() {
    return {
      isLoggedIn
    }
  },
  data() {
    return {
      lms_website: lms_website,
      authData: authData(),
      moment: moment,
      courses: {
        loading: true,
        active_tab: 'in_progress',
        in_progress: [],
        completed: [],
      }
    }
  },
  computed: {
    auth() {
      if (this.authData && this.authData.is_logged_in && this.authData.user) {
        return this.authData;
      }
    },
    activeCourses() {
      return this.courses[this.courses.active_tab] || [];
    }
  },
  watch: {},
  methods: {
    get_enrolled_courses() {
      this.courses.loading = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_enrolled_courses",
        args: {},
        callback: ({message}) => {
          NProgress.done();
          this.courses.loading = false;
          this.courses.completed = message.completed;
          this.courses.in_progress = message.in_progress;
        },
      });
    },
    avg_rating_width(value) {
      if (value) {
        return `${((value / 5) * 100) + 3}%`;
      }
      return '0%';
    },
    clip(str, limit) {
      if (!str) return '';
      str = str.toString();
      if (str.length <= limit) {
        return str;
      }
      return str.substr(0, limit) + '...';
    },
    format_currency(price = 0) {
      return format_currency(price);
    },
  },
  mounted() {
    setTimeout(() => {
      this.get_enrolled_courses();
      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });
    }, 500);
  }
}
</script>

<style scoped>

</style>
