<template>
  <nav aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      <!-- Previous Button -->
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(currentPage - 1)">
          <i class="far fa-arrow-left"></i>
        </a>
      </li>

      <!-- First Page Button (Always show) -->
      <li class="page-item" :class="{ active: currentPage === 1 }">
        <a class="page-link" href="#" @click.prevent="changePage(1)">1</a>
      </li>

      <!-- Ellipses before the current page group (if needed) -->
      <li class="page-item" v-if="showLeftEllipsis">
        <span class="page-link"><i class="far fa-ellipsis-h"></i></span>
      </li>

      <!-- Page Numbers around the current page -->
      <li
          class="page-item"
          v-for="page in visiblePages"
          :key="page"
          :class="{ active: currentPage === page }"
      >
        <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
      </li>

      <!-- Ellipses after the current page group (if needed) -->
      <li class="page-item" v-if="showRightEllipsis">
        <span class="page-link"><i class="far fa-ellipsis-h"></i></span>
      </li>

      <!-- Last Page Button (Always show) -->
      <li class="page-item" v-if="totalPages > 1" :class="{ active: currentPage === totalPages }">
        <a class="page-link" href="#" @click.prevent="changePage(totalPages)">{{ totalPages }}</a>
      </li>

      <!-- Next Button -->
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(currentPage + 1)">
          <i class="far fa-arrow-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    maxVisiblePages: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    visiblePages() {
      const pages = [];

      const half = Math.floor(this.maxVisiblePages / 2);
      let start = Math.max(2, this.currentPage - half); // Don't include the first page in the range
      let end = Math.min(this.totalPages - 1, this.currentPage + half); // Don't include the last page in the range

      // Adjust start and end if they exceed the limits
      if (this.currentPage <= half) {
        end = Math.min(this.totalPages - 1, this.maxVisiblePages); // Adjust for the start being too close to 1
      }
      if (this.currentPage >= this.totalPages - half) {
        start = Math.max(2, this.totalPages - this.maxVisiblePages + 1); // Adjust for the end being too close to totalPages
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      return pages;
    },
    showLeftEllipsis() {
      return this.currentPage > Math.floor(this.maxVisiblePages / 2) + 2; // Show ellipsis if we're past the first few pages
    },
    showRightEllipsis() {
      return this.currentPage < this.totalPages - Math.floor(this.maxVisiblePages / 2) - 1; // Show ellipsis if we're before the last few pages
    },
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit('page-changed', page);
      }
    },
  },
};
</script>
