<template>
  <!-- dashboard-content-area-start -->
  <div class="tpd-content-layout">

    <!-- dashboard-tab-area -->
    <div class="tp-dashboard-tab mb-30">
      <h2 class="tp-dashboard-tab-title">Order History </h2>
    </div>
    <!-- dashboard-tab-area-end -->

    <div class="tpd-table mb-25">
      <ul>
        <li class="tpd-table-head">
          <div class="tpd-table-row">
            <div class="tpd-order-id">
              <h4 class="tpd-table-title">Order ID</h4>
            </div>
            <div class="tpd-order-name">
              <h4 class="tpd-table-title">Course Name</h4>
            </div>
            <div class="tpd-order-date">
              <h4 class="tpd-table-title">Date</h4>
            </div>
            <div class="tpd-order-price">
              <h4 class="tpd-table-title">Price</h4>
            </div>
            <div class="tpd-order-status">
              <h4 class="tpd-table-title">Status</h4>
            </div>
            <div class="tpd-order-action"></div>
          </div>
        </li>
        <template v-if="payments.loading">
          <li>
            <div class="placeholder-glow">
              <div class="d-flex gap-3">
                <span class="placeholder d-block rounded" style="width:100%;height:30px;"></span>
                <span class="placeholder d-block rounded" style="width:100%;height:30px;"></span>
                <span class="placeholder d-block rounded" style="width:100%;height:30px;"></span>
                <span class="placeholder d-block rounded" style="width:100%;height:30px;"></span>
                <span class="placeholder d-block rounded" style="width:100%;height:30px;"></span>
              </div>
            </div>
          </li>
        </template>
        <template v-if="!payments.list.length && !payments.loading">
          <li>
            <div class="alert alert-warning">
              <i class="far fa-info-circle mr-1"></i> Not Payments Found
            </div>
          </li>
        </template>
        <template v-if="payments.list.length && !payments.loading">
          <li v-for="payment in paginatedPayments" :key="payment.name">
            <div class="tpd-table-row">
              <div class="tpd-order-id">
                <h4 class="tpd-common-text">#{{ payment.name }}</h4>
              </div>
              <div class="tpd-order-name">
                <h4 class="tpd-common-text">
                  <router-link :to="`/courses/view/${payment.payment_for_document}`">
                    {{ payment.course_title }}
                  </router-link>
                </h4>
              </div>
              <div class="tpd-order-date">
                <h4 class="tpd-common-text">{{ moment(payment.creation).format('MMMM DD, YYYY') }}</h4>
              </div>
              <div class="tpd-order-price">
                <h4 class="tpd-common-text">{{ format_currency(payment.amount, payment.currency) }}</h4>
              </div>
              <div class="tpd-order-status">
                <div class="tpd-badge-item">
                  <span class="tpd-badge warning" v-if="!payment.payment_received">On Hold</span>
                  <span class="tpd-badge sucess" v-if="payment.payment_received">Success</span>
                </div>
              </div>
              <div class="tpd-order-action">
                <div class="tpd-action-btn">
                  <a :href="`/api/method/frappe.utils.print_format.download_pdf?doctype=LMS%20Payment&name=${payment.name}&format=Standard&no_letterhead=1&letterhead=No%20Letterhead&settings=%7B%7D&_lang=en`" :download="payment.name">
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 8.23975V10.7466C13 11.079 12.8595 11.3979 12.6095 11.6329C12.3594 11.868 12.0203 12.0001 11.6667 12.0001H2.33333C1.97971 12.0001 1.64057 11.868 1.39052 11.6329C1.14048 11.3979 1 11.079 1 10.7466V8.23975" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M10.3327 5.38704L6.99935 8.52063L3.66602 5.38704" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M7 1V8.52061" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <span class="tpd-action-tooltip">Download</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <Pagination
        v-if="!payments.loading"
        :current-page="payments.currentPage"
        :total-items="payments.list.length"
        :items-per-page="payments.itemsPerPage"
        @page-changed="handlePageChange"
    />
  </div>
</template>

<script>
import {authData, isLoggedIn} from "../../../utils/auth";
import Pagination from "../../../components/Pagination.vue";

export default {
  name: "OrdersHistory",
  components: {Pagination},
  props: {},
  setup() {
    return {
      isLoggedIn
    }
  },
  data() {
    return {
      lms_website: lms_website,
      authData: authData(),
      moment: moment,
      payments: {
        loading: true,
        currentPage: 1,
        itemsPerPage: 5,
        list: [],
      }
    }
  },
  computed: {
    auth() {
      if (this.authData && this.authData.is_logged_in && this.authData.user) {
        return this.authData;
      }
    },
    paginatedPayments() {
      const start = (this.payments.currentPage - 1) * this.payments.itemsPerPage;
      const end = start + this.payments.itemsPerPage;
      return this.payments.list.slice(start, end);
    },
  },
  watch: {},
  methods: {
    get_user_payments() {
      this.payments.loading = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_user_payments",
        args: {},
        callback: ({message}) => {
          this.payments.loading = false;
          this.payments.list = message;
        },
      });
    },
    format_currency(price = 0, currency = '') {
      return format_currency(price, currency);
    },
    handlePageChange(page) {
      this.payments.currentPage = page;
    },
  },
  mounted() {
    setTimeout(() => {
      NProgress.done();
      this.get_user_payments();
      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });
    }, 500);
  }
}
</script>

<style scoped>

</style>
