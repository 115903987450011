<template>
  <main>
    <section class="tp-breadcrumb__area pt-60 pb-60 p-relative z-index-1 fix">
      <div class="tp-breadcrumb__bg" data-background="/assets/lms_website/website-assets/img/breadcrumb/breadcrumb-bg-2.jpg"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12">
            <div class="tp-breadcrumb__content">
              <div class="tp-breadcrumb__list">
                <span>
                 <router-link :to="{name:'home'}">
                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.07207 0C8.19331 0 8.31107 0.0404348 8.40664 0.114882L16.1539 6.14233L15.4847 6.98713L14.5385 6.25079V12.8994C14.538 13.1843 14.4243 13.4574 14.2225 13.6589C14.0206 13.8604 13.747 13.9738 13.4616 13.9743H2.69231C2.40688 13.9737 2.13329 13.8603 1.93146 13.6588C1.72962 13.4573 1.61597 13.1843 1.61539 12.8994V6.2459L0.669148 6.98235L0 6.1376L7.7375 0.114882C7.83308 0.0404348 7.95083 0 8.07207 0ZM8.07694 1.22084L2.69231 5.40777V12.8994H13.4616V5.41341L8.07694 1.22084Z"
                            fill="currentColor"></path>
                    </svg>
                 </router-link>
                </span>
                <span class="color">{{ lms_website.__('Student Applicant') }}</span>
              </div>
              <h3 class="tp-breadcrumb__title white">{{ lms_website.__('Student Applicant') }}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container py-5">
      <div class="row">
        <div class="col-xxl-8 col-xl-9 offset-xxl-2 offset-xl-1">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="text-center" v-if="form.is_submitted">
                <CheckMark/>
                <h3 class="text-success mt-3">{{ lms_website.__('Thank You For Submission') }}</h3>
                <p class="text-secondary">{{ lms_website.__('We will review your application and send email if confirmed') }}</p>
              </div>
              <form method="post" v-if="!form.is_submitted" :class="{'form-loading':(form.loading)}" @submit="submitApplicant($event)">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <label for="custom_name_in_english" class="form-label"><span>Name in English - الاسم بالانجليزي</span> <span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" v-model="form.data.custom_name_in_english" id="custom_name_in_english" placeholder="" required>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="first_name" class="form-label"><span>Name in Arabic - الاسم بالعربي </span> <span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" v-model="form.data.first_name" id="first_name" style="direction:rtl;" placeholder="" required>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <label for="student_email_id" class="form-label"><span>Student Email Address - البريد الالكتروني </span> <span class="text-danger">*</span></label>
                    <div class="form-control-has-icon">
                      <i class="fal fa-envelope form-control-icon"></i>
                      <input type="email" class="form-control form-control-sm" v-model="form.data.student_email_id" id="student_email_id" placeholder="name@example.com" required>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="student_mobile_number" class="form-label"><span>Student Mobile Number - رقم الهاتف </span> <span class="text-danger">*</span></label>
                    <div class="form-control-has-icon">
                      <i class="fal fa-phone-alt form-control-icon"></i>
                      <input type="text" class="form-control form-control-sm" v-model="form.data.student_mobile_number" id="student_mobile_number" placeholder="" required>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <label for="custom_student_enrollment_code" class="form-label"><span>Student Enrollment Code - كود الالتحاق </span> <span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" v-model="form.data.custom_student_enrollment_code" id="custom_student_enrollment_code" placeholder="" required>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="custom_national_id" class="form-label"><span>National ID - الرقم القومي </span></label>
                    <input type="text" class="form-control form-control-sm" v-model="form.data.custom_national_id" id="custom_national_id" placeholder="">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <label for="custom_branch" class="form-label"><span>Branch - الفرع </span> <span class="text-danger">*</span></label>
                    <FieldLink
                        :model-value="form.data"
                        :field="form.link_fields.custom_branch"
                        reference-doctype="Student Applicant"
                        css-class="form-control-sm"
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="program" class="form-label"><span>Program - البرنامج </span> <span class="text-danger">*</span></label>
                    <FieldLink
                        :model-value="form.data"
                        :field="form.link_fields.program"
                        reference-doctype="Student Applicant"
                        css-class="form-control-sm"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 mb-3">
                    <label for="custom_branch_group_timetable" class="form-label"><span>Branch Group Timetable - اختيار الموعد  </span> <span class="text-danger">*</span></label>
                    <FieldLink
                        :model-value="form.data"
                        :field="form.link_fields.custom_branch_group_timetable"
                        reference-doctype="Student Applicant"
                        css-class="form-control-sm"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <table class="table table-bordered table-sm bg-white" style="table-layout:fixed">
                      <thead>
                      <tr>
                        <th class="text-center">{{ lms_website.__('Day') }}</th>
                        <th class="text-center">{{ lms_website.__('Form') }}</th>
                        <th class="text-center">{{ lms_website.__('To') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="!form.data?.custom_branch_group_timetable_days?.length">
                        <td colspan="3" class="text-center">{{ lms_website.__('No Time Table Days') }}</td>
                      </tr>
                      <tr v-for="row in form.data?.custom_branch_group_timetable_days">
                        <td class="text-primary text-center">{{ row.day }}</td>
                        <td class="text-primary text-center">{{ row.from_time }}</td>
                        <td class="text-primary text-center">{{ row.to_time }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary" :disabled="form.loading">{{ lms_website.__('Submit Applicant') }}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import FieldLink from "../../components/forms/fields/FieldLink.vue";
import CheckMark from "../../components/shared/CheckMark.vue";

export default {
  name: "StudentApplicant",
  components: {CheckMark, FieldLink},
  setup() {
    return {
      lms_website
    }
  },
  data() {
    return {
      form: {
        loading: false,
        is_submitted: false,
        data: {
          application_date: moment().format('YYYY-MM-DD'),
          academic_year: moment().format('YYYY'),
          application_status: 'Applied',
          doctype: 'Student Applicant'
        },
        link_fields: {
          custom_branch: {
            name: 'custom_branch',
            doctype: 'Cost Center',
            required: true
          },
          program: {
            name: 'program',
            doctype: 'Program',
            required: true
          },
          custom_branch_group_timetable: {
            name: 'custom_branch_group_timetable',
            doctype: 'Branch Group Timetable',
            link_filters: {"branch": "", "is_active": 1},
            required: true
          }
        }
      }
    }
  },
  computed: {},
  watch: {
    'form.data.custom_branch': function (custom_branch) {
      if (this.form.link_fields.custom_branch) {
        this.form.link_fields.custom_branch_group_timetable.link_filters['branch'] = custom_branch;
      }
    },
    'form.data.custom_branch_group_timetable': function (branch_group_timetable) {
      if (branch_group_timetable) {
        frappe.call({
          type: 'POST',
          method: "lms_website.api.client.get",
          args: {
            doctype: 'Branch Group Timetable',
            name: branch_group_timetable
          },
          callback: ({message}) => {
            setTimeout(() => {
              this.form.data.custom_timetable_group_number = message.group_number;
              this.form.data.custom_branch_group_timetable_days = message.branch_group_timetable_days;
            }, 10);
          }
        });
      }
    }
  },
  methods: {
    submitApplicant($event) {
      $event.preventDefault();
      this.form.loading = true;
      frappe.call({
        type: 'POST',
        method: "lms_website.api.utils.create_doc",
        args: {
          doctype: this.form.data.doctype,
          frm_data: this.form.data
        },
        callback: ({message}) => {
          this.$toast.success(lms_website.__("Your application has been submitted"));
          this.form.is_submitted = true;
        },
        always: (response) => {
          this.form.loading = false;
          if (response.exception && !response._server_messages) {
            frappe.msgprint({
              title: lms_website.__("Error"),
              message: lms_website.__(response.exception),
              indicator: "red",
            });
          }
        }
      });
    }
  },
  mounted() {
    setTimeout(() => {
      NProgress.done();
      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });
    }, 500);
  }
}
</script>

<style lang="scss" scoped>

.card {
  background: #fbfdff;
  border-color: #dde5ef;
  font-family: 'Alexandria', serif;

  .form-label {
    color: #4f5e82;
    font-size: 12px;
  }
}
</style>
