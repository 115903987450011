import {useStorage} from "vue3-storage";
import EventBus from "./bus";


export function isLoggedIn() {
    return (lms_website.user_session !== 'Guest')
}

export function authData() {
    const storage = useStorage();
    let UnixAuth = storage.getStorageSync("UnixAuth");
    EventBus.on('update-auth-data', (data) => {
        UnixAuth = storage.getStorageSync("UnixAuth");
    });
    return (lms_website.user_session !== 'Guest') ? UnixAuth : {}
}
