export const frappeAPI = {
    get_all(doctype, fields = ["*"], filters = {}, order_by = "creation desc") {
        return frappe.call({
            type: "GET",
            method: "lms_website.api.utils.get_all",
            args: {
                doctype: doctype,
                fields: fields,
                filters: filters,
                order_by: order_by
            }
        })
    }
}
