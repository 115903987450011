<template>
  <main>

    <!-- course details breadcrumb start -->
    <section class="tp-breadcrumb__area pt-25 pb-55 p-relative z-index-1 fix">
      <div class="tp-breadcrumb__bg" data-background="/assets/lms_website/website-assets/img/breadcrumb/breadcrumb-bg-2.jpg"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12">
            <div class="tp-breadcrumb__content" style="min-height:270px;">
              <template v-if="loading_data">
                <div class="d-flex align-items-center gap-1 mt-4 placeholder-glow">
                  <span class="placeholder d-inline-block rounded" style="width:120px;height:26px;"></span>
                </div>
                <div class="d-flex align-items-center gap-1 mt-3 placeholder-glow">
                  <span class="placeholder d-inline-block rounded" style="width:50%;height:15px;"></span>
                </div>
                <div class="d-flex align-items-center gap-4 mt-4 placeholder-glow">
                  <div class="d-flex align-items-center gap-1">
                    <span class="placeholder d-inline-block rounded" style="width:50px;height:50px;"></span>
                    <div>
                      <span class="placeholder d-block rounded mb-1" style="width:60px;height:10px;"></span>
                      <span class="placeholder d-block rounded" style="width:100px;height:15px;"></span>
                    </div>
                  </div>
                  <div>
                    <span class="placeholder d-block rounded mb-1" style="width:50px;height:10px;"></span>
                    <span class="placeholder d-block rounded" style="width:100px;height:15px;"></span>
                  </div>
                  <div>
                    <span class="placeholder d-block rounded mb-1" style="width:50px;height:10px;"></span>
                    <span class="placeholder d-block rounded" style="width:100px;height:15px;"></span>
                  </div>
                  <div>
                    <span class="placeholder d-block rounded mb-1" style="width:50px;height:10px;"></span>
                    <span class="placeholder d-block rounded" style="width:100px;height:15px;"></span>
                  </div>
                </div>
              </template>
              <template v-if="!loading_data">
                <div class="tp-course-details-2-header mt-4">
                  <span class="tp-course-details-2-category">{{ course_details?.tags }}</span>
                  <h3 class="tp-course-details-2-title">{{ course_details?.title }}</h3>
                  <div class="tp-course-details-2-meta-wrapper d-flex align-items-center flex-wrap">
                    <div class="tp-course-details-2-meta ">
                      <div class="tp-course-details-2-author d-flex align-items-center" v-if="course_details?.instructor?.name">
                        <div class="tp-course-details-2-author-avater">
                          <img class="rounded" :src="(course_details?.instructor?.user_image)?course_details?.instructor?.user_image:'/assets/lms_website/images/user.png'" alt="Salim Rana">
                        </div>
                        <div class="tp-course-details-2-author-content">
                          <span class="tp-course-details-2-author-designation">Teacher</span>
                          <h3 class="tp-course-details-2-meta-title"><a>{{ course_details?.instructor?.full_name }}</a></h3>
                        </div>
                      </div>
                    </div>
                    <div class="tp-course-details-2-meta">
                      <span class="tp-course-details-2-meta-subtitle">Category</span>
                      <h3 class="tp-course-details-2-meta-title">{{ course_details?.custom_course_category }}</h3>
                    </div>
                    <div class="tp-course-details-2-meta">
                      <span class="tp-course-details-2-meta-subtitle">Last updated</span>
                      <h3 class="tp-course-details-2-meta-title">{{ format_date(course_details?.modified) }}</h3>
                    </div>
                    <div class="tp-course-details-2-meta text-end">
                      <div class="tp-course-details-2-meta-rating-wrapper">
                        <div class="tp-course-rating-icon">
                          <div class="course-star-rating">
                            <div class="back-stars">
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                            </div>
                            <div class="front-stars" :style="'width:'+avg_rating_width(course_details?.avg_rating)">
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                            </div>
                          </div>
                        </div>
                        <span class="tp-course-details-2-meta-subtitle">Review <template v-if="reviews_count && reviews_count>=0">({{ reviews_count }})</template></span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- course details breadcrumb end -->


    <!-- course details area start -->
    <section class="tp-course-details-2-area pt-50 pb-80">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="tp-course-details-2-main-inner pr-70 placeholder-glow" v-if="loading_data">
              <div class="placeholder d-block rounded mt-2" style="width:100%;height:15px;"></div>
              <div class="placeholder d-block rounded mt-2" style="width:100%;height:15px;"></div>
              <div class="placeholder d-block rounded mt-2" style="width:100%;height:15px;"></div>
              <div class="d-flex align-items-center gap-1 mt-2">
                <span class="placeholder d-inline-block rounded" style="width:50%;height:15px;"></span>
              </div>
            </div>
            <div class="tp-course-details-2-main-inner pr-70" v-if="!loading_data">
              <div class="tp-course-details-2-nav d-flex align-items-center">
                <nav>
                  <ul class="d-flex gap-1" id="course_details2_nav">
                    <li class="current"><a href="#info">Course Info</a></li>
                    <li class=""><a href="#resources" v-if="course_details?.resources?.length">Resources</a></li>
                    <li class=""><a href="#curriculum">Content</a></li>
                    <li class=""><a href="#reviews">Reviews</a></li>
                  </ul>
                </nav>
              </div>
              <div class="tp-course-details-2-content">
                <div id="info">
                  <h4 class="tp-course-details-2-main-title">About Course</h4>
                  <div class="tp-course-details-2-text mb-60">
                    <div class="content" v-html="course_details?.description"></div>
                    <a class="tp-course-details-showmore show-more-button">
                      <span class="svg-icon">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 1V11" stroke="#3C66F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M1 6H11" stroke="#3C66F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                      Show more
                    </a>
                  </div>
                </div>
                <div id="resources" v-if="course_details?.resources?.length">
                  <h4 class="tp-course-details-2-main-title">Resources ({{ course_details?.resources?.length }})</h4>
                  <div class="tp-course-details-2-text mb-60">
                    <div class="list-group">
                      <template v-for="resource in course_details?.resources" :key="resource.name">
                        <a :href="resource.url" target="_blank" class="list-group-item list-group-item-action d-flex align-items-center justify-between">
                          <span class="text-dark">{{resource.title}}</span>
                          <i class="far fa-external-link text-secondary"></i>
                        </a>
                      </template>
                    </div>
                  </div>
                </div>
                <CourseContent v-if="course_details && course_details?.name" :chapters="course_chapters" :course-enrolled="course_enrolled"/>
                <CourseReview v-if="course_details && course_details?.name" :course-name="course_details?.name" :is-reviewed="course_details?.is_reviewed" :course-enrolled="course_enrolled"/>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="tp-course-details-2-widget placeholder-glow" v-if="loading_data">
              <div>
                <span class="placeholder d-block rounded" style="width:100%;height:235px;"></span>
              </div>
              <div class="d-flex align-items-center gap-1 mt-3">
                <span class="placeholder d-inline-block rounded" style="width:100px;height:30px;"></span>
              </div>
              <div class="d-flex align-items-center gap-1 mt-3">
                <span class="placeholder d-inline-block rounded" style="width:100%;height:15px;"></span>
              </div>
              <div class="d-flex align-items-center gap-1 mt-2">
                <span class="placeholder d-inline-block rounded" style="width:50%;height:15px;"></span>
              </div>
            </div>
            <div class="tp-course-details-2-widget" v-if="!loading_data">
              <div class="tp-course-details-2-widget-thumb p-relative">
                <template v-if="!videoId || !videoId?.length">
                  <img :src="(course_details?.image)?course_details?.image:'/assets/lms_website/images/placeholder.jpg'" :alt="course_details?.title">
                </template>
                <template v-if="videoId?.length">
                  <iframe style="width:100%;min-height:230px;border:0;border-radius:8px;" :src="`https://www.youtube.com/embed/${videoId}`" allowFullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                  <!--                  <a class="popup-video" :data-video="videoId">-->
                  <!--                    <span>-->
                  <!--                      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                  <!--                        <path d="M0 1.83167C0 1.0405 0.875246 0.562658 1.54076 0.990487L12.6915 8.15882C13.3038 8.55246 13.3038 9.44754 12.6915 9.84118L1.54076 17.0095C0.875246 17.4373 0 16.9595 0 16.1683V1.83167Z" fill="#031F42"/>-->
                  <!--                      </svg>-->
                  <!--                    </span>-->
                  <!--                  </a>-->
                </template>
              </div>
              <div class="tp-course-details-2-widget-content">
                <div class="tp-course-details-2-widget-price">
                  <span v-if="!course_details?.paid_course">Free</span>
                  <span v-if="course_details?.paid_course">{{ format_currency(course_details?.course_price) }}</span>
                </div>
                <div class="tp-course-details-2-widget-btn">
                  <template v-if="course_details?.paid_course && !course_enrolled">
                    <a class="active">Buy Course</a>
                    <p>30-Day Money-Back Guarantee</p>
                  </template>
                  <template v-if="!course_details?.paid_course">
                    <button class="btn btn-primary w-100" @click="enroll_course" v-if="!course_enrolled" :disabled="enroll_loading">
                      <span v-if="!enroll_loading">Enroll Now</span>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true" v-if="enroll_loading"></span>
                    </button>
                  </template>
                </div>
                <div class="tp-course-details-2-widget-list">
                  <h5>This course includes:</h5>

                  <div class="tp-course-details-2-widget-list-item-wrapper">

                    <div class="tp-course-details-2-widget-list-item d-flex align-items-center justify-content-between">
                                    <span> <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 1C12.6415 1 16 4.35775 16 8.5C16 12.6423 12.6415 16 8.5 16C4.35775 16 1 12.6423 1 8.5C1 4.35775 4.35775 1 8.5 1Z" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                       <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8692 8.49618C10.8692 7.85581 7.58703 5.80721 7.2147 6.17556C6.84237 6.54391 6.80657 10.4137 7.2147 10.8168C7.62283 11.2213 10.8692 9.13655 10.8692 8.49618Z" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                       </svg> Lectures</span>
                      <span>{{ course_details?.lesson_count }}</span>
                    </div>
                    <div class="tp-course-details-2-widget-list-item d-flex align-items-center justify-content-between">
                                    <span> <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                       <path d="M8 3.80005V8.00005L10.8 9.40005" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                     </svg> Duration</span>
                      <span>{{ courseDuration }}</span>
                    </div>
                    <div class="tp-course-details-2-widget-list-item d-flex align-items-center justify-content-between">
                                    <span> <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path
                                           d="M14.721 6.64274C14.721 7.8116 14.3744 8.88373 13.7779 9.77851C12.9073 11.0683 11.5289 11.9792 9.9247 12.2129C9.65063 12.2613 9.36849 12.2855 9.07829 12.2855C8.78809 12.2855 8.50596 12.2613 8.23188 12.2129C6.62773 11.9792 5.24929 11.0683 4.37869 9.77851C3.78217 8.88373 3.43555 7.8116 3.43555 6.64274C3.43555 3.52311 5.95866 1 9.07829 1C12.1979 1 14.721 3.52311 14.721 6.64274Z"
                                           stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                       <path opacity="0.4"
                                             d="M16.5341 14.2766L15.2041 14.591C14.9058 14.6636 14.672 14.8893 14.6075 15.1875L14.3254 16.3725C14.1722 17.0174 13.35 17.2109 12.9228 16.703L9.07766 12.2856L5.23253 16.7111C4.80529 17.2189 3.98307 17.0255 3.82991 16.3806L3.54777 15.1956C3.47522 14.8973 3.24145 14.6636 2.95125 14.5991L1.62117 14.2847C1.00853 14.1396 0.790885 13.3738 1.23424 12.9304L4.37806 9.78662C5.24865 11.0764 6.6271 11.9873 8.23125 12.2211C8.50532 12.2694 8.78746 12.2936 9.07766 12.2936C9.36786 12.2936 9.64999 12.2694 9.92407 12.2211C11.5282 11.9873 12.9067 11.0764 13.7773 9.78662L16.9211 12.9304C17.3644 13.3657 17.1468 14.1315 16.5341 14.2766Z"
                                             stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                       <path opacity="0.4"
                                             d="M9.54557 4.20822L10.0212 5.15942C10.0857 5.2884 10.2549 5.41738 10.4081 5.44156L11.2706 5.58665C11.8188 5.67533 11.9478 6.07838 11.5528 6.47338L10.8837 7.14243C10.7709 7.25529 10.7064 7.47295 10.7467 7.63417L10.9401 8.46446C11.0933 9.11741 10.7467 9.37535 10.1663 9.02872L9.36017 8.55312C9.21507 8.46445 8.97324 8.46445 8.82814 8.55312L8.02203 9.02872C7.44163 9.36728 7.09501 9.11741 7.24817 8.46446L7.44163 7.63417C7.47388 7.48101 7.41745 7.25529 7.3046 7.14243L6.63553 6.47338C6.24054 6.07838 6.36951 5.68339 6.91766 5.58665L7.7802 5.44156C7.9253 5.41738 8.09458 5.2884 8.15907 5.15942L8.63467 4.20822C8.86844 3.69231 9.28762 3.69231 9.54557 4.20822Z"
                                             stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                     </svg> Certificate</span>
                      <span>{{ (course_details?.enable_certification) ? 'Yes' : 'No' }}</span>
                    </div>
                    <div class="tp-course-details-2-widget-list-item d-flex align-items-center justify-content-between">
                        <span>
                          <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path
                                 d="M6.57134 7.5C8.36239 7.5 9.81432 6.04493 9.81432 4.25C9.81432 2.45507 8.36239 1 6.57134 1C4.7803 1 3.32837 2.45507 3.32837 4.25C3.32837 6.04493 4.7803 7.5 6.57134 7.5Z"
                                 stroke="#94928E"
                                 stroke-width="1.2"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                             />
                             <path
                                 d="M12.1426 14C12.1426 11.4845 9.64553 9.44995 6.57119 9.44995C3.49684 9.44995 0.999756 11.4845 0.999756 14"
                                 stroke="#94928E"
                                 stroke-width="1.2"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                             />
                          </svg>
                          Students
                        </span>
                      <span>{{ course_details?.enrollment_count }}</span>
                    </div>

                    <!--                    <div class="tp-course-details-2-widget-search p-relative">-->
                    <!--                      <form action="#">-->
                    <!--                        <input type="text" placeholder="Enter Coupon Code">-->
                    <!--                        <button  type="submit">Apply</button>-->
                    <!--                      </form>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- course details area end -->


    <!-- course details area start -->
    <section class="tp-course-details-2-related-area pb-80" v-if="!loading_data">
      <div class="container">
        <div class="tp-course-details-2-related-border"></div>
        <div class="row">
          <div class="col-lg-12">
            <div class="tp-course-details-2-related-heading pt-80">
              <h3 class="tp-course-details-2-related-title">Related Courses</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <template v-if="!related_courses.is_loading">
            <div class="col-lg-4 col-md-6 animate__animated animate__zoomIn" v-for="course in related_courses.list" :key="course.name">
              <CourseWidget :course="course"/>
            </div>
          </template>
          <div class="col-lg-4 col-md-6" v-for="number in range(1, 3)" :key="number" v-if="related_courses.is_loading">
            <div class="tp-course-item p-relative fix mb-30 placeholder-glow">
              <div class="tp-course-teacher mb-15">
                <div class="d-flex align-items-center gap-1">
                  <span class="placeholder d-inline-block rounded-circle" style="width:30px;height:30px;"></span>
                  <span class="placeholder d-inline-block rounded" style="width:80px;height:10px;"></span>
                </div>
              </div>
              <div class="tp-course-thumb">
                <span class="placeholder d-block rounded" style="height:200px;"></span>
              </div>
              <div class="tp-course-content">
                <div class="d-flex align-items-center gap-1 mb-10">
                  <span class="placeholder d-inline-block rounded" style="width:100px;height:25px;"></span>
                </div>
                <div class="tp-course-meta">
                  <div class="d-flex align-items-center gap-1">
                    <span class="placeholder d-inline-block rounded" style="width:80px;height:10px;"></span>
                    <span class="placeholder d-inline-block rounded" style="width:80px;height:10px;"></span>
                  </div>
                </div>
                <span class="placeholder d-block rounded mt-4 mb-1" style="width:80%;height:6px;"></span>
                <span class="placeholder d-block rounded" style="width:30%;height:6px;"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- course details area end -->

    <!--    <ConfirmModal-->
    <!--        :title="lms_website.__('Enroll Course')"-->
    <!--        @confirmed="enroll_course"-->
    <!--    />-->

  </main>
</template>
<script>

import EventBus from "../../utils/bus";
import CourseReview from "./components/CourseReview.vue";
import CourseContent from "./components/CourseContent.vue";
import ConfirmModal from "../../components/ConfirmModal.vue";
import CourseWidget from "../../components/CourseWidget.vue";

export default {
  name: "CourseViewPage",
  components: {CourseWidget, CourseContent, CourseReview, ConfirmModal},
  props: ['id'],
  data() {
    return {
      lms_website: lms_website,
      loading_data: true,
      course_enrolled: false,
      course_details: null,
      reviews_count: null,
      related_courses: {
        is_loading: true,
        list: []
      },
      course_chapters: {
        is_loading: true,
        list: []
      },
      enroll_loading: false,
    }
  },
  computed: {
    courseId() {
      return this.id;
    },
    videoId() {
      return $.trim(this.course_details?.video_link);
    },
    courseDuration() {
      let duration = '';
      if (this.course_details && this.course_details.custom_course_duration) {
        let ms = this.course_details.custom_course_duration;
        let totalSeconds = ms;
        // Calculate days
        let days = Math.floor(totalSeconds / (3600 * 24));
        // Calculate remaining hours after days
        let hours = Math.floor((totalSeconds % (3600 * 24)) / 3600) + (days * 24);
        // Calculate remaining minutes after hours
        let minutes = Math.floor((totalSeconds % 3600) / 60);
        let seconds = totalSeconds % 60;
        duration = `${(hours) ? hours + 'h' : ''} ${(minutes) ? minutes + 'm' : ''} ${(seconds) ? seconds + 's' : ''}`;
      }
      return duration;
    },
  },
  methods: {
    check_course_enrolled(course_id, callback) {
      this.loading_data = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.check_course_enrolled",
        args: {
          course_name: course_id,
        },
        callback: (response) => {
          callback(response);
        },
      });
    },
    get_course_details(course_id, callback) {
      this.loading_data = true;
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_courses_details",
        args: {
          course_name: course_id,
        },
        callback: ({message}) => {
          this.course_details = {};
          if (message.instructors && message.instructors[0]) {
            message['instructor'] = message.instructors[0];
          } else {
            message['instructor'] = {};
          }
          setTimeout(() => {
            this.course_details = message;
            this.loading_data = false;
            callback();
          }, 500)
        },
      });
    },
    get_course_lessons(course_id) {
      this.course_chapters.is_loading = true;
      this.course_chapters.list = [];
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_course_chapters",
        args: {
          course_name: course_id,
        },
        callback: ({message}) => {
          this.course_chapters.is_loading = false;
          this.course_chapters.list = message;
        },
      });
    },
    get_related_courses(course_id) {
      this.related_courses.is_loading = true;
      this.related_courses.list = [];
      frappe.call({
        type: "GET",
        method: "lms_website.api.courses.get_related_courses",
        args: {
          course_name: course_id,
        },
        callback: ({message}) => {
          this.related_courses.is_loading = false;
          if (message && message.length) {
            message.map(row => {
              if (row.instructors && row.instructors[0]) {
                row['instructor'] = row.instructors[0];
              } else {
                row['instructor'] = {};
              }
              this.related_courses.list.push(row);
            });
          }
        },
      });
    },
    confirm_enroll_course() {
      if (frappe.session.user === 'Guest') {
        frappe.msgprint(lms_website.__('You must <b>log in</b> before enroll the course.'), lms_website.__('Warning'))
        return false;
      }
      $('#confirmModal').modal('show');
    },
    enroll_course() {
      if (frappe.session.user === 'Guest') {
        this.$swal.fire({
          title: lms_website.__('Warning'),
          html: lms_website.__('You must <b>log in</b> before enroll the course.'),
          icon: "warning",
          showDenyButton: true,
          confirmButtonText: lms_website.__('Ok, login!')
        }).then(({isConfirmed}) => {
          if (isConfirmed) {
            this.$router.push({name: 'auth.login', query: {redirect: this.$router.currentRoute.value.fullPath}});
          }
        });
        return false;
      }
      this.$swal.fire({
        title: lms_website.__('Enroll Course'),
        text: lms_website.__('Confirm to access to all course content'),
        icon: "question",
        showDenyButton: true,
        confirmButtonText: lms_website.__('Yes, enroll!')
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          this.enroll_loading = true;
          frappe.call({
            type: "POST",
            method: "lms_website.api.courses.create_course_enrolled",
            args: {
              course_name: this.courseId
            },
            callback: ({message}) => {
              this.$toast.success(lms_website.__("You have been enrolled to course."));
              window.location.reload();
            },
            always: (response) => {
              if (response.exception || response._server_messages)
                this.enroll_loading = false;

              if (response.exception && !response._server_messages) {
                frappe.msgprint({
                  title: lms_website.__("Error"),
                  message: lms_website.__(response.exception),
                  indicator: "red",
                });
              }
            }
          });
        }
      });
    },
    avg_rating_width(value) {
      if (value) {
        return `${((value / 5) * 100) + 3}%`;
      }
      return '0%';
    },
    clip(str, limit) {
      if (!str) return '';
      str = str.toString();
      if (str.length <= limit) {
        return str;
      }
      return str.substr(0, limit) + '...';
    },
    format_currency(price = 0) {
      return format_currency(price);
    },
    range(start, end) {
      return Array.from({length: end - start + 1}, (_, i) => start + i);
    },
    format_date(date, format = 'D MMM, YYYY') {
      return moment(date).format(format)
    },
  },
  watch: {
    '$route.params.id': function (course_id) {
      this.check_course_enrolled(this.courseId, (response) => {
        this.course_enrolled = !!(response && response.message);
        this.get_course_details(course_id, () => {
          setTimeout(() => {
            NProgress.done();
            this.get_course_lessons(course_id);
            this.get_related_courses(course_id);
          }, 500);
        });
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  mounted() {

    EventBus.on('update-course-reviews-count', (count) => {
      this.reviews_count = count;
    });

    this.check_course_enrolled(this.courseId, (response) => {
      this.course_enrolled = !!(response && response.message);
      this.get_course_details(this.courseId, () => {
        setTimeout(() => {
          NProgress.done();
          this.get_course_lessons(this.courseId);
          this.get_related_courses(this.courseId);
        }, 500);
      });
    });

    setTimeout(() => {
      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });
    }, 500);

  }
}
</script>

<style lang="scss" scoped>
button.accordion-button.d-flex.justify-content-between {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-item {
  overflow: hidden;
}
</style>
