<template>
  <div id="curriculum" class="pt-30">
    <h4 class="tp-course-details-2-main-title">Course Content</h4>
    <div class="tp-course-details-2-faq">
      <div class="accordion" id="_accordionPanelsStayOpenExample">
        <template v-for="(chapter,index) in chapters.list" :key="chapter.uid">
          <div class="accordion-item">
            <h2 class="accordion-header" :id="`panelsStayOpen-heading-${chapter.uid}`">
              <button class="accordion-button d-flex justify-content-between" :class="(index === 0)?'':'collapsed'" type="button" data-bs-toggle="collapse" :data-bs-target="`#panelsStayOpen-collapse-${chapter.uid}`" :aria-expanded="index === 0" :aria-controls="`panelsStayOpen-collapse-${chapter.uid}`">
                <span class="span">{{ chapter.title }}</span>
                <span class="lesson">{{ chapter.lessons?.length }} Lessons </span>
                <span class="accordion-btn"></span>
              </button>
            </h2>
            <div :id="`panelsStayOpen-collapse-${chapter.uid}`" class="accordion-collapse collapse" :class="(index === 0)?'show':''" :aria-labelledby="`panelsStayOpen-heading-${chapter.uid}`">
              <div class="accordion-body">
                <template v-if="chapter.lessons?.length">
                  <div v-for="lesson in chapter.lessons" class="tp-course-details-2-faq-item">
                    <div class="d-flex justify-content-between">
                      <template v-if="lesson.quiz_id">
                        <div class="left">
                      <span>
                        <i class="far fa-clipboard-list-check mr-10 text-secondary"></i>
                        <i>{{ lms_website.__('Quiz') }}:</i> {{ lesson.title }}
                      </span>
                        </div>
                        <div class="right">
                      <span>
                        <a v-if="courseEnrolled && !quiz.is_start" @click="startQuiz(lesson.quiz_id)" class="pointer-event green">Start</a>
                        <a v-if="courseEnrolled && quiz.is_start" @click="cancelQuiz(lesson.quiz_id)" class="pointer-event red">Cancel</a>
                        <i class="far fa-lock-alt" v-if="!courseEnrolled"></i>
                      </span>
                        </div>
                      </template>
                      <template v-if="!lesson.quiz_id">
                        <div class="left">
                          <span>
                            <i class="far fa-play-circle mr-10 text-secondary" v-if="lesson.custom_lesson_type==='Video' || lesson.custom_lesson_type==='Multiple Videos'"></i>
                            <i class="far fa-headphones mr-10 text-secondary" v-if="lesson.custom_lesson_type==='Audio'"></i>
                            <i class="far fa-file-pdf mr-10 text-secondary" v-if="lesson.custom_lesson_type==='PDF'"></i>
                            <i class="far fa-book mr-10 text-secondary" v-if="lesson.custom_lesson_type==='PDF Link'"></i>
                            <i>{{ lesson.custom_lesson_type }}:</i> {{ lesson.title }}
                          </span>
                        </div>
                        <div class="right">
                      <span>
                        <a @click="openLessonModal(lesson)" v-if="lesson.include_in_preview ||  courseEnrolled" class="pointer-event">Preview</a>
                        <i class="far fa-lock-alt" v-if="!lesson.include_in_preview && !courseEnrolled"></i>
                      </span>
                        </div>
                      </template>
                    </div>
                    <template v-if="lesson.quiz_id && quiz.is_start && quiz.active_quiz===lesson.quiz_id">
                      <div class="clearfix"></div>
                      <div class="course-quiz-content" :class="{'form-loading':(quiz.loading || quiz.submit_loading)}">
                        <h5>{{ quiz.data?.title }}</h5>
                        <template v-if="checkQuizMaxAttempts">
                          <div class="alert alert-info mt-3" style="padding:5px 10px;">
                            <p class="mb-0 font-s-13 text-dark">{{ lms_website.__('You will have to get {0}% correct answers in order to pass the quiz.', [quiz.data?.passing_percentage]) }}</p>
                          </div>
                        </template>
                        <template v-if="!checkQuizMaxAttempts">
                          <div class="alert alert-warning mt-3" style="padding:5px 10px;">
                            <p class="mb-1">{{ lms_website.__('You have reached the maximum number of attempts allowed for this quiz') }}</p>
                            <router-link :to="{name:'profile.my_quiz'}"><b>Open Your Submissions</b></router-link>
                          </div>
                        </template>
                        <template v-if="checkQuizMaxAttempts">
                          <div class="text-center mt-3" v-if="quiz.is_submitted">
                            <CheckMark v-if="quiz.result?.pass"/>
                            <FailMark v-if="!quiz.result?.pass"/>
                            <h3 class="mt-3">
                              <span class="text-secondary">{{ lms_website.__('You got {0}%', [quiz.result?.percentage]) }} - </span>
                              <span class="text-success" v-if="quiz.result?.pass">{{ lms_website.__('Passed') }}</span>
                              <span class="text-danger" v-if="!quiz.result?.pass">{{ lms_website.__('Failed') }}</span>
                            </h3>
                            <p class="text-dark">{{ lms_website.__('Your score is {0}/{1}.', [quiz.result?.score, quiz.result?.score_out_of]) }}</p>
                          </div>
                          <div v-if="!quiz.is_submitted">
                            <template v-for="question in quizQuestions">
                              <fieldset>
                                <div class="question-title" v-html="question.question"></div>
                                <div class="question-content">
                                  <template v-if="question.type==='Choices'">
                                    <ul class="list-group">
                                      <template v-for="(answer) in (question.answers)" :key="answer.uid">
                                        <li class="list-group-item d-flex align-items-center" v-if="answer.answer" :data-uid="answer.uid">
                                          <template v-if="!question.is_checked">
                                            <template v-if="!question.multiple">
                                              <input class="form-check-input form-check-input-lg mr-2" type="radio" v-model="quiz.answers[question.name]" :id="`${question.name}-option_${answer.uid}`" :value="answer.answer">
                                            </template>
                                            <template v-if="question.multiple">
                                              <input class="form-check-input form-check-input-lg mr-2" type="checkbox" v-model="quiz.answers[question.name]" :id="`${question.name}-option_${answer.uid}`" :value="answer.answer">
                                            </template>
                                            <label class="form-check-label stretched-link" :for="`${question.name}-option_${answer.uid}`">{{ answer.answer }}</label>
                                          </template>
                                          <template v-if="question.is_checked">
                                            <span class="badge text-bg-secondary mr-1" v-if="!answer.is_answered && !answer.is_correct"><i class="far fa-minus-circle"></i></span>
                                            <span class="badge text-bg-success mr-1" v-if="(answer.is_answered && answer.is_correct) || answer.is_correct"><i class="far fa-check"></i></span>
                                            <span class="badge text-bg-danger mr-1" v-if="answer.is_answered && !answer.is_correct"><i class="far fa-times"></i></span>
                                            <label class="form-check-label stretched-link">{{ answer.answer }}</label>
                                          </template>
                                        </li>
                                      </template>
                                    </ul>
                                    <div class="invalid-feedback d-block" v-if="question.validate_error"><i class="far fa-info-circle mr-1"></i> {{ question.validate_error }}</div>
                                  </template>
                                  <template v-if="question.type==='User Input'">
                                    <div class="position-relative">
                                      <textarea class="form-control" v-model="quiz.answers[question.name]" :class="{'is-invalid':(question.correct_error),'is-valid':(question.correct_success)}" style="height:100px;resize:none;" placeholder="Your Answer"></textarea>
                                      <div class="valid-feedback d-block" v-if="question.correct_success"><i class="far fa-check-circle mr-1"></i> {{ question.correct_success }}</div>
                                      <div class="invalid-feedback d-block" v-if="question.correct_error"><i class="far fa-times-circle mr-1"></i> {{ question.correct_error }}</div>
                                      <div class="invalid-feedback d-block" v-if="question.validate_error"><i class="far fa-info-circle mr-1"></i> {{ question.validate_error }}</div>
                                    </div>
                                  </template>
                                </div>
                              </fieldset>
                              <div class="d-flex align-items-center mt-4">
                                <b class="text-secondary">Question {{ quiz.pagination.currentPage }} / {{ quiz.data?.custom_number_of_questions }}</b>
                                <div class="ms-auto">
                                  <template v-if="quiz.data?.show_answers">
                                    <button type="button" class="btn btn-sm btn-primary" @click="quiz_check_answer(question)" v-if="!question.is_checked" :disabled="!quiz.answers[question.name] || !quiz.answers[question.name]?.length"><i class="far fa-check-circle"></i> Check</button>
                                    <button type="button" class="btn btn-sm btn-secondary" @click="next_question(question)" v-if="question.is_checked && (quiz.pagination.currentPage < quiz.data?.custom_number_of_questions)" :disabled="!quiz.answers[question.name]">Next Question <i class="far fa-arrow-right ml-1"></i></button>
                                    <button type="button" class="btn btn-sm btn-primary" @click="submit_answers()" v-if="question.is_checked && (quiz.pagination.currentPage === quiz.data?.custom_number_of_questions)" :disabled="!quiz.answers[question.name]">Submit Answers <i class="far fa-arrow-right ml-1"></i></button>
                                  </template>
                                  <template v-if="!quiz.data?.show_answers">
                                    <button type="button" class="btn btn-sm btn-secondary" @click="next_question(question)" v-if="(quiz.pagination.currentPage < quiz.data?.custom_number_of_questions)" :disabled="!quiz.answers[question.name] || !quiz.answers[question.name]?.length">Next Question <i class="far fa-arrow-right ml-1"></i></button>
                                    <button type="button" class="btn btn-sm btn-primary" @click="submit_answers()" v-if="(quiz.pagination.currentPage === quiz.data?.custom_number_of_questions)" :disabled="!quiz.answers[question.name]">Submit Answers <i class="far fa-arrow-right ml-1"></i></button>
                                  </template>
                                </div>
                              </div>
                            </template>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div class="modal fade" id="lessonViewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="lessonViewModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" :class="{'modal-fullscreen':(lessonModal.full_screen),'modal-xl':(!lessonModal.full_screen)}">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="lessonViewModalLabel">{{ lessonModal.lesson?.title }}</h1>
          <div class="ms-auto">
            <button type="button" class="btn btn-full-screen" @click="lessonModal.full_screen=!lessonModal.full_screen">
              <i v-if="!lessonModal.full_screen" class="far fa-expand"></i>
              <i v-if="lessonModal.full_screen" class="far fa-compress"></i>
            </button>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div class="modal-body">
          <!--Video-->
          <template v-if="lessonModal.lesson?.custom_lesson_type==='Video'">
            <div class="row">
              <div :class="(lessonModal.lesson?.pdf_audio_list?.length || lessonModal.lesson?.custom_text_content?.length)?'col-lg-8':'col-lg-12'">
                <template v-if="lessonModal.lesson?.custom_video_type==='YouTube Link'">
                  <iframe v-if="lessonModal.lesson?.custom_youtube_link" class="youtube-iframe" style="width:100%;border:0;border-radius:10px;" :src="`https://www.youtube.com/embed/${getYouTubeVideoId(lessonModal.lesson?.custom_youtube_link)}`" allowFullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                  <div class="alert alert-warning" v-if="!lessonModal.lesson?.custom_youtube_link">Video url not found</div>
                </template>
                <template v-if="lessonModal.lesson?.custom_video_type==='Attach File'">
                  <video controls controlsList="nodownload" style="width:100%;height:100%" v-if="lessonModal.lesson?.custom_video_link">
                    <source :src="lessonModal.lesson?.custom_video_link" type="audio/ogg">
                    Your browser does not support the video tag.
                  </video>
                  <div class="alert alert-warning" v-if="!lessonModal.lesson?.custom_video_link">Video file url not found</div>
                </template>
                <div class="col-lg-4" v-if="lessonModal.lesson?.pdf_audio_list?.length || lessonModal.lesson?.custom_text_content?.length">
                  <div class="card mb-3" v-if="lessonModal.lesson?.pdf_audio_list?.length">
                    <div class="card-header">
                      Audio List
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" v-for="audio in lessonModal.lesson?.pdf_audio_list">
                        {{ audio.title }}
                        <audio controls controlsList="nodownload" style="width:100%" v-if="audio.audio_file">
                          <source :src="audio.audio_file" type="audio/ogg">
                          Your browser does not support the audio tag.
                        </audio>
                      </li>
                    </ul>
                  </div>
                  <div class="card" v-if="lessonModal.lesson?.custom_text_content?.length">
                    <div class="card-header">
                      Text Content
                    </div>
                    <div class="card-body" v-html="lessonModal.lesson?.custom_text_content"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="lessonModal.lesson?.custom_lesson_type==='Multiple Videos'">
            <div class="row">
              <div :class="(lessonModal.lesson?.pdf_audio_list?.length || lessonModal.lesson?.custom_text_content?.length)?'col-lg-8':'col-lg-12'">
                <template v-for="video in lessonModal.lesson?.videos_list">
                  <div class="mb-3">
                    <template v-if="video.link_type==='URL'">
                      <iframe v-if="video.video_url" class="youtube-iframe" style="width:100%;border:0;border-radius:10px;" :src="`https://www.youtube.com/embed/${getYouTubeVideoId(video.video_url)}`" allowFullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                      <div class="alert alert-warning" v-if="!video.video_url">Video url not found</div>
                    </template>
                    <template v-if="video.link_type==='Attach File'">
                      <video controls controlsList="nodownload" style="width:100%;height:100%" v-if="video.attach_video">
                        <source :src="video.attach_video" type="audio/ogg">
                        Your browser does not support the video tag.
                      </video>
                      <div class="alert alert-warning" v-if="!video.attach_video">Video file url not found</div>
                    </template>
                  </div>
                </template>
              </div>
              <div class="col-lg-4" v-if="lessonModal.lesson?.pdf_audio_list?.length || lessonModal.lesson?.custom_text_content?.length">
                <div class="card mb-3" v-if="lessonModal.lesson?.pdf_audio_list?.length">
                  <div class="card-header">
                    Audio List
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item" v-for="audio in lessonModal.lesson?.pdf_audio_list">
                      {{ audio.title }}
                      <audio controls controlsList="nodownload" style="width:100%" v-if="audio.audio_file">
                        <source :src="audio.audio_file" type="audio/ogg">
                        Your browser does not support the audio tag.
                      </audio>
                    </li>
                  </ul>
                </div>
                <div class="card" v-if="lessonModal.lesson?.custom_text_content?.length">
                  <div class="card-header">
                    Text Content
                  </div>
                  <div class="card-body" v-html="lessonModal.lesson?.custom_text_content"></div>
                </div>
              </div>
            </div>
          </template>
          <!--/Video-->
          <!--Audio-->
          <template v-if="lessonModal.lesson?.custom_lesson_type==='Audio'">
            <template v-if="lessonModal.lesson?.custom_video_type==='Audio Link' || lessonModal.lesson?.custom_video_type==='Attach File'">
              <audio controls controlsList="nodownload" style="width:100%" v-if="lessonModal.lesson?.custom_audio_link || lessonModal.lesson?.custom_audio_file">
                <source :src="lessonModal.lesson?.custom_audio_link || lessonModal.lesson?.custom_audio_file" type="audio/ogg">
                Your browser does not support the audio tag.
              </audio>
              <div class="alert alert-warning" v-if="!lessonModal.lesson?.custom_audio_link && !lessonModal.lesson?.custom_audio_file">Audio file url not found</div>
            </template>
          </template>
          <!--/Audio-->
          <!--PDF-->
          <template v-if="lessonModal.lesson?.custom_lesson_type==='PDF'">
            <div class="row">
              <div :class="(lessonModal.lesson?.pdf_audio_list?.length || lessonModal.lesson?.custom_text_content?.length)?'col-lg-8':'col-lg-12'">
                <div class="pdf-viewer" :class="(!lessonModal.file_loaded)?'is-loading':''">
                  <div id="PDFViewer"></div>
                  <!--                  <PDF v-if="lessonModal.lesson?.custom_pdf_file" :src="getPDFLink(lessonModal.lesson?.custom_pdf_file)"/>-->
                  <!--                  <iframe v-if="lessonModal.lesson?.custom_pdf_file" id="PDFViewer" src="about:blank" style="width:100%;min-height:calc(100vh - 180px);border:0;border-radius:10px;"></iframe>-->
                </div>
                <div class="alert alert-warning" v-if="!lessonModal.lesson?.custom_pdf_file">PDF file url not found</div>
              </div>
              <div class="col-lg-4" v-if="lessonModal.lesson?.pdf_audio_list?.length">
                <div class="card mb-3">
                  <div class="card-header">
                    Audio List
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item" v-for="audio in lessonModal.lesson?.pdf_audio_list">
                      {{ audio.title }}
                      <audio controls controlsList="nodownload" style="width:100%" v-if="audio.audio_file">
                        <source :src="audio.audio_file" type="audio/ogg">
                        Your browser does not support the audio tag.
                      </audio>
                    </li>
                  </ul>
                </div>
                <div class="card" v-if="lessonModal.lesson?.custom_text_content?.length">
                  <div class="card-header">
                    Text Content
                  </div>
                  <div class="card-body" v-html="lessonModal.lesson?.custom_text_content"></div>
                </div>
              </div>
            </div>
          </template>
          <!--/PDF-->
          <!--PDF Link-->
          <template v-if="lessonModal.lesson?.custom_lesson_type==='PDF Link'">
            <div class="pdf-viewer">
              <iframe v-if="lessonModal.lesson?.custom_pdf_link" class="custom-iframe" :src="lessonModal.lesson?.custom_pdf_link" style="width:100%;border:0;border-radius:10px;"></iframe>
            </div>
          </template>
          <!--/PDF Link-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import CheckMark from "../../../components/shared/CheckMark.vue";
import FailMark from "../../../components/shared/FailMark.vue";

export default {
  name: "CourseContent",
  components: {FailMark, CheckMark, Modal},
  props: {
    chapters: {
      type: [Object, Array],
      default: {
        is_loading: true,
        list: []
      }
    },
    courseEnrolled: {
      type: [Boolean],
      default: false
    }
  },
  data() {
    return {
      lms_website: lms_website,
      lessonModal: {
        file_loaded: false,
        full_screen: false,
        lesson: {}
      },
      quiz: {
        active_quiz_max_attempts: 0,
        active_quiz: null,
        loading: false,
        is_start: false,
        submit_loading: false,
        is_submitted: false,
        data: {},
        answers: {},
        originalArray: [1, 2, 3, 4],
        shuffledArray: [],
        result: undefined,
        pagination: {
          perPage: 1,
          currentPage: 1
        }
      },
    }
  },
  computed: {
    quizQuestions() {
      if (this.quiz.data && this.quiz.data.questions) {
        const offset = (this.quiz.pagination.currentPage - 1) * this.quiz.pagination.perPage;
        return this.quiz.data.questions.slice(offset, offset + this.quiz.pagination.perPage);
      } else
        return [];
    },
    checkQuizMaxAttempts() {
      if (this.quiz.data?.max_attempts === 0) {
        return true;
      } else {
        return this.quiz.data?.max_attempts > this.quiz.active_quiz_max_attempts;
      }
    }
  },
  watch: {},
  methods: {
    openLessonModal(lesson) {
      this.lessonModal.file_loaded = false;
      this.lessonModal.lesson = lesson;
      const PDFViewer = $('#PDFViewer');
      $('#lessonViewModal').modal('show');
      if (lesson.custom_lesson_type === 'PDF') {
        PDFViewer.html('');
        setTimeout(() => {
          // const pdf_url = `https://docs.google.com/viewer?url=${this.getPDFLink(lesson?.custom_pdf_file)}&embedded=true`;
          $.when(
              $.getScript("https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf.js"),
              $.getScript("https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf.worker.js")
          ).done(() => {
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf.worker.js';
          }).then(() => {
            $("#PDFViewer").kendoPDFViewer({
              pdfjsProcessing: {
                file: this.getPDFLink(lesson?.custom_pdf_file)
              },
              width: "100%",
              height: window.innerHeight - 180
            });
            this.lessonModal.file_loaded = true;
          })
        }, 1000);
      } else {
        this.lessonModal.file_loaded = true;
      }
    },
    check_quiz_submissions(quiz_name, callback) {
      frappe.call({
        type: "GET",
        method: "lms_website.api.quiz.check_student_quiz_submissions",
        args: {
          quiz: quiz_name
        },
        callback: (response) => {
          callback(response)
        }
      });
    },
    startQuiz(quiz_id) {
      this.quiz.is_start = true;
      this.quiz.loading = true;
      this.quiz.active_quiz = quiz_id;
      this.check_quiz_submissions(quiz_id, ({message}) => {
        this.quiz.active_quiz_max_attempts = message;
        console.log('this.quiz.active_quiz_max_attempts', this.quiz.active_quiz_max_attempts)
        frappe.call({
          type: "GET",
          method: "lms_website.api.quiz.get_quiz_data",
          args: {
            quiz_id: quiz_id
          },
          callback: ({message}) => {
            setTimeout(() => {
              let questions_list = [];
              this.quiz.loading = false;
              this.quiz.data = message;
              if (message.questions && message.questions.length) {
                message.questions.map(question => {
                  question.answers = [];
                  if (question.type === 'Choices') {
                    let valid_answers = [];
                    if (question.multiple) {
                      this.quiz.answers[question.name] = [];
                    }
                    for (let i = 1; i <= 4; i++) {
                      if (question[`is_correct_${i}`] === 1) {
                        valid_answers.push(question[`option_${i}`]);
                      }
                    }
                    question.valid_answers = valid_answers;
                    question.is_correct_answer = (value) => {
                      valid_answers.includes(value)
                    };
                    for (let i = 1; i <= 4; i++) {
                      let uid = this.generateUniqueId();
                      question.answers.push({
                        uid: uid,
                        answer: question[`option_${i}`],
                        is_answered: false,
                        is_correct: valid_answers.includes(question[`option_${i}`])
                      })
                    }
                  } else if (question.type === 'User Input') {
                    question.possibility_answers = [];
                    let possibility_answers = [];
                    for (let i = 1; i <= 4; i++) {
                      if (question[`possibility_${i}`]) {
                        possibility_answers.push(question[`possibility_${i}`]);
                      }
                      question.possibility_answers = possibility_answers;
                    }
                  }
                  questions_list.push(question);
                });
              }
              this.quiz.data['questions'] = questions_list;
              // this.generateRandomAnswers();
              console.log('this.quiz.data', this.quiz.data)
            }, 500);
          },
          always: (response) => {
            // this.quiz.loading = false;
            if (response.exception && !response._server_messages) {
              frappe.msgprint({
                title: lms_website.__("Error"),
                message: lms_website.__(response.exception),
                indicator: "red",
              });
            }
          }
        });
      });
    },
    cancelQuiz() {
      setTimeout(() => {
        this.quiz = {
          active_quiz: null,
          loading: false,
          is_start: false,
          data: {},
          answers: {},
          originalArray: [1, 2, 3, 4],
          shuffledArray: [],
          pagination: {
            perPage: 1,
            currentPage: 1
          }
        };
      }, 10);
    },
    quiz_check_answer(question) {
      setTimeout(() => {
        if (question.type === 'Choices') {
          if (question.answers && question.answers.length) {
            question.answers.map(answer => {
              if (question.multiple) {
                if (this.quiz.answers[question.name] && this.quiz.answers[question.name].length) {
                  question.is_checked = true;
                  question.validate_error = null;
                  answer.is_answered = (this.quiz.answers[question.name].includes(answer.answer));
                } else {
                  question.is_checked = false;
                  question.validate_error = lms_website.__('Please enter your answer');
                }
              } else {
                answer.is_answered = (answer.answer === this.quiz.answers[question.name]);
                question.is_checked = true;
              }
            });
          }
        } else if (question.type === 'User Input') {
          if ($.trim(this.quiz.answers[question.name])) {
            question.validate_error = null;
            question.correct_success = null;
            question.correct_error = null;
            question.is_checked = true;
            if (question.possibility_answers.includes(this.quiz.answers[question.name])) {
              question.correct_success = lms_website.__('Correct Answer');
              question.correct_error = null;
            } else {
              question.correct_success = null;
              question.correct_error = lms_website.__('Incorrect Answer');
            }
          } else {
            question.validate_error = lms_website.__('Please enter your answer');
          }
        }
      }, 10);
    },
    next_question(question) {
      setTimeout(() => {
        question.is_checked = true;
        this.quiz.pagination.currentPage++;
      }, 100);
    },
    submit_answers() {
      this.$swal.fire({
        title: lms_website.__('Do you want to submit your answers?'),
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: lms_website.__('Yes, submit it!')
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          let results = [];
          if (this.quiz.data.questions && this.quiz.data.questions.length) {
            this.quiz.data.questions.map((question, index) => {
              let answer_obj = {};
              let question_answer = this.quiz.answers[question.name];
              answer_obj['question_index'] = index + 1;
              answer_obj['question_name'] = question.name;
              answer_obj['question_name'] = question.name;
              if (question.type === 'Choices') {
                if (question.multiple) {
                  answer_obj['answer'] = question_answer.join(',');
                  let is_correct = [];
                  if (this.quiz.answers[question.name] && this.quiz.answers[question.name].length) {
                    this.quiz.answers[question.name].map(value => {
                      is_correct.push(Number(question.valid_answers.includes(value)));
                    });
                  }
                  answer_obj['is_correct'] = is_correct
                } else {
                  answer_obj['answer'] = String(question_answer);
                  answer_obj['is_correct'] = [Number(question.valid_answers.includes(question_answer))];
                }
              } else {
                answer_obj['answer'] = String(question_answer);
                answer_obj['is_correct'] = [Number(question.possibility_answers.includes(question_answer))];
              }
              results.push(answer_obj)
            });

            this.quiz.submit_loading = true;
            this.quiz.is_submitted = false;

            console.log('results', results);
          }
          frappe.call({
            type: "POST",
            method: "lms_website.api.quiz.submit_quiz_answers",
            args: {
              quiz: this.quiz.active_quiz,
              results: results,
            },
            callback: ({message}) => {
              this.quiz.submit_loading = false;
              this.quiz.is_submitted = true;
              this.quiz.result = message;
              console.log('===quiz_summary==', message);
            },
            always: (response) => {
              if (response.exception || response._server_messages) {
                this.quiz.submit_loading = false;
              }
              if (response.exception && !response._server_messages) {
                frappe.msgprint({
                  title: lms_website.__("Error"),
                  message: lms_website.__(response.exception),
                  indicator: "red",
                });
              }
            }
          });
          // this.quiz.is_start = false;
          // this.quiz.loading = false;
          // this.quiz.active_quiz = null;
        }
      });
    },
    generateUniqueId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    },
    getYouTubeVideoId(url) {
      const urlObj = new URL(url);
      return urlObj.searchParams.get("v");
    },
    getPDFLink(pdf_link) {
      if (this.isValidUrl(pdf_link)) {
        return pdf_link;
      } else {
        return lms_website.get_base_url() + pdf_link;
      }
    },
    updatePDFViewer(newUrl, callback) {
      const iframe = document.getElementById('PDFViewer');
      iframe.onload = function () {
        console.log('Iframe loaded with new content', iframe);
        callback();
      };
      iframe.src = newUrl;
    },
    isValidUrl(str) {
      const regex = /^(http|https):\/\/[^ "]+$/;
      return regex.test(str);
    },
    generateRandomAnswers(answers) {
      const answers_array = [...answers];
      for (let i = answers_array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [answers_array[i], answers_array[j]] = [answers_array[j], answers_array[i]]; // Swap elements
      }
      // this.quiz.shuffledArray = answers_array;
      return [...answers_array];
      // this.quiz.shuffledArray = this.shuffleArray([...this.quiz.originalArray]);
    },
    shuffleArray(array) {
      let currentIndex = array.length, randomIndex;
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }
      return array;
    }
  },
  mounted() {
    $('#lessonViewModal').on('hidden.bs.modal', () => {
      const mediaElements = document.querySelectorAll('video, audio');
      mediaElements.forEach(function (media) {
        media.pause();
      });
    });
    setTimeout(() => {
      if ($('.show-more-button').length > 0) {
        const showMoreButton = document.querySelector('.show-more-button');
        const content = document.querySelector('.content');

        showMoreButton.addEventListener('click', function () {
          const svgIcon = showMoreButton.querySelector('.svg-icon');
          if (content.classList.contains('show')) {
            content.classList.remove('show');
            showMoreButton.innerHTML = '';
            svgIcon.innerHTML = `
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6 1V11" stroke="#3C66F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M1 6H11" stroke="#3C66F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					`;
            showMoreButton.appendChild(svgIcon);
            showMoreButton.append('Show more');
          } else {
            content.classList.add('show');
            showMoreButton.innerHTML = '';
            svgIcon.innerHTML = `
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 6H11" stroke="#3C66F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					`;
            showMoreButton.appendChild(svgIcon);
            showMoreButton.append('Show less');
          }
        });
      }
      setTimeout(() => {
        $('#course_details2_nav').onePageNav();
      }, 800);
    }, 500);
  }
}
</script>

<style lang="scss" scoped>
.pdf-viewer {
  background: #eeeeee;
  min-height: calc(100vh - 180px);
  border-radius: 10px;

  &.is-loading {
    position: relative;

    iframe {
      opacity: 0;
    }

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 20;
      content: "";
    }

    &:after {
      box-shadow: 0 0 45px rgba(0, 0, 0, 0.45);
      border: 4px solid #b9bac4;
      border-top: 4px solid var(--bs-primary);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: block;
      animation: loader-spin 0.5s linear infinite;
      -webkit-animation: loader-spin 0.5s linear infinite;
      -moz-animation: loader-spin 0.5s linear infinite;
      -o-animation: loader-spin 0.5s linear infinite;
      -ms-animation: loader-spin 0.5s linear infinite;
      position: absolute;
      z-index: 55;
      left: 50%;
      top: 50%;
      margin-top: -20px;
      margin-left: -20px;
      content: "";
    }
  }
}

.youtube-iframe, .custom-iframe {
  min-height: calc(100vh - 180px)
}

.modal-fullscreen {
  .pdf-viewer {
    min-height: calc(100vh - 100px);
  }

  .youtube-iframe, .custom-iframe {
    min-height: calc(100vh - 110px);
  }
}

</style>
