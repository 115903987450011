<template>
  <!-- dashboard-content-area-start -->
  <div class="tpd-content-layout">
    <div class="tp-profile-wrapper">
      <div class="tp-dashboard-tab mb-30">
        <h2 class="tp-dashboard-tab-title">My Profile</h2>
      </div>
      <div class="tp-profile-box">
        <div class="tp-profile-wrap">
          <ul>
            <li>
              <div class="tp-profile-info d-flex">
                <div class="tp-profile-info-tag">
                  <span>Registration Date</span>
                </div>
                <div class="tp-profile-info-details">
                  <span>{{ moment(auth.user?.creation).format("DD, MMMM YYYY - hh:mm a") }}</span>
                </div>
              </div>
            </li>
            <li>
              <div class="tp-profile-info d-flex">
                <div class="tp-profile-info-tag">
                  <span>First Name</span>
                </div>
                <div class="tp-profile-info-details">
                  <span>{{ auth.user?.first_name }}</span>
                </div>
              </div>
            </li>
            <li>
              <div class="tp-profile-info d-flex">
                <div class="tp-profile-info-tag">
                  <span>Last Name</span>
                </div>
                <div class="tp-profile-info-details">
                  <span>{{ auth.user?.last_name }}</span>
                </div>
              </div>
            </li>
            <li>
              <div class="tp-profile-info d-flex">
                <div class="tp-profile-info-tag">
                  <span>Username</span>
                </div>
                <div class="tp-profile-info-details">
                  <span>{{ auth.user?.username }}</span>
                </div>
              </div>
            </li>
            <li>
              <div class="tp-profile-info d-flex">
                <div class="tp-profile-info-tag">
                  <span>Email</span>
                </div>
                <div class="tp-profile-info-details">
                  <span>{{ auth.user?.email }}</span>
                </div>
              </div>
            </li>
            <li>
              <div class="tp-profile-info d-flex">
                <div class="tp-profile-info-tag">
                  <span>Phone Number</span>
                </div>
                <div class="tp-profile-info-details">
                  <span>{{ auth.user?.phone }}</span>
                </div>
              </div>
            </li>
            <li>
              <div class="tp-profile-info d-flex">
                <div class="tp-profile-info-tag">
                  <span>Skills</span>
                </div>
                <div class="tp-profile-info-details">
                 <div class="d-flex gap-2 p-3">
                   <div v-for="skill in auth?.skills" class="badge text-light bg-secondary">{{skill.skill_name}}</div>
                 </div>
                </div>
              </div>
            </li>
            <li>
              <div class="tp-profile-info d-flex">
                <div class="tp-profile-info-tag">
                  <span>Biography</span>
                </div>
                <div class="tp-profile-info-details"><span class="details-zone">{{ auth.user?.bio }}</span></div>
              </div>
            </li>
          </ul>
        </div>
        <table class="table table-sm table-borderless table-list mt-4">
          <thead>
          <tr>
            <th class="font-s-14" colspan="2">
              <div class="d-flex align-items-center"> Education Details</div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in auth?.education">
            <td>
              <b class="d-block">{{ row.institution_name }}</b>
              <div class="text-dark"><i class="far fa-graduation-cap mr-1" style="width:18px;text-align:center;"></i> {{ row.major }}</div>
              <div class="text-secondary"><i class="far fa-map-marker-alt mr-1" style="width:18px;text-align:center;"></i> {{ row.location }}</div>
            </td>
            <td>
              <div class="text-secondary"><b>Start Date:</b> {{ row.start_date }}</div>
              <div class="text-secondary"><b>End Date:</b> {{ row.end_date }}</div>
              <div class="text-secondary"><b>Grade:</b> {{ row.grade }}</div>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-sm table-borderless table-list mt-4">
          <thead>
          <tr>
            <th class="font-s-14" colspan="2">
              <div class="d-flex align-items-center"> Work Experience</div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in auth?.work_experience">
            <td>
              <b class="d-block">{{ row.company }}</b>
              <div class="text-dark"><i class="far fa-id-card mr-1" style="width:18px;text-align:center;"></i> {{ row.title }}</div>
              <div class="text-secondary" v-if="row.location"><i class="far fa-map-marker-alt mr-1" style="width:18px;text-align:center;"></i> {{ row.location }}</div>
            </td>
            <td>
              <div class="text-secondary"><b>Start Date:</b> {{ row.from_date }}</div>
              <div class="text-secondary"><b>End Date:</b> {{ (row.current) ? lms_website.__('Present') : row.to_date }}</div>
              <div class="text-secondary" v-if="row.description">{{ row.description }}</div>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-sm table-borderless table-list mt-4">
          <thead>
          <tr>
            <th class="font-s-14" colspan="2">
              <div class="d-flex align-items-center"> Certification</div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in auth?.certification">
            <td>
              <b class="d-block">{{ row.organization }}</b>
              <div class="text-dark"><i class="far fa-file-certificate mr-1" style="width:18px;text-align:center;"></i> {{ row.certification_name }}</div>
              <div class="text-secondary" v-if="row.description">{{ row.description }}</div>
            </td>
            <td>
              <div class="text-secondary"><b>Start Date:</b> {{ row.issue_date }}</div>
              <div class="text-secondary"><b>End Date:</b> {{ row.expiration_date }}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- dashboard-content-area-end -->
</template>

<script>
import {authData, isLoggedIn} from "../../../utils/auth";

export default {
  name: "ViewProfile",
  props: {},
  setup() {
    return {
      isLoggedIn
    }
  },
  data() {
    return {
      lms_website: lms_website,
      authData: authData(),
      moment: moment,
    }
  },
  computed: {
    auth() {
      if (this.authData && this.authData.is_logged_in && this.authData.user) {
        return this.authData;
      }
    }
  },
  watch: {},
  methods: {},
  mounted() {
    setTimeout(() => {
      NProgress.done();
      $("[data-background]").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
      });
    }, 500);
  }
}
</script>

<style scoped>

</style>
