import {useStorage} from "vue3-storage";

export const guestMiddleware = async (to, from, next) => {
    const storage = useStorage();
    let UnixAuth = storage.getStorageSync("UnixAuth");
    if (lms_website.user_session !== 'Guest') {
        await next({name: 'profile.view'});
    } else {
        next();
    }
}
