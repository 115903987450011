<script>

export default {
  name: "FieldLink",
  props: {
    field: {
      type: [Object],
      default: {}
    },
    modelValue: {
      type: [Object],
      default: {}
    },
    referenceDoctype: {
      type: [String],
      default: ''
    },
    cssClass: {
      type: [String],
      default: ''
    }
  },
  setup() {
    return {
      lms_website
    }
  },
  data() {
    return {
      frappe: frappe,
      field_value: null,
      loading_list: false,
      show_list: false,
      list: [],
    }
  },
  watch: {
    field: {
      handler(field) {
        // console.log('==field==', field)
      },
      deep: true
    },
  },
  methods: {
    search_link(value = '') {
      this.loading_list = true;
      const args = {
        txt: this.modelValue[this.field.name] || value,
        doctype: this.field.doctype,
        reference_doctype: this.referenceDoctype,
        ignore_user_permissions: 0,
        page_length: 10
      };
      if (this.field.link_filters) {
        args.filters = this.field.link_filters;
      }
      frappe.call({
        method: "lms_website.api.utils.custom_search_link",
        args: args,
        callback: ({message}) => {
          setTimeout(() => {
            this.loading_list = false;
            this.list = message;
          }, 10);
        }
      });
    },
    openList() {
      this.show_list = true;
      this.search_link();
    },
    searchList(event) {
      this.show_list = true;
      this.search_link(this.modelValue[this.field.name]);
    },
    closeList() {
      setTimeout(() => {
        let value = this.modelValue[this.field.name];
        let check_item = this.list.filter(item => item.value === value)[0];
        if (!check_item) {
          this.modelValue[this.field.name] = '';
        }
        this.show_list = false;
      }, 150);
    },
    select_value(value) {
      this.modelValue[this.field.name] = value;
      setTimeout(() => {
        this.closeList();
      }, 150);
    },
    get_filter_description(_filters, doctype) {
      let filter_array = [];
      let meta = null;
      filters = _filters;

      // convert object style to array
      if (!Array.isArray(filters)) {
        for (let fieldname in filters) {
          let value = filters[fieldname];
          if (!Array.isArray(value)) {
            value = ["=", value];
          }
          filter_array.push([fieldname, ...value]); // fieldname, operator, value
        }
      } else {
        filter_array = filters;
      }
      // add doctype if missing
      filter_array = filter_array.map((filter) => {
        if (filter.length === 3) {
          return [doctype, ...filter]; // doctype, fieldname, operator, value
        }
        return filter;
      });

      const get_filter_description = (filter) => {
        let doctype = filter[0];
        let fieldname = filter[1];
        let docfield = frappe.meta.get_docfield(doctype, fieldname);
        let label = docfield ? lms_website.__(docfield.label) : lms_website.__(frappe.model.unscrub(fieldname));

        if (docfield && docfield.fieldtype === "Check") {
          filter[3] = filter[3] ? lms_website.__("Yes") : lms_website.__("No");
        }
        if (filter[3] && Array.isArray(filter[3]) && filter[3].length > 5) {
          filter[3] = filter[3].slice(0, 5);
          filter[3].push("...");
        }
        let value = filter[3] == null || filter[3] === "" ? lms_website.__("empty") : String(filter[3]);
        return [lms_website.__(label).bold(), filter[2], value.bold()].join(" ");
      }

      let filter_string = filter_array.map(get_filter_description).join(", ");
      return lms_website.__("Filters applied for {0}", [filter_string]);
    },
  },
  mounted() {
    if (this.field.default)
      this.modelValue[this.field.name] = this.field.default;
  }
}
</script>

<template>
  <div class="field-link-container dropdown">
    <div class="field-link-loading" v-if="loading_list"></div>
    <input type="text" class="form-control" :class="cssClass" v-model="modelValue[field.name]" @input="searchList" @keydown="searchList" @focus="openList" @blur="closeList" :name="field.name" :id="`${field.name}`" :required="field.required" autocomplete="off">
    <div class="dropdown-menu animate__animated animate__zoomIn animate__faster" :class="{'show':(show_list && !loading_list)}" style="width:100%;">
      <div class="alert alert-warning text-center text-orange" style="padding:4px 8px;margin:0 8px;font-size:13px;" v-if="!loading_list && !list.length">{{ lms_website.__('No data found') }}</div>
      <button type="button" class="dropdown-item" v-for="item in list" @click="select_value(item.value)">
        <b class="d-block">{{ item.value }}</b>
        <small class="text-secondary" v-if="item.description">{{ item.description }}</small>
      </button>
      <div class="dropdown-item bg-white dropdown-item-filters" style="white-space:revert;font-size:13px;border-top:1px solid #eee;padding-top:10px;margin-top:10px;" v-if="field.link_filters">
        <span class="text-secondary" v-html="get_filter_description(field.link_filters)"></span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.field-link-container {
  position: relative;

  .dropdown-menu {
    box-shadow: 0 3px 10px -4px 0 rgba(0, 0, 0, 0.35);
    max-height: 268px;
    overflow: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: #777777;
    }
  }

  .field-link-loading {
    border: 3px solid #b9bac4;
    border-top: 3px solid var(--bs-primary);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: block;
    animation: loader-spin 0.5s linear infinite;
    -webkit-animation: loader-spin 0.5s linear infinite;
    -moz-animation: loader-spin 0.5s linear infinite;
    -o-animation: loader-spin 0.5s linear infinite;
    -ms-animation: loader-spin 0.5s linear infinite;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .dropdown-item {
    width: calc(100% - 16px);
    font-size: 13px;
    line-height: 21px;
    margin: 0 8px;
    border-radius: 6px;

    b {
      font-weight: 500;
    }

    small {
      font-weight: 300;
      font-size: 12px;
    }

    &.dropdown-item-filters {
      color: var(--bs-primary);
    }

    &:hover {
      background-color: #f3f6f9;
    }

    &:active {
      .text-secondary {
        color: #ffffff;
      }
    }
  }
}

</style>
