export function timeAgo(date) {
    const now = new Date(); // Current time in local timezone
    const localDate = new Date(date); // Ensure the date is in local timezone
    const secondsPast = Math.floor((now - localDate) / 1000); // Time difference in seconds

    if (secondsPast < 60) {
        return `just now`;
    } else if (secondsPast < 3600) {
        const minutes = Math.floor(secondsPast / 60);
        return `${minutes} minutes ago`;
    } else if (secondsPast < 86400) {
        const hours = Math.floor(secondsPast / 3600);
        return `${hours} hours ago`;
    } else if (secondsPast < 2592000) {
        const days = Math.floor(secondsPast / 86400);
        return `${days} days ago`;
    } else if (secondsPast < 31536000) {
        const months = Math.floor(secondsPast / 2592000);
        return `${months} months ago`;
    } else {
        const years = Math.floor(secondsPast / 31536000);
        return `${years} years ago`;
    }
}
